// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i');

// Variables
@import 'variables';
@import 'woocommerce';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
$fa-font-path:        "../webfonts";
// Font-Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

// jQuery-UI
@import '~jquery-ui/themes/base/all.css';

// import style
@import "style";

// animated headline
@import '~jquery-animated-headlines/dist/css/jquery.animatedheadline.css';

// swiper slider
@import 'node_modules/swiper/swiper';
@import 'node_modules/swiper/modules/navigation';
@import 'node_modules/swiper/modules/pagination';

// magnific popup
@import '~magnific-popup';

@import 'mixins';
@import 'roadmap';

// AOS
@import '~aos/dist/aos.css';

// alertifyjs
@import "~alertifyjs/build/css/alertify.min.css";
@import "~alertifyjs/build/css/themes/bootstrap.min.css";

@import "~slick-carousel/slick/slick";
