$theme-color: #65892f;
$black-color: #000000;
$secondary-color: #fee600;
$transition: all 0.3s ease;
$title-font: 'Bakbak One', cursive;
%of-hidden {
  overflow: hidden;
}
%p-rel {
  position: relative;
}
%p-abs {
  position: absolute;
  content: "";
}
%flex {
  display: flex;
  flex-wrap: wrap;
}
%align-items-center {
  align-items: center;
}
%justify-between {
  justify-content: space-between;
}
%mb-15 {
  margin-bottom: 15px;
}
%mb-0 {
  margin-bottom: 0;
}

.lh-markdown h1,
.lh-markdown h2,
.lh-markdown h3,
.lh-markdown h4,
.lh-markdown h5,
.lh-markdown h6 {
  color: #f1f5f8;
  margin-bottom: 1rem;
}

.lh-markdown h1,
.lh-markdown h2,
.lh-markdown h3 {
  line-height: 2;
  border-bottom-width: 1px;
  border-color: #606f7b;
}