@charset "UTF-8";
/*!
  Theme Name: GIO
  Theme URI: http://www.themeim.com/demo/gio/
  Author: Theme Im
  Author URI: http://www.themeim.com/
  Description: Multiperpose Game HTML Template
  Version: 1.0.0
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Text Domain: gameisland
  Tags: game,business,coporate,startup
  */
/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base
  # Sections
    ## Header
    ## Banner
    ## Beyond
    ## Blog
    ## Video
    ## Behind
    ## Live Stream
    ## Game Strategy
    ## Add
    ## Newsletter
    ## Page Heade
    ## Feature Game
    ## Tranding Game
    ## Logo Carousel
    ## Overview
    ## Purchase Game
    ## Footer

  --------------------------------------------------------------*/
@font-face {
  font-family: themeim-build-icons;
  src: url("../fonts/themeim-build-icons.eot");
  src: url("../fonts/themeim-build-icons.eot?#iefix") format("embedded-opentype"), url("../fonts/themeim-build-icons.woff") format("woff"), url("../fonts/themeim-build-icons.ttf") format("truetype"), url("../fonts/themeim-build-icons.svg#themeim-build-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}


:root {
  --background-color: #0d1f39;
  --gray: rgba(94, 91, 85, 1);
  --gray-bg: rgba(94, 91, 85, 0.08);
  --dark-gray: rgba(24, 20, 18, 0.95);
  --dark-gray2: rgba(24, 20, 18, 0.5);
  --blue-gray: #929391;
  --orange: rgb(248 157 65);
  --brown: rgba(118, 69, 2, 1);
  --light-gray: #9E9783;
  --green: #8cbe4b;
  --darker-blue: rgb(38 64 100 / 95%);
  --purple: rgb(178 117 255);
  --everlook-purple: rgb(83 47 137);
  --eu-background: #252c36;
}

.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: "";
  font-family: 'themeim-build-icons';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
}

.icon.-facebook:before {
  content: "";
}

.icon.-instagram:before {
  content: "";
}

.icon.-twitter:before {
  content: "";
}

.icon.-viget:before {
  content: "";
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

body {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  line-height: 28px;
  color: #c4c4c4;
  background-color: var(--background-color);
}

a {
  transition: all 0.15s ease-in-out;
}

a,
a:hover {
  text-decoration: none;
}

ul, ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

img {
  max-width: 100%;
}

/* Reset */
.pr {
  position: relative !important;
}

.bdn {
  border: none !important;
}

/* Padding */
.pr__15 {
  padding-right: 15px !important;
}

.pl__15 {
  padding-left: 15px;
}

.pt__15 {
  padding-top: 15px !important;
}

.pt__50 {
  padding-top: 50px !important;
}

.pt__80 {
  padding-top: 80px !important;
}

.pt__100 {
  padding-top: 100px !important;
}

.pb__100 {
  padding-bottom: 100px !important;
}

.pb__50 {
  padding-bottom: 50px !important;
}

.pt__30 {
  padding-top: 30px;
}

.pb__30 {
  padding-bottom: 30px !important;
}

/* Margon */
.mr__0 {
  margin-right: 0 !important;
}

.mb_10, .mb__10 {
  margin-bottom: 10px;
}

.mb__15 {
  margin-bottom: 15px;
}

.mb__17 {
  margin-bottom: 17px;
}

.mb__20 {
  margin-bottom: 20px !important;
}

.mb__25 {
  margin-bottom: 25px;
}

.mb__30 {
  margin-bottom: 30px;
}

.mb__50 {
  margin-bottom: 50px;
}

.mt_20 {
  margin-top: 20px;
}

.mt__30 {
  margin-top: 30px;
}

.mt_40 {
  margin-top: 40px;
}

.mt__50 {
  margin-top: 50px;
}

.brn {
  border: 0 !important;
}

.section-padding {
  padding: 100px 0;
}

/* Background */
.bgb {
  background: #000;
}

.content-two {
  margin-top: 20px;
}

.section-padding {
  padding: 100px 0;
}

.parallax {
  background-size: cover;
}

/* Section Title */
.section-title {
  text-align: center;
  margin-bottom: 70px;
}

.section-title .title, .section-title .sub-title {
  text-transform: uppercase;
  margin: 0;
  font-weight: 700;
}

.section-title .title {
  font-size: 50px;
  position: relative;
  color: #dedede;
  margin-bottom: 10px;
}

.section-title .title span {
  color: #65892f;
}

.section-title .sub-title {
  font-size: 20px;
  color: #65892f;
}

.section-title .delemitter {
  display: block;
  height: 50px;
  background-repeat: no-repeat;
  max-width: 237px;
  margin: 0 auto 20px;
}

.section-title .section-content p {
  margin: 0 auto;
  font-size: 18px;
  line-height: 28px;
}

.section-title-two {
  text-align: center;
  margin-bottom: 60px;
}

.section-title-two .title {
  font-size: 30px;
  line-height: 25px;
  color: #dedede;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  margin: 0;
}

.section-title-two .title:before, .section-title-two .title:after {
  position: absolute;
  content: '';
  height: 1px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  background: #353558;
  max-width: 400px;
}

.section-title-two .title:before {
  left: 0;
}

.section-title-two .title:after {
  right: 0;
}

.section-mini-title {
  margin-bottom: 50px;
  position: relative;
}

.section-mini-title .title, .section-mini-title .sub-title {
  color: #dedede;
}

.section-mini-title .title {
  font-size: 30px;
  position: relative;
  font-weight: 700;
  margin-bottom: 0;
}

.section-mini-title .sub-title {
  font-size: 25px;
  color: #f68b1f;
  font-weight: 700;
}

.section-icon-title {
  background-image: radial-gradient(ellipse farthest-corner at center 0px, #11383a -300%, #262635 45%, #27273a 85%);
  padding: 23px 20px;
  margin-bottom: 20px;
  position: relative;
}

.section-icon-title:before {
  content: '';
  background: url(../media/background/stripe.png) top left;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.section-icon-title h3 {
  margin: 0;
  color: #FFF;
  font-size: 20px;
  position: relative;
}

.section-icon-title h3 i {
  margin-right: 10px;
}

.section-title-three {
  text-align: center;
  margin-bottom: 30px;
}

.section-title-three h2 {
  font-size: 40px;
  line-height: 45px;
  font-weight: 600;
  color: #fff;
  margin: 0;
}

/* Button */
.cart-btn {
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  background: #f68b1f;
  border: 2px solid #fff;
  color: #fff;
  display: inline-block;
  padding: 11px 31px;
  border-radius: 4px;
  text-transform: uppercase;
}

.cart-btn i {
  margin-right: 8px;
}

.cart-btn:hover, .cart-btn:focus {
  color: #fff;
  background: #ffc107;
  border-color: #ffc107;
}

.cart-btn-two {
  padding: 14px 49px;
  text-transform: uppercase;
  background: #fd0e0e;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  vertical-align: middle;
  border-radius: 4px;
  display: inline-block;
}

.cart-btn-two i {
  margin-right: 8px;
}

.cart-btn-two:hover, .cart-btn-two:focus {
  background: #d52b31;
  color: #fff;
}

.details-btn {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  padding: 5px 16px;
  background: rgba(255, 255, 255, 0.302);
  border-radius: 4px;
  line-height: 17px;
  border: 1px solid #fff;
}

.details-btn:hover, .details-btn:focus {
  background: #ffc107;
  color: #fff;
  border-color: #ffc107;
  outline: 0;
}

.popup-btn-two {
  background: #487b00;
  color: #fff;
  padding: 8px 25px;
  border-radius: 4px;
  font-weight: 600;
  display: inline-block;
}

.popup-btn-two i {
  margin-right: 5px;
}

.popup-btn-two:hover, .popup-btn-two:focus {
  color: #fff;
  background: #f68b1f;
  outline: 0;
}

.follow-btn {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  padding: 5px 12px;
  background: #f68b1f;
  border-radius: 3px;
  line-height: 16px;
}

.follow-btn i {
  margin-right: 5px;
}

.follow-btn:hover, .follow-btn:focus {
  background: #d97109;
  color: #fff;
  outline: 0;
}

.play-btn {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.play-btn i {
  font-size: 30px;
  margin-right: 10px;
  vertical-align: middle;
}

.play-btn:hover {
  color: #ffc107;
}

.home-zoombie {
  background-attachment: fixed;
}

.home-version-magazine {
  background: #13131f;
}

.home-clan {
  background: #13131f;
}

.tim-btn, .products.product-two li.product .add_to_cart_button {
  padding: 10px 20px;
  font-size: 18px;
  color: #dedede;
  background: transparent;
  border: 2px solid #dedede;
  text-transform: uppercase;
  line-height: 18px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  display: inline-block;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.tim-btn:before, .tim-btn:after, .products.product-two li.product .add_to_cart_button:before, .products.product-two li.product .add_to_cart_button:after {
  background: #65892f;
  content: '';
  position: absolute;
  z-index: -1;
}

.tim-btn:after, .products.product-two li.product .add_to_cart_button:after {
  height: 100%;
  left: -60%;
  top: 0;
  -ms-transform: skew(50deg);
  transform: skew(50deg);
  transition-duration: 0.4s;
  -ms-transform-origin: top left;
  transform-origin: top left;
  width: 0;
}

.tim-btn:hover, .products.product-two li.product .add_to_cart_button:hover {
  border-color: #65892f;
  color: #fff;
}

.tim-btn:hover:after, .products.product-two li.product .add_to_cart_button:hover:after {
  height: 100%;
  width: 170%;
}

.tim-btn {
  font-weight: 500;
}

.tim-btn.btn-sm {
  padding: 8px 17px;
  font-size: 16px;
}

.tim-btn.btn-larg {
  padding: 12px 30px;
}

.tim-btn.btn-exlg {
  padding: 16px 35px;
}

.tim-btn-bg {
  padding: 14px 25px;
  background: #f68b1f;
  display: inline-block;
  border: 2px solid #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 17px;
  color: #fff;
  border-radius: 4px;
  text-transform: uppercase;
}

.tim-btn-bg i {
  margin-left: 5px;
}

.tim-btn-bg:hover {
  color: #fff;
  background: #d52b31;
  border-color: #d52b31;
}

/* Flat Icons */
@font-face {
  font-family: Flaticon;
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"), url("../fonts/Flaticon.woff") format("woff"), url("../fonts/Flaticon.ttf") format("truetype"), url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: Flaticon;
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-style: normal;
}

.flaticon-man-with-bag-and-walking-stick:before {
  content: "\f100";
}

.flaticon-trophy:before {
  content: "\f101";
}

.flaticon-fire:before {
  content: "\f102";
}

.flaticon-gamepad:before {
  content: "\f103";
}

/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
.header {
  transition: all 0.3s ease-in-out;
}

.header.header-one {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: black;
}

.header.header-two, .header.header-three {
  position: relative;
}

.header.header-two .top-menu, .header.header-three .top-menu {
  background: #3d4852;
}

.header.header-two .top-menu:after, .header.header-three .top-menu:after {
  display: none;
}

.header.header-two .nav, .header.header-three .nav {
  background-image: radial-gradient(ellipse farthest-corner at center 0px, #11383a -300%, #262635 45%, #27273a 85%);
}

.header.header-two .nav .nav-wrap, .header.header-three .nav .nav-wrap {
  background: transparent;
}

.header.header-two .nav .nav-wrap:before, .header.header-three .nav .nav-wrap:before {
  display: none;
}

.header.header-three .top-menu {
  border-bottom: 3px solid #606f7b;
}

.header.header-three .middle-menu-wrapper {
  background: #22292f;
}

.header.header-three .middle-menu-wrapper .top-add img {
  width: 100%;
}

.header.fixed {
  position: sticky;
  top: -51px;
  animation: headerFixedTop .35s;
  width: 100%;
  z-index: 999999;
}

.header.fixed .nav {
  background-image: radial-gradient(ellipse farthest-corner at center 0px, #11383a -300%, #262635 45%, #27273a 85%);
}

.header.header-three .nav-wrap .tim-nav > li.menu-item-has-children .sub-menu {
  right: auto;
}

.header.header-three.fixed {
  top: 4px;
}

.header.header-one.header-dark.fixed {
  position: fixed !important;
  top: -50px;
}

.header.header-one.header-dark.fixed .nav.bgb {
  background: #000;
}

.header.header-one.header-dark.fixed .nav-wrap #site-logo {
  padding: 20px 0;
}

.fixed .nav:after {
  display: none;
}

.fixed .nav-wrap:before {
  display: none;
}

.fixed .nav-wrap .tim-nav > li {
  padding: 20px 0;
}

.fixed .nav-wrap .tim-nav > li.search-btn {
  margin: 20px 0 20px 30px !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.top-product-items {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 10px 0;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.top-product-items .item {
  width: 48%;
  margin-left: 7px;
  padding: 10px 5px;
  background: #333;
  border-radius: 10px;
}

.top-product-items .item:first-child {
  margin-left: 0;
}

.top-product-items .item img {
  float: left;
  max-width: 100%;
}

.top-product-items .item .content {
  margin-left: 65px;
  padding: 13px 0px;
}

.top-product-items .item .content h3 {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
}

.top-product-items .item .content h3 a {
  color: #888888;
}

.top-product-items .item .content h3 a:hover {
  color: #f68b1f;
}

.top-menu-search-form {
  position: absolute;
  right: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 1px solid #888;
  width: 300px;
  display: -ms-flexbox;
  display: flex;
  padding: 5px 10px;
  border-radius: 4px;
}

.top-menu-search-form input {
  width: 100%;
  border: 0;
  outline: 0;
  background: transparent;
  color: #888888;
}

.top-menu-search-form input::-webkit-input-placeholder {
  color: #888;
}

.top-menu-search-form input:-ms-input-placeholder {
  color: #888;
}

.top-menu-search-form input::placeholder {
  color: #888;
}

.top-menu-search-form button {
  background: transparent;
  border: 0;
  outline: 0;
  color: #888;
}

.header.transperant-wbg.fixed {
  top: 0;
}

.header.transperant-wbg.fixed .nav-wrap .tim-nav > li {
  padding: 25px 0;
}

@keyframes headerFixedTop {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.top-menu-wrap {
  background: #3d4852;
  padding: 11px 0;
  position: relative;
}

.top-menu-wrap:before, .top-menu-wrap:after {
  position: absolute;
  content: '';
  top: 0;
}

.top-menu-wrap:before {
  left: -400px;
  min-width: 400px;
  height: 50px;
  background: #3d4852;
}

.top-menu-wrap:after {
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 50px solid #3d4852;
  right: -50px;
}

.top-menu .head-social-link {
  margin: 0;
  padding: 0;
  list-style: none;
  float: left;
  display: inline-block;
  margin-right: 20px;
}

.top-menu .head-social-link li {
  display: inline-block;
  margin-right: 10px;
}

.top-menu .head-social-link li a {
  display: block;
  font-size: 16px;
  color: #FFF;
}

.top-menu .head-social-link li a:hover {
  color: #65892f;
}

.header-text .tour-title {
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  background: #000;
  padding: 0 5px;
  display: inline-block;
  margin-right: 5px;
}

.header-text .ah-headline {
  color: #FFF;
  margin-bottom: 0;
  font-size: 14px;
  width: 300px;
  overflow: hidden;
  display: inline;
}

.header-text .ah-headline.slide .ah-words-wrapper {
  vertical-align: middle;
}

.user-link {
  margin: 0;
  padding: 0;
  list-style: none;
}

.user-link.nav {
  background: transparent !important;
}

.user-link li {
  display: inline-block;
  margin-right: 30px;
}

.user-link li a {
  display: block;
  color: #FFF;
}

.user-link li a i {
  margin-right: 13px;
}

.user-link li a:hover {
  color: #65892f;
}

.user-link li a:hover i {
  color: #fff;
}

.user-link li:last-child {
  margin-right: 0;
}

.header-one .nav {
  position: relative;
}

.header-one .nav:after {
  position: absolute;
  content: '';
  background: radial-gradient(ellipse farthest-corner at center 0px, #11383a -300%, #262635 45%, #27273a 85%);
  width: calc(50% - 555px);
  height: 110px;
  right: 0;
  z-index: -1;
}

.nav-wrap {
  background-image: radial-gradient(ellipse farthest-corner at center 0px, #11383a -300%, #262635 45%, #27273a 85%);
  position: relative;
}

.nav-wrap:before {
  position: absolute;
  content: '';
  top: 0;
  left: -50px;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 0px solid transparent;
  border-top: 110px solid #272737;
}

.nav-wrap #site-logo {
  position: absolute;
  display: inline-block;
  float: left;
  z-index: 1000;
}

.nav-wrap #header-reg {
  top:30%;
  left:45%;
  position: absolute;
  display: inline-block;
  float: left;
}

.nav-wrap .tim-nav {
  margin: 0;
  padding: 0;
}

.nav-wrap .tim-nav > li {
  padding: 20px 0;
  display: inline-block;
}

.nav-wrap .tim-nav > li.menu-item-has-children > a:after {
  position: absolute;
  content: '\f107';
  font: var(--fa-font-solid);
  font-size: 14px;
  right: 0;
  top: 50%;
  -ms-transform: translateY(-58%);
  transform: translateY(-58%);
  color: #FFF;
}

.nav-wrap .tim-nav > li a {
  padding: 0 20px;
  color: #FFF;
  font-size: 20px;
  font-weight: 500;
  display: inline-block;
  position: relative;
}

.nav-wrap .tim-nav > li a:hover {
  color: #65892f;
}

.nav-wrap .tim-nav > li.search-btn {
  height: 45px;
  width: 60px;
  background: #D82F2B;
  text-align: center;
  line-height: 44px;
  border-radius: 5px;
  margin: 32px 0 32px 30px;
  background: #1f1e24;
  padding: 0;
  cursor: pointer;
  font-size: 18px;
}

.nav-wrap .tim-nav > li.menu-item-has-children .sub-menu {
  text-align: left;
  position: absolute;
  padding: 10px 20px;
  width: 240px;
  background: #1f1e24;
  top: 120%;
  left: auto;
  margin: 0;
  border-top: 1px solid #606f7b;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.05);
  height: auto;
  z-index: 9999;
}

.nav-wrap .tim-nav > li.menu-item-has-children .sub-menu li {
  display: block;
}

.nav-wrap .tim-nav > li.menu-item-has-children .sub-menu li:last-child {
  border-bottom: 0;
}

.nav-wrap .tim-nav > li.menu-item-has-children .sub-menu li a {
  color: #fff;
  padding: 3px 0;
  font-size: 16px;
}

.nav-wrap .tim-nav > li.menu-item-has-children .sub-menu li a:hover, .nav-wrap .tim-nav > li.menu-item-has-children .sub-menu li a.current_page {
  color: #65892f;
}

.nav-wrap .tim-nav > li.menu-item-has-children .sub-menu.mega-menu {
  width: 100%;
  padding: 20px 30px;
}

.nav-wrap .tim-nav > li.menu-item-has-children .sub-menu.mega-menu > li {
  width: 25%;
  float: left;
}

.nav-wrap .tim-nav > li.menu-item-has-children .sub-menu.mega-menu > li h3 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-left: 15px;
}

.nav-wrap .tim-nav > li.menu-item-has-children .sub-menu.mega-menu li .sub-menu {
  background-color: transparent;
  border-top-color: transparent;
  float: left;
  padding: 0 15px;
}

.nav-wrap .tim-nav > li.menu-item-has-children .sub-menu.mega-menu li .sub-menu li {
  display: block;
}

.nav-wrap .tim-nav > li.menu-item-has-children .sub-menu.mega-menu li .sub-menu li a {
  display: block;
  padding: 3px 0;
}

.nav-wrap .tim-nav > li.menu-item-has-children .mega-menu-inner {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 110%;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.nav-wrap .tim-nav > li.menu-item-has-children .mega-menu-inner .sub-menu {
  top: auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.nav-wrap .tim-nav > li.menu-item-has-children .mega-menu-inner .torurmant {
  background: #131313;
}

.nav-wrap .tim-nav > li.menu-item-has-children .mega-menu-inner .trurnament-inner .tournaments-details .details a.tim-btn {
  color: #fff;
}

.nav-wrap .tim-nav > li:nth-last-child(-n+3) ul {
  left: auto;
  right: 0;
}

.nav-wrap .tim-nav > li:nth-last-child(-n+3) ul li.menu-item-has-children {
  position: relative;
}

.nav-wrap .tim-nav > li:nth-last-child(-n+3) ul li.menu-item-has-children > ul.sub-menu {
  right: 0;
  right: 110%;
}

.nav-wrap .tim-nav > li ul > li.menu-item-has-children {
  position: relative;
}

.nav-wrap .tim-nav > li ul > li.menu-item-has-children ul.sub-menu {
  top: 20px;
  left: auto;
  right: -120%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.nav-wrap .tim-nav > li ul > li.menu-item-has-children:hover ul.sub-menu {
  opacity: 1;
  visibility: visible;
  top: -11px;
}

.nav-wrap .tim-nav > li:hover .dot, .nav-wrap .tim-nav > li:hover .dot:before, .nav-wrap .tim-nav > li:hover .dot:after {
  background: #f68b1f;
}

.nav-wrap .tim-nav > li:hover .dot {
  animation: color 1000ms infinite ease-in-out;
  animation-delay: 800ms;
}

.nav-wrap .tim-nav > li:hover .dot:before {
  animation: color 1000ms infinite ease-in-out;
  animation-delay: 600ms;
}

.nav-wrap .tim-nav > li:hover .dot:after {
  animation: color 1000ms infinite ease-in-out;
  animation-delay: 400ms;
}

.nav-wrap .tim-nav > li:hover .sub-menu {
  opacity: 1;
  top: 100%;
  visibility: visible;
  z-index: 20;
}

.nav-wrap .tim-nav > li:hover .mega-menu-inner {
  opacity: 1;
  visibility: visible;
  top: 100%;
  z-index: 9999;
}

.nav-wrap .tim-nav > li:hover .mega-menu-inner .sub-menu {
  opacity: 1;
  visibility: visible;
  position: relative;
  top: 0;
}

.ah-headline span {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
}

.header.header-dark .nav-wrap:after {
  height: 110px;
}

.mask-overlay {
  content: '';
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.active .mobile-menu-inner {
  left: 0;
}

.close-menu {
  background: #000;
  color: #000;
  position: absolute;
  right: 10px;
  top: 10px;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 40px;
  padding: 5px;
}

.close-menu .bar {
  display: block;
  position: relative;
  margin-bottom: 3px;
  width: 100%;
  height: 2px;
  background-color: #fff;
  transition: all 0.25s ease-in-out;
}

.close-menu .bar:first-child {
  margin-bottom: 5px;
  -ms-transform: rotate(-45deg) translate(-6px, 6px);
  transform: rotate(-45deg) translate(-6px, 6px);
}

.close-menu .bar:last-child {
  -ms-transform: rotate(45deg) translate(1px, 1px);
  transform: rotate(45deg) translate(1px, 1px);
}

.switch-top {
  position: fixed;
  bottom: -42px;
  right: 25px;
  transition: all 0.3s ease-in-out;
  z-index: 9999;
}

.switch-top p {
  display: inline-block;
  float: left;
  margin: 0 20px 0 0;
  color: #d52b31;
  line-height: 40px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  font-weight: 500;
}

.switch-top:hover p {
  opacity: 1;
  visibility: visible;
}

.return-to-top {
  position: fixed;
  bottom: -42px;
  right: 42px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  cursor: pointer;
  z-index: 998;
  border-radius: 50%;
  opacity: 0;
  transition: bottom .5s ease,opacity .5s ease;
}

.return-to-top > i {
  position: relative;
  overflow: hidden;
  font-size: 12px;
  width: inherit;
  height: inherit;
  line-height: inherit;
  display: block;
  color: transparent;
  text-shadow: 0 0 #fff, 0 50px #fff;
  transition: text-shadow .2s ease;
  z-index: 1;
}

.return-to-top:after, .return-to-top:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  -ms-transform: scale(1);
  transform: scale(1);
  border-radius: inherit;
}

.return-to-top:before {
  background: transparent;
  opacity: 1;
  transition: all .3s ease;
  transition: transform .5s ease,opacity .6s ease;
}

.return-to-top:after {
  background: #272727;
  box-shadow: 0 0 0 0 transparent;
  transition: all .3s ease;
}

.return-to-top:hover {
  background: #65892f;
}

.return-to-top:hover:after {
  -ms-transform: scale(1.07);
  transform: scale(1.07);
  background: #65892f;
  box-shadow: 0 10px 20px 8px rgba(0, 0, 0, 0.15);
}

.return-to-top:hover > i {
  text-shadow: 0 -42px #fff, 0 0 #fff;
}

.return-to-top.back-top {
  bottom: 42px;
  opacity: 1;
}

.switch-top.back-top {
  bottom: 20px;
  opacity: 1;
}

.switch-top.back-top .top-menu .head-social-link li {
  margin-right: 7px;
}

.switch-top.back-top .nav-wrap .tim-nav > li a {
  padding: 0 15px;
}

.switch-top.back-top .nav-wrap .tim-nav > li.menu-item-has-children:after {
  right: -5px;
}

.top-search-form {
  position: absolute;
  right: 0;
  height: 80px;
  background: #1f1e24;
  padding: 14px 20px;
  width: 40%;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  bottom: -100px;
  z-index: 9999;
}

.top-search-form .search-form {
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 5px;
  border-radius: 5px;
}

.top-search-form .search-form input {
  width: 90%;
  background: transparent;
  border: 0;
  height: 40px;
  padding: 10px;
  outline: 0;
  color: #fff;
  z-index: 1;
  position: relative;
  font-weight: 600;
}

.top-search-form .search-form button {
  border: 0;
  background: transparent;
  color: #fff;
}

.top-search-form.active {
  opacity: 1;
  visibility: visible;
  bottom: -80px;
  z-index: 999;
}

.sea-fire canvas {
  position: absolute;
  top: 10px;
  left: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 0;
}

#mobile-nav-wrap, .mobile-menu-inner {
  display: none;
}

/* Transperant Header */
#header.transperant-wbg {
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid #474f4f;
}

#header.transperant-wbg .nav-wrap {
  background: transparent;
}

#header.transperant-wbg .nav-wrap:after, #header.transperant-wbg .nav-wrap:before {
  display: none;
}

/* Clan Header */
#header.clan-header .top-menu {
  background: #1b1a24;
}

#header.clan-header .top-menu .top-menu-wrap {
  background-color: transparent;
}

#header.clan-header .top-menu .top-menu-wrap:before, #header.clan-header .top-menu .top-menu-wrap:after {
  display: none;
}

#header.clan-header .nav-wrap {
  background: transparent;
}

#header.clan-header .nav-wrap:before, #header.clan-header .nav-wrap:after {
  display: none;
}

#header.clan-header .nav-wrap #site-logo {
  padding: 36px 0;
}

#header.clan-header .nav-wrap .tim-nav > li {
  padding: 36px 0;
}

#header.fixed {
  position: fixed;
}

.tim-nav .top-author {
  text-align: center;
}

.tim-nav .top-author img {
  width: 100%;
}

/* Page Loader */
#loader-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100000;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 175px;
  height: 100px;
}

.loader span {
  display: block;
  background: #ccc;
  width: 7px;
  height: 10%;
  border-radius: 14px;
  margin-right: 5px;
  float: left;
  margin-top: 25%;
}

.loader span:last-child {
  margin-right: 0px;
}

.loader span:nth-child(1) {
  animation: load 2.5s 1.4s infinite linear;
}

.loader span:nth-child(2) {
  animation: load 2.5s 1.2s infinite linear;
}

.loader span:nth-child(3) {
  animation: load 2.5s 1s infinite linear;
}

.loader span:nth-child(4) {
  animation: load 2.5s 0.8s infinite linear;
}

.loader span:nth-child(5) {
  animation: load 2.5s 0.6s infinite linear;
}

.loader span:nth-child(6) {
  animation: load 2.5s 0.4s infinite linear;
}

.loader span:nth-child(7) {
  animation: load 2.5s 0.2s infinite linear;
}

.loader span:nth-child(8) {
  animation: load 2.5s 0s infinite linear;
}

.loader span:nth-child(9) {
  animation: load 2.5s 0.2s infinite linear;
}

.loader span:nth-child(10) {
  animation: load 2.5s 0.4s infinite linear;
}

.loader span:nth-child(11) {
  animation: load 2.5s 0.6s infinite linear;
}

.loader span:nth-child(12) {
  animation: load 2.5s 0.8s infinite linear;
}

.loader span:nth-child(13) {
  animation: load 2.5s 1s infinite linear;
}

.loader span:nth-child(14) {
  animation: load 2.5s 1.2s infinite linear;
}

.loader span:nth-child(15) {
  animation: load 2.5s 1.4s infinite linear;
}

@keyframes load {
  0% {
    background: #ccc;
    margin-top: 25%;
    height: 10%;
  }
  50% {
    background: #444;
    height: 100%;
    margin-top: 0%;
  }
  100% {
    background: #ccc;
    height: 10%;
    margin-top: 25%;
  }
}

@keyframes color {
  0% {
    background: #fff;
  }
  50% {
    background: #fff;
  }
  100% {
    background: #ffc107;
  }
}

.tab {
  padding: 20px;
  position: relative;
  overflow: hidden;
  background: #1f1e24;
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.tabs {
  position: relative;
  overflow: hidden;
  margin: 0;
  text-align: left;
  float: left;
  width: 20%;
}

.tabs li {
  line-height: 38px;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.tabs a {
  color: #fff;
  font-weight: 500;
  width: 100%;
  display: block;
  letter-spacing: 0;
  outline: none;
  padding: 0 20px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  border-bottom: 1px solid #303030;
  font-size: 16px !important;
}

.nav-wrap .tabs_item {
  display: none;
  padding: 30px 0;
  padding: 20px;
}

.nav-wrap .tabs_item .top-team {
  text-align: center;
}

.nav-wrap .tabs_item .top-team > a {
  background: #131313;
  padding: 10px;
  border-radius: 4px;
}

.nav-wrap .tabs_item .top-team h3 a {
  padding: 0;
}

.nav-wrap .tabs_item .top-team h3 a:hover {
  color: #f68b1f;
}

.nav-wrap .tabs_item:first-child {
  display: block;
}

.nav-wrap .tabs_item .nav-top-author-inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.nav-wrap .tabs_item .top-author h3 {
  line-height: 16px;
}

.nav-wrap .tabs_item .top-author h3 a {
  font-size: 16px;
}

.nav-wrap .tabs_item .torurmant {
  max-width: 730px;
  margin: 0 auto;
}

.nav-wrap .tabs_item .torurmant img {
  height: 120px;
  width: 120px;
}

.nav-wrap .tabs_item .torurmant .link-btn {
  position: absolute;
  color: #f68b1f;
  font-size: 16px;
  font-weight: 600;
}

.nav-wrap .tabs_item .torurmant .link-btn:hover {
  color: #D82F2B;
}

.nav-wrap .tabs_item .torurmant-game .tim-btn-bg {
  padding: 17px 55px;
  background: transparent;
  border: 1px solid #fff;
}

.nav-wrap .tabs_item .torurmant-game .tim-btn-bg:hover {
  color: #fff;
  border-color: #f68b1f;
}

.nav-wrap .tabs_item .tags a {
  font-size: 14px;
  color: #fff;
}

.nav-wrap .tabs_item .top-user-details {
  text-align: left;
  padding: 10px;
}

.nav-wrap .tabs_item .top-user-details .game-badges img {
  width: 24px;
}

.nav-wrap .tabs_item .top-user-status .left-side {
  width: 70%;
}

.nav-wrap .tabs_item .top-user-status .left-side .user-pic {
  width: 85px;
}

.nav-wrap .tabs_item .top-user-status .left-side .content {
  margin-left: 100px;
}

.nav-wrap .tabs_item .top-user-status .left-side .content p {
  line-height: 18px;
  font-size: 14px;
}

.nav-wrap .tabs_item .top-user-status .left-side .content h3.name {
  font-size: 15px;
  margin-bottom: 10px;
}

.nav-wrap .tabs_item .top-user-status .right-side {
  width: 30%;
}

.nav-wrap .tabs_item .top-user-status .right-side .game-level h4 {
  font-size: 16px;
}

.nav-wrap .tabs_item .top-user-status .game-badges {
  padding: 7px;
}

.nav-wrap .tabs_item .top-user-status .game-badges h5 {
  font-size: 14px;
  margin-bottom: 3px;
}

.current a {
  color: #fff;
  background: #303030;
  width: 100%;
}

.loader .loader-inner {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  animation-iteration-count: infinite;
  animation-duration: 3000ms;
}

.loader .loader-inner svg {
  width: 100%;
  height: 100%;
  animation: rotate 4s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader .loader-inner:nth-child(1) {
  animation-name: loading-1;
  animation-delay: 500ms;
}

.loader .loader-inner:nth-child(2) {
  animation-name: loading-2;
  animation-delay: 0ms;
}

.loader .loader-inner:nth-child(3) {
  animation-name: loading-3;
  animation-delay: 500ms;
}

.loader .loader-inner:nth-child(4) {
  animation-name: loading-4;
  animation-delay: 0ms;
}

@keyframes loading-1 {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(150%, 150%) rotate(180deg);
  }
  100% {
    transform: translate(0, 0) rotate(360deg);
  }
}

@keyframes loading-2 {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(-150%, 150%) rotate(180deg);
  }
  100% {
    transform: translate(0, 0) rotate(360deg);
  }
}

@keyframes loading-3 {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(-150%, -150%) rotate(180deg);
  }
  100% {
    transform: translate(0, 0) rotate(360deg);
  }
}

@keyframes loading-4 {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(150%, -150%) rotate(180deg);
  }
  100% {
    transform: translate(0, 0) rotate(360deg);
  }
}

@media (max-width: 1199px) {
  .header-one .nav:after {
    width: calc(50% - 465px);
    z-index: -1;
  }
}

@media (max-width: 1200px) {
  .top-menu .head-social-link li {
    margin-right: 5px;
  }
  .nav-wrap .tim-nav > li a {
    padding: 0 15px;
  }
  .nav-wrap .tim-nav > li.menu-item-has-children:after {
    right: -4px;
  }
  .nav-wrap .tabs_item .top-author h3 a {
    font-size: 14px;
  }
  .top-product-items .item {
    padding: 5px;
  }
  .top-product-items .item img {
    width: 45px;
    margin-top: 7px;
  }
  .top-product-items .item .content {
    margin-left: 50px;
  }
  .header.header-three.fixed {
    top: -50px;
  }
}

@media (max-width: 1024px) {
  .header-one .nav:after {
    height: 70px;
  }
  .nav-wrap #site-logo {
    padding: 20px 0;
  }
  .nav-wrap .tim-nav > li {
    padding: 20px 0;
  }
  .nav-wrap .tim-nav > li.search-btn {
    margin: 12px 0 12px 10px;
    width: 50px;
  }
  .nav-wrap .tim-nav > li .dot {
    bottom: 17px;
  }
  .nav-wrap .tim-nav > li a {
    font-size: 16px;
  }
  .nav-wrap .tim-nav > li a .dot {
    bottom: -5px;
  }
  .nav-wrap .tim-nav > li a:after {
    right: -5px;
  }
  .nav-wrap .tim-nav > li.menu-item-has-children .sub-menu {
    width: 160px;
  }
  .middle-menu-wrapper {
    display: none;
  }
  .nav-wrap:before {
    border-top: 69px solid #272737;
  }
}

@media (max-width: 992px) {
  .header {
    display: none;
  }
  #nav-toggle {
    margin-right: 0;
    float: right;
    width: 30px;
    padding: 7px 0;
  }
  #nav-toggle .toggle-inner {
    position: relative;
  }
  #nav-toggle .toggle-inner > span {
    display: block;
    position: relative;
    margin-bottom: 5px;
    width: 100%;
    height: 2px;
    background-color: #fff;
    transition: all 0.25s ease-in-out;
  }
  #nav-toggle .toggle-inner > span:nth-child(2), #nav-toggle .toggle-inner > span:nth-child(3) {
    width: 24px;
    margin-left: 6px;
    background-color: #65892f !important;
  }
  #nav-toggle .toggle-inner > span:nth-child(4) {
    margin-bottom: 0;
  }
  #nav-toggle.active .toggle-inner > span:nth-child(2), #nav-toggle.active .toggle-inner > span:nth-child(3) {
    opacity: 0;
  }
  #nav-toggle.active .toggle-inner > span:nth-child(1) {
    -ms-transform: rotate(45deg) translate(7px, 7px);
    transform: rotate(45deg) translate(7px, 7px);
  }
  #nav-toggle.active .toggle-inner > span:nth-child(4) {
    -ms-transform: rotate(-45deg) translate(8px, -8px);
    transform: rotate(-45deg) translate(8px, -8px);
  }
  #mobile-nav-wrap {
    display: block;
    background: radial-gradient(ellipse farthest-corner at center 0px, #11383a -300%, #262635 45%, #27273a 85%);
    padding: 16px 20px;
  }
  #mobile-nav-wrap #mobile-logo {
    position: absolute;
    z-index: 9999;
    left: -3%;
    top: -2%;
    float: left;
    padding: 5px 0;
  }
  .mnav-fixed #mobile-logo{
    top: -25% !important;
  }
  #mobile-nav-wrap #mobile-logo img {
    width: 110px;
  }
  #mobile-nav-wrap .user-link {
    padding: 5px 0;
  }
  #mobile-nav-wrap .user-link li {
    margin-right: 10px;
  }
  #mobile-nav-wrap.mnav-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 9999;
    animation: headerFixedTop .35s;
  }
  .mobile-menu-inner {
    display: block;
    width: 350px;
    text-align: center;
    background: #1f1e24;
    padding: 40px 20px 20px;
    position: absolute;
    z-index: 9999;
    left: -100%;
    transition: all 0.7s cubic-bezier(0.65, -0.22, 0.35, 1.13);
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    top: 0;
  }
  .mobile-menu-inner.mnav-fixed {
    position: fixed;
  }
  .mobile-menu-inner #mobile-logo {
    margin-bottom: 30px;
  }
  .mobile-menu-inner .mob-nav {
    margin: 0;
    padding: 0;
    text-align: left;
    list-style: none;
  }
  .accordion-menu {
    width: 100%;
    max-width: 350px;
    margin: 20px auto 50px;
    text-align: left;
    border-radius: 4px;
  }
  .accordion-menu > li {
    position: relative;
    padding: 5px 0;
    border-bottom: 1px solid #333;
  }
  .accordion-menu > li.single-link {
    margin: 3px 0;
  }
  .accordion-menu > li .dropdownlink:before {
    content: "\f105";
    position: absolute;
    right: 10px;
    top: 9px;
    font: var(--fa-font-solid);
    transition: all 0.3s ease-in-out;
    font-size: 16px;
  }
  .accordion-menu > li.open .dropdownlink:before {
    content: "\f107";
    position: absolute;
    right: 10px;
    font: var(--fa-font-solid);
    transition: all 0.3s ease-in-out;
    font-size: 16px;
  }
  .accordion-menu > li.open > a {
    border-bottom: 1px solid #333;
  }
  .accordion-menu > li > a {
    font-weight: 500;
    font-size: 18px;
    text-decoration: none;
    text-transform: uppercase;
    padding: 10px 0;
    color: #fff;
  }
  .accordion-menu .accordion-menu li.open .dropdownlink {
    color: #D82F2B;
  }
  .accordion-menu .accordion-menu li.open .dropdownlink .fa-angle-down {
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .accordion-menu .accordion-menu li:last-child .dropdownlink {
    border-bottom: 0;
  }
  .accordion-menu .dropdownlink {
    cursor: pointer;
    display: block;
    padding: 3px 0;
    font-size: 18px;
    color: #fff;
    position: relative;
    transition: all 0.4s ease-out;
  }
  .accordion-menu .dropdownlink i {
    position: absolute;
    top: 13px;
    left: 16px;
    font-size: 14px;
  }
  .accordion-menu .dropdownlink .fa-angle-down {
    right: 12px;
    left: auto;
  }
  .accordion-menu .dropdownlink:last-child {
    border-bottom: none;
  }
  .accordion-menu .submenuItems {
    display: none;
    background: transparent;
    padding: 5px 0;
  }
  .accordion-menu .submenuItems li:last-child {
    border-bottom: none;
  }
  .accordion-menu .submenuItems a {
    display: block;
    color: #FFF;
    transition: all 0.4s ease-out;
    text-decoration: none;
    font-size: 16px;
  }
  .accordion-menu .submenuItems a:hover, .accordion-menu .submenuItems a:focus {
    color: #ffc107;
  }
  #moble-search {
    border: 1px solid #fff;
    display: -ms-flexbox;
    display: flex;
    border-radius: 4px;
  }
  #moble-search input[type="text"] {
    background: transparent;
    height: 45px;
    color: #fff;
    border: 0;
    padding: 0 10px;
    width: 88%;
    outline: 0;
  }
  #moble-search input[type="text"]::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  #moble-search input[type="text"]:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  #moble-search input[type="text"]::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  #moble-search button[type="submit"] {
    background: transparent;
    border: 0;
    color: #fff;
  }
  @keyframes headerFixedTop {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@media (max-width: 768px) {
  #mobile-nav-wrap {
    padding: 8px 20px !important;
  }
}

@media (max-width: 500px) {
  .mobile-menu-inner {
    width: 90%;
  }
  .switch-top {
    right: 10px;
  }
}

/*--------------------------------------------------------------
  ##  Banner
  --------------------------------------------------------------*/
#banner {
  background: #040404;
  height: 100vh;
}

#banner .banner-slider {
  position: relative;
}

#banner .banner-slider .swiper-slide {
  height: 100vh;
  background-size: cover;
  background-position: center center;
}

#banner .banner-slider .slider-content, #banner .banner-slider .slider-content-two {
  position: absolute;
  top: 57%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.83);
}

#banner .banner-slider .slider-content h2, #banner .banner-slider .slider-content-two h2 {
  font-size: 60px;
  line-height: 70px;
  color: #FFF;
  font-weight: 700;
  margin: 0;
}

#banner .banner-slider .slider-content h2 span, #banner .banner-slider .slider-content-two h2 span {
  color: #f68b1f;
}

#banner .banner-slider .slider-content {
  border-radius: 5px;
  padding: 50px 30px;
  transition: all ease-in-out;
  left: 21%;
}

#banner .banner-slider .slider-content:before {
  position: absolute;
  top: -10px;
  bottom: -10px;
  right: -10px;
  left: -10px;
  border: 2px solid rgba(0, 0, 0, 0.83);
  content: '';
  border-radius: 4px;
  z-index: -1;
}

#banner .banner-slider .slider-content-two {
  padding: 50px 50px 50px 0;
  left: 23%;
}

#banner .banner-slider .slider-content-two:before {
  position: absolute;
  content: '';
  height: 100%;
  width: 50px;
  left: -50px;
  background: rgba(0, 0, 0, 0.83);
  top: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#banner .banner-slider .slider-content-two:after {
  content: '';
  position: absolute;
  right: -236px;
  top: 0;
  width: 0;
  height: 0;
  border-top: 310px solid transparent;
  border-bottom: 0 solid transparent;
  border-left: 236px solid rgba(0, 0, 0, 0.83);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#banner .banner-slider .slider-content-3 {
  position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

#banner .banner-slider .slider-content-3 img {
  min-width: 400px;
  width: 100%;
}

#banner .banner-slider .slider-content-3 .content {
  -ms-transform: translateY(-92%);
  transform: translateY(-92%);
  text-align: center;
  position: absolute;
  top: 50%;
  width: 100%;
}

#banner .banner-slider .slider-content-3 .content h2 {
  font-size: 50px;
  color: #fff;
  font-weight: 700;
  margin: 0;
}

#banner .banner-slider .slider-content-3 .content h2 span {
  color: #D82F2B;
}

#banner .banner-slider .fire-nav-prev, #banner .banner-slider .fire-nav-next {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

#banner .banner-slider .fire-nav-prev {
  left: 50px;
}

#banner .banner-slider .fire-nav-next {
  right: 50px;
}

#banner .banner-slider:hover .fire-nav-prev, #banner .banner-slider:hover .fire-nav-next {
  opacity: 1;
  visibility: visible;
  z-index: 999;
}

.popup-btn {
  padding: 10px 21px;
  border: 2px solid #FFF;
  font-size: 20px;
  color: #FFF;
  text-transform: uppercase;
  line-height: 25px;
  display: inline-block;
  margin-top: 20px;
}

.popup-btn i {
  font-size: 9px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  color: #0d1614;
  padding-left: 2px;
  background: #fff;
  line-height: 20px;
  text-align: center;
  margin-right: 8px;
  vertical-align: middle;
}

.popup-btn:hover {
  background: #f68b1f;
  border-color: #f68b1f;
  color: #fff;
}

.popup-btn:hover i {
  background: #fff;
  color: #f68b1f;
}

/* Magazine */
#banner-magazine {
  background-size: cover;
}

.game-items .item {
  position: relative;
  overflow: hidden;
}

.game-items .item img {
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.game-items .item .game-content {
  position: absolute;
  left: 0;
  bottom: 20px;
  padding: 0 20px;
}

.game-items .item .game-content .game-title {
  font-size: 20px;
  margin-top: 0;
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 25px;
}

.game-items .item .game-content .game-title a {
  color: #fff;
}

.game-items .item .game-content .game-title a:hover {
  color: #f68b1f;
}

.game-items .item .game-content .meta li {
  margin-right: 15px;
}

.game-items .item .game-content .meta li a {
  color: #FFF;
}

.game-items .item .game-content .meta li a i {
  margin-right: 5px;
}

.game-items .item .game-content .meta li a:hover {
  color: #f68b1f;
}

.game-items .item:hover {
  cursor: e-resize;
}

.game-items .item:hover img {
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.review {
  margin: 0;
  padding: 0;
  list-style: none;
}

.review li {
  display: inline-block;
  color: #ffeb3b;
}

.review li:hover {
  color: #f68b1f;
}

.review li:hover i {
  color: #FFF;
}

.game-slider {
  margin-bottom: 30px;
}

.game-slider .fire-nav-prev, .game-slider .fire-nav-next {
  z-index: 99;
  top: 50%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.game-slider .fire-nav-prev:before, .game-slider .fire-nav-next:before {
  border-color: #fff;
}

.game-slider .fire-nav-prev i, .game-slider .fire-nav-next i {
  color: #fff;
}

.game-slider .fire-nav-prev {
  left: 30px;
}

.game-slider .fire-nav-next {
  right: 30px;
}

.game-slider:hover .fire-nav-prev, .game-slider:hover .fire-nav-next {
  opacity: 1;
  visibility: visible;
  z-index: 999;
}

/* Banner Magazine Two */
.banner-slider-zommbie {
  height: 85vh;
  position: relative;
}

.banner-slider-zommbie .fire-nav-prev, .banner-slider-zommbie .fire-nav-next {
  top: 50%;
  z-index: 111;
}

.banner-slider-zommbie .fire-nav-prev {
  left: 40px;
}

.banner-slider-zommbie .fire-nav-next {
  right: 40px;
}

.mgslider-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.mgslider-content h2 {
  color: #fff;
  font-size: 60px;
  line-height: 65px;
  margin: 0;
  text-shadow: 0px 9px 6.48px rgba(21, 21, 21, 0.24);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-transform: uppercase;
  font-weight: 700;
}

.mgslider-content .delemitter {
  height: 50px;
  background-size: contain;
  max-width: 230px;
  display: block;
  margin: 30px auto;
}

.mgslider-content p {
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 30px;
}

.mgslider-content .tim-btn {
  padding: 14px 51px;
  margin-right: 5px;
  font-size: 20px;
  font-weight: 600;
  line-height: 18px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  margin-right: 20px;
  border-radius: 4px;
}

.mgslider-content .tim-btn:after {
  background: #fd0e0e;
}

.mgslider-content .tim-btn:hover {
  color: #fff;
  border-color: #fd0e0e;
}

.mgslider-content .tim-btn.tim-btn-bg {
  padding: 15px 62px;
  font-size: 18px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.mgslider-content .tim-btn.tim-btn-bg:after {
  background: #fff;
}

.mgslider-content .tim-btn.tim-btn-bg:hover {
  background: transparent;
  color: #000;
  border-color: #fff;
}

.mgslider-content.text-left {
  left: 25%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.mgslider-content.text-left img {
  position: absolute;
  left: -115px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
}

/* Clan Slider */
.banner-slider-clan {
  height: 85vh;
}

.banner-slider-clan .swiper-slide {
  background-size: cover;
  background-position: center center;
}

.banner-slider-clan .clan-slider-content {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.banner-slider-clan .clan-slider-content h2 {
  font-size: 60px;
  font-weight: 700;
  line-height: 70px;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
}

.banner-slider-clan .clan-slider-content p {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}

.banner-slider-clan .clan-slider-content .skew-bg {
  padding: 35px 140px 35px 40px;
}

.banner-slider-clan .clan-slider-content .skew-bg:before, .banner-slider-clan .clan-slider-content .skew-bg:after {
  position: absolute;
  content: '';
  z-index: -1;
}

.banner-slider-clan .clan-slider-content .skew-bg:before {
  background-color: rgba(246, 139, 31, 0.5);
  -ms-transform: skewX(-5deg);
  transform: skewX(-5deg);
  z-index: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.banner-slider-clan .clan-slider-content .skew-bg:after {
  -ms-transform: skewX(-5deg);
  transform: skewX(-5deg);
  left: -5px;
  top: -5px;
  bottom: -5px;
  right: -5px;
  border: 5px solid rgba(255, 255, 255, 0.3);
}

.banner-slider-clan .clan-slider-content.text-center {
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.banner-slider-clan .clan-slider-content.text-center img {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}

@media (max-width: 1280px) {
  .mgslider-content h2 {
    font-size: 60px;
    line-height: 60px;
  }
  .mgslider-content .delemitter {
    width: 185px;
    height: 38px;
  }
}

@media (max-width: 1024px) {
  #banner .swiper-wrapper .slider-content h2 {
    font-size: 40px;
    line-height: 40px;
  }
  #banner .swiper-wrapper .slider-content .popup-btn {
    padding: 10px 15px;
    font-size: 16px;
    line-height: 20px;
  }
  #banner .banner-slider .slider-content-two:after {
    border-top: 299px solid transparent;
  }
  #banner .banner-slider .slider-content-3 .content {
    -ms-transform: translateY(-85%);
    transform: translateY(-85%);
  }
  #banner .banner-slider .slider-content-3 .content h2 {
    font-size: 40px;
  }
  #banner .banner-slider .fire-nav-prev {
    left: 20px;
  }
  #banner .banner-slider .fire-nav-next {
    right: 20px;
  }
}

@media (max-width: 991px) {
  #banner .banner-slider .slider-content, #banner .banner-slider .slider-content-3, #banner .banner-slider .slider-content-two {
    top: 50%;
  }
  #banner .banner-slider .slider-content-3 .content h2 {
    font-size: 30px;
  }
  #banner .banner-slider .slider-content h2, #banner .banner-slider .slider-content-two h2 {
    font-size: 40px;
    line-height: 50px;
  }
  #banner .banner-slider .slider-content-two:after {
    border-top: 255px solid transparent;
    border-left: 215px solid rgba(0, 0, 0, 0.83);
    right: -215px;
  }
  .popup-btn {
    padding: 8px 15px;
    font-size: 16px;
  }
  .mgslider-content h2 {
    font-size: 50px;
    line-height: 50px;
  }
  .mgslider-content p {
    font-size: 18px;
    margin-top: 20px;
  }
  .mgslider-content p br {
    display: none;
  }
  .mgslider-content .delemitter {
    display: none;
  }
  .banner-slider-clan .clan-slider-content h2 {
    font-size: 40px;
    line-height: 45px;
  }
  .banner-slider-clan .clan-slider-content p {
    font-size: 16px;
  }
  .banner-slider-clan .clan-slider-content .skew-bg {
    padding: 35px 50px 35px 40px;
  }
}

@media (max-width: 768px) {
  #banner, .banner-slider-zommbie {
    height: 85vh;
  }
  #banner .swiper-wrapper .slider-content h2 {
    font-size: 35px;
    line-height: 35px;
  }
  .beyond-content .content {
    font-size: 16px;
  }
  #banner .banner-slider .slider-content h2, #banner .banner-slider .slider-content-two h2 {
    font-size: 35px;
    line-height: 45px;
  }
  #banner .banner-slider .slider-content-two:after {
    border-top: 255px solid transparent;
    border-left: 130px solid rgba(0, 0, 0, 0.83);
    right: -130px;
  }
  .mgslider-content {
    width: 65%;
  }
  .mgslider-content h2 {
    font-size: 35px;
    line-height: 35px;
  }
  .mgslider-content p {
    font-size: 15px;
    margin-top: 20px;
    line-height: 24px;
  }
  .mgslider-content p br {
    display: none;
  }
  .mgslider-content .delemitter {
    display: none;
  }
  .mgslider-content .tim-btn {
    padding: 12px 30px;
  }
  .mgslider-content .tim-btn.tim-btn-bg {
    padding: 12px 40px;
  }
}

@media (max-width: 500px) {
  #banner .banner-slider .slider-content, #banner .banner-slider .slider-content-two {
    padding: 30px;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 90%;
  }
  #banner .banner-slider .swiper-slide {
    height: 85vh;
  }
  #banner .banner-slider .slider-content-two:after, #banner .banner-slider .slider-content-two:before {
    display: none;
  }
  #banner .fire-nav-prev, #banner .fire-nav-next {
    display: none;
  }
  .popup-btn i {
    margin-left: 1px;
  }
  .mgslider-content.text-left {
    text-align: center !important;
    width: 80%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .mgslider-content {
    width: 80%;
  }
  .banner-slider-clan .clan-slider-content {
    width: 90%;
  }
  .banner-slider-clan .clan-slider-content h2 {
    font-size: 30px;
    line-height: 30px;
  }
  .banner-slider-clan .clan-slider-content p {
    font-size: 14px;
  }
  .banner-slider-clan .clan-slider-content p br {
    display: none;
  }
  .banner-slider-clan .clan-slider-content .skew-bg {
    padding: 35px 50px 35px 40px;
  }
  .banner-slider-clan .clan-slider-content .skew-bg:before, .banner-slider-clan .clan-slider-content .skew-bg:after {
    -ms-transform: skewX(0);
    transform: skewX(0);
  }
  .mgslider-content h2 {
    font-size: 30px;
  }
}

/*--------------------------------------------------------------
  ##  Forum Banner
  --------------------------------------------------------------*/
#forum-banner {
  height: 90vh;
  background-size: cover;
  background-position: center center;
  padding-top: 160px;
  overflow: hidden;
  position: relative;
}

.forum-inner {
  max-width: 1140px;
  margin: 0 auto;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  width: 100%;
}

.top-forum {
  padding: 95px 0;
}

.top-forum h2 {
  font-size: 50px;
  font-weight: 700;
  color: #fff;
}

.top-forum h3 {
  font-size: 30px;
  color: #fff;
  margin-bottom: 15px;
}

.top-forum p {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 30px;
}

.top-forum .tim-btn-bg {
  padding: 14px 58px;
  font-size: 20px;
  border-radius: 0;
}

.top-user {
  text-align: right;
}

.top-user img {
  max-width: 100%;
}

.top-author img {
  border-radius: 50%;
  margin-bottom: 10px;
}

.top-author .game-author-name {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0;
}

.top-author .game-author-name a {
  color: #fff;
}

.top-author .game-author-name a:hover {
  color: #f68b1f;
}

.top-author .author-post-count {
  font-size: 13px;
  color: #fff;
  margin: 0;
}

.top-user-details {
  background: #293241;
  padding: 15px;
  border-radius: 5px;
}

.top-user-status .left-side {
  width: 65%;
  float: left;
  padding-right: 15px;
}

.top-user-status .left-side .user-pic {
  float: left;
  width: 90px;
  height: auto;
}

.top-user-status .left-side .content {
  margin-left: 100px;
}

.top-user-status .left-side .content h3.name {
  font-size: 20px;
  color: #fff;
  margin: 0;
}

.top-user-status .left-side .content p {
  color: #fff;
  font-size: 14px;
  margin: 0;
}

.top-user-status .left-side .content p.some-text {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
}

.top-user-status .right-side {
  float: right;
  width: 35%;
}

.top-user-status .right-side .game-level h4 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.top-user-status .offline {
  background: #1c1c1d;
  padding: 7px;
  margin: 5px 0;
}

.top-user-status .offline h6 {
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  margin: 0;
}

.top-user-status .offline p {
  margin: 0;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
}

.game-badges {
  padding: 10px;
  background: #222223;
}

.game-badges h5 {
  font-size: 16px;
  color: #fff;
}

.game-badges img {
  width: 35px;
}

.user-performance {
  background: #1d1d1d;
  padding: 20px;
  margin-top: 15px;
  border-radius: 5px;
}

.game-collector {
  background: #141414;
  padding: 10px;
  border-radius: 5px;
}

.game-collector h5 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}

.game-collector .collector-items {
  background: #0e0e0e;
  padding: 5px;
}

.game-collector .item {
  width: 25%;
  text-align: center;
  float: left;
  padding: 10px 0;
}

.game-collector .item a h6 {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  line-height: 30px;
  margin-bottom: 0;
}

.game-collector .item a p {
  font-size: 16px;
  color: #9b9b9b;
  margin: 0;
  line-height: 16px;
}

.activity {
  margin-top: 10px;
  padding: 7px;
  background: #141414;
  border-radius: 5px;
}

.activity h3 {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 0;
}

.activity p {
  color: #fff;
  margin-bottom: 0;
  line-height: 19px;
}

.achievement {
  background: #141414;
  padding: 10px;
  border-radius: 5px;
}

.achievement .game_info {
  position: relative;
  height: 56px;
}

.achievement .game_info .game_info_cap {
  float: left;
  margin-right: 8px;
  width: 184px;
  max-width: 33%;
}

.achievement .game_info .game_info_cap img {
  max-width: 100%;
}

.achievement .game_info .game_name a {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.achievement .game_info .game_info_details {
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: right;
  color: #fff;
  font-size: 15px;
  line-height: 22px;
}

.achievement-progressbar {
  background: #282828;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.width-half {
  width: 100%;
  float: left;
}

.width-half p {
  display: inline-block;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  float: left;
  margin: 0 10px 0 0;
  left: 12px;
  line-height: 12px;
}

.width-half p span {
  font-size: 14px;
  margin-left: 5px;
}

.width-half .progress {
  height: 14px;
  padding: 1px;
  border-radius: 30px;
  background: #222223;
}

.width-half .progress .progress-bar {
  border-radius: 30px;
  background: #516179;
}

@media (max-width: 1200px) {
  .forum-inner {
    max-width: 90%;
  }
}

@media (max-width: 1024px) {
  #forum-banner {
    padding-top: 20px;
  }
  .forum-inner {
    padding: 0;
  }
  .top-forum {
    padding: 0 0 20px;
  }
  .top-forum h2 {
    font-size: 35px;
    line-height: 35px;
  }
  .top-forum h3 {
    font-size: 25px;
    line-height: 25px;
  }
  .top-user-details {
    padding: 10px;
    margin-top: 30px;
  }
  .user-performance {
    padding: 10px;
  }
  .game-collector {
    padding: 10px;
  }
  .game-collector .collector-items {
    padding: 0;
  }
  .game-collector .item a h6 {
    font-size: 20px;
    line-height: 22px;
  }
  .game-collector .item a p {
    font-size: 14px;
  }
  .activity {
    margin: 5px 0;
  }
  .achievement {
    padding: 10px;
  }
}

@media (max-width: 500px) {
  #forum-banner {
    height: 115vh;
  }
  .top-user-status .left-side .user-pic {
    width: 70px;
  }
  .top-user-status .left-side .content {
    margin-left: 80px;
  }
  .top-user-status .left-side .content p {
    line-height: 20px;
  }
  .game-badges img {
    max-width: 30px;
    width: 100%;
  }
  .top-user-status .left-side, .top-user-status .right-side {
    width: 100%;
  }
  .top-forum h2 {
    font-size: 30px;
    line-height: 30px;
  }
  .top-forum h3 {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 10px;
  }
  .top-forum p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .top-forum .tim-btn-bg {
    padding: 10px 40px;
  }
  .activity {
    text-align: center;
  }
  .activity .float-left, .activity .float-right {
    float: none !important;
  }
  .achievement .game_info .game_info_details {
    position: static;
  }
}

/*--------------------------------------------------------------
  ##  Beyond
  --------------------------------------------------------------*/
#beyond {
  padding: 100px 0 70px;
  background-size: cover;
  background-position: center center;
  background: #181827;
}

#beyond-two {
  background-size: cover;
  background-attachment: fixed;
}

.beyond-content .content {
  font-size: 16px;
}

.game-items .game-item {
  text-align: center;
  margin-bottom: 30px;
}

.game-items .game-item img {
  border-radius: 5px;
  transition: all 0.3s ease-in;
  width: 100%;
}

.game-items .game-item h3 {
  color: #FFF;
  font-size: 20px;
  margin-top: 20px;
  overflow: hidden;
  display: inline-block;
  line-height: 20px;
  transition: all 0.3s ease-in;
}

.game-items .game-item:hover img {
  box-shadow: 0 0 50px rgba(246, 139, 31, 0.6);
}

.game-items .game-item:hover h3 {
  box-shadow: 0 0 50px rgba(246, 139, 31, 0.6);
}

.game-items .game-item.hover-shadow:hover img {
  box-shadow: 0 0 40px rgba(255, 255, 255, 0.5);
}

.game-items .game-item.hover-shadow:hover h3 {
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
}

@media (max-width: 768px) {
  #beyond {
    padding: 50px 0;
  }
}

/*--------------------------------------------------------------
  ##  Blog
  --------------------------------------------------------------*/
#blog {
  position: relative;
}

.blog-post .feature-image {
  position: relative;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
}

.blog-post .feature-image img {
  border-radius: 12px;
}

.blog-post .blog-title {
  font-size: 25px;
  margin-top: 15px;
}

.blog-post .game-cat {
  position: absolute;
  left: 20px;
  bottom: 10px;
}

.blog-post .game-cat .cat-1, .blog-post .game-cat .cat-2 {
  padding: 0px 10px;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  border-radius: 4px;
  color: #fff;
}

.blog-post .game-cat .cat-1 {
  background: #D82F2B;
}

.blog-post .game-cat .cat-2 {
  background: #297c09;
}

.blog-content .blog-title {
  font-size: 22px;
  font-weight: 700;
}

.blog-content .content {
  font-size: 16px;
  line-height: 28px;
}

.blog-title {
  color: #FFF;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.blog-title a {
  color: #ddd;
}

.blog-title a span {
  color: #f68b1f;
}

.blog-title a:hover {
  color: #f68b1f;
}

.read-btn {
  font-size: 16px;
  color: #FFF;
  font-weight: 500;
  padding: 11px 18px;
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 4px;
}

.read-btn i {
  margin-left: 5px;
}

.read-btn:hover {
  background: rgba(216, 47, 43, 0.7);
  color: #ededed;
}

.feature-image {
  position: relative;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.feature-image img {
  max-width: 100%;
  transition: all 0.3s ease-in-out;
}

.feature-image:hover img {
  -ms-transform: scale(1.1) rotate(3deg);
  transform: scale(1.1) rotate(3deg);
}

.fire-nav-prev, .fire-nav-next {
  height: 45px;
  width: 45px;
  line-height: 57px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: 65%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  outline: 0;
  z-index: 111;
}

.fire-nav-prev:before, .fire-nav-next:before {
  position: absolute;
  content: '';
  top: 4px;
  left: 2px;
  height: 41px;
  width: 41px;
  border: 2px solid #5b5b5b;
  border-radius: 50%;
}

.fire-nav-prev i, .fire-nav-next i {
  color: #5b5b5b;
  font-size: 30px;
}

.fire-nav-prev canvas, .fire-nav-next canvas {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
}

#blog-nav-next {
  right: 15%;
}

#blog-nav-prev {
  left: 15%;
}

.navigation {
  margin-bottom: 60px;
}

/* Blog Post Two */
.blog-post-two .feature-image .blog-content {
  position: absolute;
  left: 0;
  bottom: 15px;
  padding: 0 20px;
}

.blog-post-two .feature-image .blog-content .blog-title {
  font-size: 20px;
  line-height: 22px;
  margin-top: 10px;
  margin-bottom: 0;
}

.blog-post-two .feature-image .meta li {
  margin-right: 0;
}

.blog-post-two.blog-large .feature-image .blog-content .blog-title {
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 10px;
}

.blog-post-two:hover .feature-image img {
  -ms-transform: scale(1.1) rotate(3deg);
  transform: scale(1.1) rotate(3deg);
}

.cat {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cat li {
  display: inline-block;
}

.cat li a {
  display: inline-block;
  border: 1px solid #fff;
  color: #FFF;
  padding: 0 16px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 28px;
  margin-right: 10px;
}

.cat li a:hover {
  border-color: #f68b1f;
}

.cat li a:hover:after {
  background: #f68b1f;
}

.meta li {
  display: inline-block;
  margin-right: 10px;
}

.meta li span {
  color: #fff;
}

.meta li a {
  font-size: 16px;
  color: #fff;
  margin-right: 5px;
}

.meta li a i {
  margin-right: 5px;
}

.meta li a:hover {
  color: #f68b1f;
}

.meta li a:hover i {
  color: #FFF;
}

.meta li a.admin {
  font-weight: 600;
  margin-right: 5px;
  position: relative;
}

.meta li a.admin:after {
  position: absolute;
  content: '';
  right: -6px;
  bottom: 7px;
  height: 3px;
  width: 3px;
  background: #fff;
  border-radius: 50%;
}

.meta.meta-grey li a {
  color: #888;
}

.meta.meta-grey li a:after {
  background: #888;
}

.meta.meta-grey li a:hover {
  color: #f68b1f;
}

/* Blog Post Three */
#blog-two {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0;
}

.blog-slider {
  position: relative;
}

.blog-slider .slider-nav-prev {
  left: -10%;
}

.blog-slider .slider-nav-next {
  right: -10%;
}

.blog-post-three {
  padding: 10px;
  overflow: hidden;
  border-radius: 10px;
  background: #1a1c20;
}

.blog-post-three .feature-image {
  border-radius: 10px;
  overflow: hidden;
}

.blog-post-three .blog-content {
  padding: 20px 10px;
}

.blog-post-three .blog-content .blog-title-two {
  font-size: 22px;
  font-weight: 700;
}

.blog-post-three .blog-content .blog-title-two a {
  color: #dedede;
}

.blog-post-three .blog-content .blog-title-two a:hover {
  color: #f68b1f;
}

.blog-post-three .blog-content p {
  font-size: 16px;
  font-weight: 500;
  color: #a7a7a7;
}

.blog-post-three .blog-content .rbtn {
  font-size: 16px;
  color: #c5c5c5;
  font-weight: 700;
  position: relative;
  display: inline-block;
  line-height: 16px;
}

.blog-post-three .blog-content .rbtn:before {
  position: absolute;
  content: '';
  width: 100%;
  bottom: 0;
  height: 1px;
  background: #383838;
}

.blog-post-three .blog-content .rbtn i {
  margin-left: 5px;
}

.blog-post-three .blog-content .rbtn:hover {
  color: #f68b1f;
}

.blog-post-three .blog-content .rbtn:hover:before {
  background: #f68b1f;
}

/* Recent Post */
.recent-news-inner {
  margin-top: 50px;
}

.recent-news .blog-title {
  color: #dedede;
  font-size: 18px;
  line-height: 26px;
  margin-top: 15px;
  font-weight: 600;
}

.recent-news .meta a {
  color: #888888;
}

.recent-news .meta a.admin:after {
  background: #888;
}

.recent-news .meta a:hover {
  color: #f68b1f;
}

/* Weekly Top News */
#weekly-top {
  padding-top: 60px;
}

.cat-two {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cat-two li {
  display: inline-block;
}

.cat-two li a {
  color: #888;
  text-transform: uppercase;
}

.cat-two li a:hover {
  color: #f68b1f;
}

.top-news {
  margin-bottom: 30px;
}

.top-news img {
  max-width: 100%;
}

.top-news .cat-two {
  margin-top: 5px;
}

.top-news .blog-title {
  font-size: 18px;
  line-height: 25px;
}

.top-mews-feature {
  border: 1px solid #1f1f31;
}

.top-mews-feature .feature-image {
  position: relative;
}

.top-mews-feature .feature-image img {
  max-width: 100%;
  height: auto;
}

.top-mews-feature .feature-image .cat {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.top-mews-feature .blog-content {
  padding: 20px;
}

.top-mews-feature .blog-content .blog-title {
  font-size: 30px;
  line-height: 40px;
  margin: 0;
}

/* Latest News */
#latest-news {
  padding: 60px 0;
}

.latest-post .blog-content {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 100%;
  padding: 0 20px;
}

.latest-post .blog-content .blog-title {
  margin-bottom: 10px;
}

.latest-post .blog-content .content {
  line-height: 25px;
  font-size: 16px;
  margin-bottom: 0;
}

.latest-post.blog-small .blog-content {
  text-align: center;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  transition: all 0.3s ease-in-out;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.latest-post.blog-small .blog-content .content {
  position: absolute;
  bottom: 10px;
  transition: all 0.3s ease-in-out;
  height: 100%;
  width: 100%;
  top: 132%;
  left: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.latest-post.blog-small .blog-content .content .blog-title {
  font-size: 18px;
  margin: 0 0 10px 0;
  transition: all 0.4s ease-in-out;
  text-align: center;
}

.latest-post.blog-small .blog-content .content .read-more {
  font-size: 14px;
  line-height: 25px;
  font-weight: 600;
  background: #FFF;
  padding: 3px 11px;
  color: #444;
  transition: all 0.2s ease-in-out;
  display: inline-block;
}

.latest-post.blog-small .blog-content .content .read-more:hover {
  color: #fff;
}

.latest-post.blog-small:hover .blog-content {
  background: rgba(246, 139, 31, 0.9);
}

.latest-post.blog-small:hover .blog-content .content {
  top: 85%;
}

.latest-post.blog-small:hover .blog-content .content .blog-title {
  color: #d97109;
}

.latest-post.blog-mid .blog-content .blog-title {
  font-size: 20px;
  line-height: 25px;
}

.latest-post.blog-mid .blog-content .content {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
}

/* Upcoming Game */
.blog-post-two.upc-game {
  margin-bottom: 30px;
}

.blog-post-two.upc-game .blog-content .blog-title {
  margin-bottom: 5px;
}

.blog-post-two.upc-game .blog-content p {
  color: #FFF;
  font-size: 16px;
  line-height: 25px;
}

.related-game-inner .related-game .game-meta {
  list-style: none;
}

.related-game-inner .related-game .game-meta li {
  margin-right: 10px;
  position: relative;
  display: inline-block;
}

.related-game-inner .related-game .game-meta li a {
  color: #FFF;
  font-size: 12px;
  line-height: 25px;
  font-weight: 500;
}

.related-game-inner .related-game .game-meta li a:hover {
  color: #f68b1f;
}

.related-game-inner .related-game .game-meta li:after {
  content: '/';
  right: -9px;
  top: 0;
  position: absolute;
  color: #FFF;
  font-size: 10px;
}

.related-game-inner .related-game .game-meta li:last-child:after {
  display: none;
}

.related-game-inner .related-game .game-title {
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 0;
}

.related-game-inner .related-game .game-title a {
  color: #FFF;
  font-weight: 600;
}

.related-game-inner .related-game .game-title a:hover {
  color: #f68b1f;
}

.games-video-slider-inner {
  margin: 50px 0;
}

.upc-game-items {
  border: 1px solid #1f1f31;
  padding: 10px 0;
}

.upc-game-items .item:not(:first-child) {
  margin-top: 10px;
}

.upc-game-items .item:not(:last-child) {
  padding-bottom: 10px;
  border-bottom: 1px solid #1f1f31;
}

.upc-game-items .item {
  padding-left: 10px;
  padding-right: 10px;
}

.upc-game-items .item .feature-image {
  float: left;
}

.upc-game-items .item .content {
  margin-left: 175px;
}

.upc-game-items .item .content .blog-title {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 15px;
  margin-top: 0;
  font-weight: 500;
}

.upc-game-items .item .content .read-btn {
  background-color: transparent;
  color: #fff;
  font-size: 14px;
  padding: 0;
  border: 0;
}

.upc-game-items .item .content .read-btn i {
  margin-left: 4px;
  font-size: 8px;
  transition: all 0.3s ease-in-out;
  vertical-align: middle;
}

.upc-game-items .item .content .read-btn:hover {
  color: #f68b1f;
}

.upc-game-items .item .content .read-btn:hover i {
  margin-left: 6px;
}

.review-wrap {
  position: absolute;
  left: 10px;
  bottom: 5px;
}

.review-wrap .review-count {
  font-size: 14px;
  margin: 0;
  color: #ffeb3b;
  font-weight: 700;
}

.review-wrap .review {
  line-height: 18px;
}

.review-wrap .review li {
  color: #ffeb3b;
}

.gallery-thumbs .swiper-slide.swiper-slide-active {
  border: 2px solid #f68b1f;
}

.latest-post.stategy .blog-content {
  padding: 20px;
  left: 0;
  bottom: 0;
}

.latest-post.stategy .blog-content .blog-title {
  font-size: 25px;
  line-height: 30px;
}

.latest-post.stategy .blog-content .content {
  font-size: 14px;
  line-height: 22px;
  margin-top: 5px;
}

/* Upcoming Game */
#upcoming-game {
  padding-top: 60px;
}

/* Upcoming Game Two*/
#upcoming-game-two, #gameing-post-three {
  padding: 100px 0;
  background-attachment: fixed;
  background-size: cover;
}

.upc-game-two {
  background-color: #0f0f1b;
  padding: 20px 30px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.upc-game-two .feature-image {
  height: 140px;
  width: 140px;
  border-radius: 50%;
  overflow: hidden;
  float: left;
}

.upc-game-two .feature-image a {
  display: block;
}

.upc-game-two .feature-image a img {
  border-radius: 50%;
}

.upc-game-two .game-content {
  margin-left: 160px;
}

.upc-game-two .game-content h3 {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 0;
}

.upc-game-two .game-content h3 a {
  color: #fff;
}

.upc-game-two .game-content h3 a:hover {
  color: #f68b1f;
}

.upc-game-two .game-content p {
  color: #cecece;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 26px;
}

.upc-game-two .game-content .meta-three a {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  margin-right: 10px;
  margin-right: 10px;
}

.upc-game-two .game-content .meta-three a i {
  margin-right: 5px;
}

.upc-game-two .game-content .meta-three a.rd-btn {
  color: #f68b1f;
  text-decoration: underline;
}

/* Game Blog Post */
#game-post {
  padding: 100px 0;
  background: #13131f;
}

.latest-post.game-blog-post {
  border-radius: 5px;
  overflow: hidden;
  max-width: 500px;
}

.latest-post.game-blog-post .blog-content .blog-title {
  line-height: 20px;
  margin: 0;
}

.latest-post.game-blog-post .blog-content .blog-title a {
  font-size: 20px;
  font-weight: 700;
}

.latest-post.game-blog-post .blog-content .blog-title a:hover {
  color: #f68b1f;
}

.latest-post.game-blog-post .blog-content .blog-title a {
  line-height: 20px;
  font-size: 20px;
  font-weight: 700;
}

.latest-post.game-blog-post .blog-content .review li {
  font-size: 13px;
  line-height: 13px;
  color: #f68b1f;
}

.latest-post.game-blog-post .blog-content .meta li {
  margin-right: 0;
}

.latest-post.game-blog-post .blog-content .meta li a:hover {
  color: #f68b1f;
}

.latest-post.game-blog-post .blog-content .follow-btn {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.game-large-player .youtube-wrapper {
  border-radius: 5px;
  padding-bottom: 55.5%;
}

.game-large-player .youtube-wrapper .youtube-poster {
  height: 405px;
}

.game-large-player .youtube-wrapper:before {
  font-size: 100px;
}

.sidebar-blog {
  background: #111;
  padding: 10px 10px 20px 10px;
  border-radius: 5px;
  max-width: 500px;
}

.sidebar-blog .feature-image {
  float: left;
}

.sidebar-blog .feature-image img {
  max-width: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.sidebar-blog .blog-content {
  margin-left: 120px;
}

.sidebar-blog .blog-content .blog-title {
  font-size: 18px;
  margin: 10px 0 0;
}

.sidebar-blog .blog-content .blog-title a:hover {
  color: #f68b1f;
}

.sidebar-blog .blog-content .review li {
  color: #f68b1f;
  font-size: 13px;
}

.sidebar-blog .blog-content .meta li {
  margin-right: 0;
  font-size: 14px;
  color: #f68b1f;
}

.sidebar-blog .blog-content .meta li a:hover {
  color: #f68b1f;
}

.sidebar-blog .blog-content .follow-btn {
  margin-top: 5px;
}

/* Gaming Post */
.gaming-post .feature-image {
  border-radius: 5px;
  position: relative;
}

.gaming-post .feature-image .meta li {
  position: relative;
}

.gaming-post .feature-image .meta li:after {
  position: absolute;
  content: '';
  right: -5px;
  top: 7px;
  height: 14px;
  width: 2px;
  background: #d2c6bf;
}

.gaming-post .feature-image .meta li:last-child:after {
  display: none;
}

.gaming-post .feature-image .meta li a {
  color: #fff;
  font-size: 16px;
}

.gaming-post .feature-image .meta li a i {
  color: #f68b1f;
}

.gaming-post .feature-image .meta li a:hover {
  color: #f68b1f;
}

.gaming-post .feature-image .blog-content {
  position: absolute;
  left: 20px;
  bottom: 10px;
  width: 90%;
}

.gaming-post .feature-image .blog-content .blog-title {
  font-size: 18px;
  line-height: 25px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 5px;
}

.gaming-post .feature-image .blog-content .blog-title a {
  color: #fff;
}

.gaming-post .feature-image .blog-content .blog-title a:hover {
  color: #f68b1f;
}

.gaming-post .feature-image .blog-content p {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}

/* Blog Post Clane */
#latest-news-clan-two {
  background-size: cover;
}

.clan-blog-post {
  padding: 15px;
  background: #1a1c20;
  border-radius: 7px;
}

.clan-blog-post img {
  max-width: 100%;
}

.clan-blog-post .content {
  margin-top: 25px;
}

.clan-blog-post .content h3 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  margin: 0;
}

.clan-blog-post .content h3 a {
  color: #fff;
}

.clan-blog-post .content h3 a:hover {
  color: #f68b1f;
}

.clan-blog-post .content .meta.meta-grey li {
  position: relative;
  margin-right: 5px;
}

.clan-blog-post .content .meta.meta-grey li:after {
  content: '.';
  position: absolute;
  right: -4px;
  bottom: 0;
  font-size: 32px;
  color: #4f4f4f;
  line-height: 37px;
}

.clan-blog-post .content .meta.meta-grey li:last-child:after {
  display: none;
}

.clan-blog-post .content .meta.meta-grey li a {
  font-size: 14px;
}

.clan-blog-post .content p {
  color: #b2b2b2;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
  margin-top: 10px;
}

.clan-blog-post-list {
  padding: 15px;
  background: #1a1c20;
  border-radius: 7px;
}

.clan-blog-post-list .clan-blog-list {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #292929;
}

.clan-blog-post-list .clan-blog-list a img {
  border-radius: 10px;
}

.clan-blog-post-list .clan-blog-list .content {
  margin-left: 100px;
}

.clan-blog-post-list .clan-blog-list .content h4 {
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}

.clan-blog-post-list .clan-blog-list .content h4 a {
  color: #fff;
}

.clan-blog-post-list .clan-blog-list .content h4 a:hover {
  color: #f68b1f;
}

.clan-blog-post-list .clan-blog-list .meta li a {
  font-size: 14px;
}

.clan-blog-post-list .game-video-item {
  border-radius: 10px;
}

.author-about .avatar {
  float: left;
  position: relative;
  z-index: 5;
  padding: 15px;
}

.author-about .avatar img {
  z-index: 5;
  height: 75px;
  width: 75px;
  border-radius: 50%;
}

.author-about .avatar:before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  background-image: url("../media/blog/49.png");
  height: 105px;
  width: 105px;
  z-index: -1;
}

.author-about .content {
  margin-left: 130px;
}

.author-about .content .author-name {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  margin: 0;
}

.author-about .content .social-link {
  margin: 0;
  padding: 0;
  list-style: none;
}

.author-about .content .social-link li {
  display: inline-block;
  margin-right: 10px;
}

.author-about .content .social-link li a {
  color: #fff;
  font-size: 14px;
}

.author-about .content p {
  font-size: 14px;
  line-height: 25px;
  color: #fff;
  margin-bottom: 0;
}

.comments-wrap .comments {
  margin: 0;
  padding: 0;
  list-style: none;
}

.comments-wrap .comments .comments-body .comments-inner {
  background: #090808;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 2px solid #65892f;
}

.comments-wrap .comments .comments-body .comments-inner .avatar-left {
  float: left;
}

.comments-wrap .comments .comments-body .comments-inner .avatar-right {
  float: right;
}

.comments-wrap .comments .comments-body .comments-inner .avatar-left img {
  border-radius: 50%;
}

.comments-wrap .comments .comments-body .comments-inner .avatar-right img {
  border-radius: 50%;
}

.comments-wrap .comments .comments-body .comments-inner .avatar-comments {
  padding: 13px;
}

.comments-wrap .comments .comments-body .comments-inner .avatar-comments-left {
  margin-left: 90px;
  padding: 13px;
}

.comments-wrap .comments .comments-body .comments-inner .avatar-comments-right {
  margin-right: 90px;
  padding: 13px;
}

.comments-wrap .comments .comments-body .comments-inner .avatar-comments p {
  margin: 0;
}

.comments-wrap .comments .comments-body .comments-inner .avatar-comments-left p {
  margin: 0;
}

.comments-wrap .comments .comments-body .comments-inner .avatar-comments-right p {
  margin: 0;
}

.comments-wrap .comments .comments-body .comments-inner .comments-author {
  display: inline-block;
  position: relative;
}

.comments-wrap .comments .comments-body .comments-inner .comments-author a {
  color: #dedede;
  font-size: 16px;
  font-weight: 700;
}

.comments-wrap .comments .comments-body .comments-inner .comments-author a:hover {
  color: #ffc107;
}

.comments-wrap .comments .comments-body .comments-inner .avatar-meta {
  margin: 0 15px;
  display: inline-block;
  color: #858585;
  font-size: 16px;
  font-weight: 500;
  position: relative;
}

.comments-wrap .comments .comments-body .comments-inner .avatar-meta:hover {
  color: #ffc107;
}

.comments-wrap .comments .comments-body .comments-inner .avatar-meta:hover:before, .comments-wrap .comments .comments-body .comments-inner .avatar-meta:hover:after {
  color: #858585;
}

.comments-wrap .comments .comments-body .comments-inner .avatar-meta:after, .comments-wrap .comments .comments-body .comments-inner .avatar-meta:before {
  position: absolute;
  content: '|';
  top: 1px;
}

.comments-wrap .comments .comments-body .comments-inner .avatar-meta:before {
  left: -10px;
}

.comments-wrap .comments .comments-body .comments-inner .avatar-meta:after {
  right: -10px;
}

.comments-wrap .comments .comments-body .comments-inner .reply {
  color: #ffc107;
  font-size: 16px;
  font-weight: 500;
}

.comments-wrap .comments .reply-comment {
  margin-left: 50px;
}

.comments-wrap .title {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  margin: 0 0 20px;
}

.comments-form .form-comments textarea, .comments-form .form-comments input {
  width: 100%;
  background: #000;
  outline: 0;
  border: 0;
  color: #fff;
}

.comments-form .form-comments textarea::-webkit-input-placeholder, .comments-form .form-comments input::-webkit-input-placeholder {
  color: #fff;
}

.comments-form .form-comments textarea:-ms-input-placeholder, .comments-form .form-comments input:-ms-input-placeholder {
  color: #fff;
}

.comments-form .form-comments textarea::placeholder, .comments-form .form-comments input::placeholder {
  color: #fff;
}

.comments-form .form-comments textarea {
  height: 200px;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 2px solid #65892f;
}

.comments-form .form-comments input {
  width: 50%;
  float: left;
  padding: 6px 20px;
  margin-bottom: 20px;
  height: 45px;
}

.comments-form .form-comments input.br {
  border-right: 1px solid #2d2d2d;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.comments-form .form-comments input.bdr {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.comments-form .form-comments .tim-btn-bg {
  cursor: pointer;
  padding: 16px 59px;
  margin-bottom: 20px;
  display: inline-block;
  background: #ea1d25;
}

.comments-form .form-comments .tim-btn-bg:hover {
  background: #fd0e0e;
}

/* Ganing Post */
#gameing-post-three {
  background: #13131f;
}

/* Blog Grid */
.blog-post-grid {
  padding-bottom: 20px;
  background: #1b1b1b;
  border-radius: 5px;
  margin-bottom: 30px;
}

.blog-post-grid .featire-image {
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.blog-post-grid .featire-image img {
  max-width: 100%;
  transition: all 0.3s ease-in-out;
}

.blog-post-grid .featire-image:hover img {
  -ms-transform: scale(1.1) rotate(3deg);
  transform: scale(1.1) rotate(3deg);
}

.blog-post-grid .post-type-icon {
  height: 55px;
  width: 55px;
  text-align: center;
  display: block;
  position: relative;
  z-index: 111;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  line-height: 55px;
  -ms-transform: translate(30px, -27px);
  transform: translate(30px, -27px);
  position: absolute;
}

.blog-post-grid .post-type-icon span {
  display: block;
  font-size: 16px;
}

.blog-post-grid .post-type-icon:before {
  position: absolute;
  content: '';
  height: 55px;
  width: 55px;
  background: #f68b1f;
  left: 0;
  top: 0;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 4px;
  z-index: -1;
}

.blog-post-grid .post-type-icon:after {
  position: absolute;
  content: '';
  border: 2px solid #fff;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  left: 0;
  top: 0;
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.blog-post-grid .blog-content {
  padding: 50px 25px 0;
}

.blog-post-grid .blog-content .blog-title {
  font-size: 20px;
  line-height: 25px;
  margin: 15px 0;
}

.blog-post-grid .blog-content .blog-title a {
  color: #dedede;
}

.blog-post-grid .blog-content .blog-title a:hover {
  color: #f68b1f;
}

.blog-post-grid .blog-content p {
  font-size: 16px;
  color: #dedede;
  line-height: 24px;
  margin-top: 10px;
}

.blog-post-grid .blog-content .tim-btn {
  font-size: 11px;
  line-height: 15px;
  padding: 5px 12px;
  border-radius: 3px;
}

.blog-post-grid .blog-content .tim-btn:hover {
  color: #fff;
}

.blog-meta {
  margin: 0;
  padding: 0;
  list-style: none;
}

.blog-meta li {
  display: inline-block;
  margin-right: 5px;
  color: #dedede;
  font-size: 14px;
}

.blog-meta li i {
  margin-right: 7px;
  color: #f68b1f;
}

.blog-meta li .avatar {
  margin-right: 10px;
}

.blog-meta li .date {
  color: #dedede;
}

.blog-meta li .date:hover {
  color: #ffc107;
}

.blog-meta li a {
  font-size: 14px;
  color: #dedede;
  font-weight: 500;
}

.blog-meta li a:hover {
  color: #f68b1f;
}

.blog-grid {
  background: #131313;
}

.blog-grid-two-column {
  background: #000;
}

.blog-grid-four {
  background-attachment: fixed;
}

.blog-masonry {
  background: #131313;
  padding: 100px 0;
}

.single-post {
  padding: 50px 0;
  background: #131313;
}

.blog-details .meta {
  margin-bottom: 20px;
}

.blog-details p {
  color: #b1b1b1;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 30px;
}

.blog-details .youtube-wrapper {
  margin-bottom: 30px;
}

.blog-details .youtube-wrapper:before {
  font-size: 80px;
}

.blog-details .youtube-wrapper .youtube-poster {
  height: 500px;
}

.blog-details h3 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 10px;
  color: #fff;
}

.blog-details blockquote {
  padding: 35px 30px;
  background: #1f1f1f;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  border-left: 6px solid #f68b1f;
  margin-bottom: 20px;
  position: relative;
}

.blog-details .blog-inner-thumb {
  margin-bottom: 30px;
}

.blog-details .blog-inner-thumb img {
  max-width: 100%;
  height: auto;
}

.tagCloud a {
  display: inline-block;
  padding: 0 10px;
  color: #e9e9e9;
  background: #0d0b0b;
  font-size: 14px;
  line-height: 30px;
}

.tagCloud a:hover {
  background: #f68b1f;
}

.share {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.share li {
  display: inline-block;
  position: relative;
  transition: all 0.3s ease-in-out;
  margin-right: 5px;
}

.share li a {
  color: #fff;
  display: block;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 44px;
  border-radius: 50%;
}

.share li a.bg_facebook {
  background: #3b5998;
}

.share li a.bg_twitter {
  background: #1da1f2;
}

.share li a.bg_linkedin {
  background: #0A66C2;
}

.share li a.bg_whatsapp {
  background: #25D366;
}

.share li a.bg_reddit {
  background: #FF5700;
}

.share li a.bg_telegram {
  background: #229ED9;
}

.share li .like-count {
  display: block;
  position: absolute;
  height: 35px;
  width: 60px;
  background: #fff;
  left: 50%;
  top: -47px;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 5px;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  color: #303030;
}

.share li .like-count:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  position: absolute;
  bottom: -10px;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.share li:hover .like-count {
  opacity: 1;
  visibility: visible;
}

.author-about-inner {
  margin-top: 40px;
  border-bottom: 1px solid #252525;
}

.blog-comments-inner, .author-about-inner {
  padding: 50px;
}

.blog-comments-inner .commtent-title, .author-about-inner .commtent-title {
  color: #fff;
  font-size: 25px;
  line-height: 28px;
  margin-bottom: 20px;
  font-weight: 500;
}

.blog-comments-inner .comments-body p, .author-about-inner .comments-body p {
  color: #fff;
}

.blog-comments-inner .comments-form input[type="text"], .blog-comments-inner .comments-form textarea, .author-about-inner .comments-form input[type="text"], .author-about-inner .comments-form textarea {
  background: #000;
}

.blog-comments-inner .comments-form input[type="text"]::-webkit-input-placeholder, .blog-comments-inner .comments-form textarea::-webkit-input-placeholder, .author-about-inner .comments-form input[type="text"]::-webkit-input-placeholder, .author-about-inner .comments-form textarea::-webkit-input-placeholder {
  color: #929292;
}

.blog-comments-inner .comments-form input[type="text"]:-ms-input-placeholder, .blog-comments-inner .comments-form textarea:-ms-input-placeholder, .author-about-inner .comments-form input[type="text"]:-ms-input-placeholder, .author-about-inner .comments-form textarea:-ms-input-placeholder {
  color: #929292;
}

.blog-comments-inner .comments-form input[type="text"]::placeholder, .blog-comments-inner .comments-form textarea::placeholder, .author-about-inner .comments-form input[type="text"]::placeholder, .author-about-inner .comments-form textarea::placeholder {
  color: #929292;
}

#related-post {
  position: relative;
  background: #131313;
  padding: 0 0 50px;
}

#related-post .title {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 40px;
}

#related-post .slider-nav-prev:before, #related-post .slider-nav-next:before {
  background: #ec4e00;
}

.full-width {
  max-width: 970px;
  width: 100%;
  margin: 0 auto;
}

.full-width .blog-details .youtube-wrapper .youtube-poster {
  height: 580px;
}

.nav-links {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  margin-top: 70px;
}

.nav-links li {
  display: inline-block;
  text-align: center;
  margin-right: 10px;
}

.nav-links li a {
  display: block;
  height: 30px;
  width: 30px;
  background: #232323;
  color: #fff;
  line-height: 30px;
  border-radius: 4px;
}

.nav-links li a:hover, .nav-links li a.active {
  background: #f68b1f;
}

.nav-links li:nth-child(2) {
  position: absolute;
  bottom: -40px;
}

.nav-links li:nth-child(4) {
  position: absolute;
  top: -40px;
  left: 43px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .upc-game-items .item .feature-image {
    max-width: 130px;
  }
  .upc-game-items .item .content {
    margin-left: 140px;
  }
  .upc-game-items .item .content .blog-title {
    font-size: 14px;
    margin-top: 0;
  }
  .upc-game-items .item .content .cat li a {
    padding: 0 13px;
  }
}

@media (max-width: 1024px) {
  .blog-content .blog-title {
    font-size: 22px;
    margin: 10px 0;
    z-index: 333;
  }
  .blog-content .content {
    font-size: 14px;
    line-height: 22px;
  }
  .cat li a {
    padding: 0 7px;
  }
  .upc-game-items .item .content .blog-title {
    font-size: 16px;
  }
  .recent-news .blog-title {
    font-size: 22px;
    margin: 10px 0;
  }
  .blog-details {
    padding: 0 20px;
  }
  .clan-blog-post-list {
    margin-bottom: 30px;
  }
  .clan-blog-post-list .clan-blog-list .meta li a {
    font-size: 13px;
  }
  .clan-blog-post-list .content {
    margin-left: 90px;
  }
  .clan-blog-post-list .content h3 {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .blog-post-three {
    margin-bottom: 20px;
  }
  .gaming-post {
    max-width: 570px;
    margin: 0 auto 30px;
  }
  .gaming-post-inner {
    max-width: 600px;
    margin: 0 auto;
  }
  .blog-post {
    margin-bottom: 30px;
  }
  .latest-post.game-blog-post {
    max-width: 370px;
  }
  .game-large-player .youtube-wrapper {
    margin-bottom: 30px;
  }
  .blog-post-two.blog-large, .latest-post.blog-large {
    margin-bottom: 30px;
  }
  .recent-news {
    margin-bottom: 30px;
  }
  .upc-game-items {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  #blog-two, #gameing-post-three {
    padding: 50px 0;
  }
  .blog-post {
    max-width: 370px;
    margin: 0 auto 30px;
  }
  .blog-post-three {
    max-width: 450px;
    margin: 0 auto 30px;
  }
  .blog-post-grid {
    max-width: 450px;
    margin: 0 auto 30px;
  }
  .blog-content .blog-title {
    font-size: 20px;
    margin: 10px 0;
    z-index: 333;
  }
  .blog-post-two.blog-large .feature-image .blog-content .blog-title {
    font-size: 25px;
    line-height: 30px;
  }
  .latest-post.blog-large, .latest-post.blog-mid {
    margin-bottom: 20px;
  }
  .latest-post.blog-small:hover .blog-content .content .read-more {
    margin-top: 180px;
  }
  .related-game {
    margin-bottom: 20px;
  }
  .blog-post-two.blog-large {
    margin-bottom: 10px;
  }
  .top-mews-feature {
    margin-bottom: 20px;
  }
  .upc-game-items {
    margin-bottom: 20px;
  }
  .blog-details .blog-title {
    font-size: 25px;
  }
  .blog-inner-thumb .pr__15 {
    padding-right: 0 !important;
    margin-bottom: 15px;
  }
  .blog-details p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 15px;
  }
  .blog-details blockquote {
    font-size: 18px;
    line-height: 26px;
  }
  .game-large-player .youtube-wrapper:before {
    font-size: 65px;
  }
  .tagCloud a {
    font-size: 14px;
    padding: 5px 8px;
  }
  .blog-comments-inner, .author-about-inner {
    padding: 25px;
  }
  .latest-post.game-blog-post .blog-content .blog-title a {
    font-size: 16px;
  }
  .latest-post.game-blog-post .blog-content .follow-btn {
    bottom: 28px;
  }
  .review-wrap .review li {
    font-size: 12px;
  }
}

@media (max-width: 500px) {
  .blog-post-two .feature-image .blog-content {
    bottom: 0;
  }
  .blog-post-two.blog-large .feature-image .blog-content .blog-title {
    margin: 0;
  }
  .blog-post-two.blog-large .feature-image .blog-content .blog-title {
    font-size: 20px;
    line-height: 25px;
  }
  .cat li a {
    padding: 0 8px;
    font-size: 13px;
  }
  .upc-game-items .item .content .blog-title {
    font-size: 14px;
    line-height: 20px;
  }
  .review-wrap .review li {
    font-size: 12px;
  }
  .recent-news .blog-title {
    font-size: 20px;
    line-height: 25px;
    margin-top: 10px;
  }
  .top-mews-feature {
    margin-bottom: 20px;
  }
  .top-mews-feature .blog-content .blog-title {
    font-size: 25px;
    line-height: 25px;
  }
  .comments-wrap .comments .reply-comment {
    margin-left: 20px;
  }
  .comments-wrap .comments .comments-body .comments-inner {
    padding: 15px;
  }
  .comments-wrap .comments .comments-body .comments-inner .avatar-comments-left {
    margin-left: 80px;
  }
  .comments-wrap .comments .comments-body .comments-inner .avatar-comments-right {
    margin-right: 80px;
  }
  .comments-wrap .comments .comments-body .comments-inner .comments-author {
    margin-bottom: 0;
  }
  .comments-wrap .comments .comments-body .comments-inner .comments-author a {
    font-size: 14px;
  }
  .comments-wrap .comments .comments-body .comments-inner .avatar-meta {
    font-size: 14px;
    margin: 0 10px;
  }
  .author-about .content {
    margin-left: 115px;
  }
  .author-about .content p {
    line-height: 20px;
  }
  .blog-comments-inner p, .author-about-inner p {
    font-size: 14px;
    line-height: 20px;
  }
  .gaming-post .feature-image .blog-content .blog-title {
    font-size: 16px;
    line-height: 18px;
  }
  .upc-game-two .feature-image {
    float: none;
    margin: 0 auto;
  }
  .upc-game-two .game-content {
    margin-left: 0;
    margin-top: 20px;
    text-align: center;
  }
  .upc-game-two .game-content h3 {
    font-size: 20px;
  }
  .latest-post.game-blog-post .blog-content .follow-btn {
    bottom: 25px;
  }
  .author-about {
    text-align: center;
  }
  .author-about .avatar {
    float: none;
    width: 105px;
    margin: 0 auto 15px;
  }
  .author-about .content {
    margin-left: 0;
  }
  .comments-wrap .comments .comments-body .comments-inner {
    text-align: center;
  }
  .comments-wrap .comments .comments-body .comments-inner .avatar-left {
    float: none;
    margin: 0 auto 15px;
  }
  .comments-wrap .comments .comments-body .comments-inner .avatar-right {
    float: none;
    margin: 0 auto 15px;
  }
  .comments-wrap .comments .comments-body .comments-inner .avatar-comments-left {
    margin-left: 0;
  }
  .comments-wrap .comments .comments-body .comments-inner .avatar-comments-right {
    margin-right: 0;
  }
  .section-title-two .title:before, .section-title-two .title:after {
    display: none;
  }
}

@media (max-width: 400px) {
  .upc-game-items .item {
    text-align: center;
  }
  .upc-game-items .item .feature-image {
    float: none;
  }
  .upc-game-items .item .content {
    margin-left: 0;
    margin-top: 20px;
    text-align: center;
  }
}

/*--------------------------------------------------------------
  ##  Video
  --------------------------------------------------------------*/
#video {
  padding: 300px 0;
  text-align: center;
  position: relative;
  background-position: center center;
  background-size: cover;
}

.video-btn {
  height: 85px;
  width: 85px;
  border: 10px solid #f68b1f;
  text-align: center;
  line-height: 84px;
  display: inline-block;
  border-radius: 50%;
  padding-left: 10px;
  position: relative;
  outline: 0;
  background: #fff;
}

.video-btn:after {
  position: absolute;
  content: '';
  height: 161%;
  width: 161%;
  top: -20px;
  border: 10px solid rgba(246, 139, 41, 0.5);
  left: -20px;
  border-radius: 50%;
}

.video-btn i {
  font-size: 35px;
  color: #f68b1f;
}

#video-two {
  padding: 200px 0 60px;
  text-align: center;
  background-size: cover;
  background-position: center center;
}

.play-button {
  margin-bottom: 30px;
}

.play-button i {
  font-size: 117px;
  color: #fff;
  margin-bottom: 20px;
}

.play-button h3 {
  color: #fff;
  font-weight: 700;
  font-size: 30px;
}

.meta-inner .meta {
  margin-bottom: 20px;
}

.meta-inner .meta li {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
}

.meta-inner .tim-btn-bg {
  padding: 14px 42px;
  font-size: 20px;
  display: inline-block;
}

@media (max-width: 1024px) {
  #video {
    padding: 150px 0;
  }
}

@media (max-width: 768px) {
  #video-two {
    padding: 100px 0 50px;
  }
  .play-button i {
    font-size: 75px;
  }
}

/*--------------------------------------------------------------
  ##  Behind
  --------------------------------------------------------------*/
#behind {
  background-attachment: fixed;
  background-size: cover;
}

.behind-items .item {
  padding: 50px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.behind-items .item .icone-wrap {
  height: 85px;
  line-height: 85px;
  width: 100px;
  text-align: center;
  margin: 0 auto 20px;
}

.behind-items .item .icone-wrap i {
  font-size: 80px;
  color: #a0a0a0;
  transition: all 0.3s ease-in-out;
}

.behind-items .item .title {
  font-size: 25px;
  font-weight: 700;
  color: #d4d4d4;
  letter-spacing: 0.10px;
}

.behind-items .item .content {
  font-size: 16px;
  font-weight: 500;
  color: #949494;
}

.behind-items .item:hover {
  background: #131313;
  z-index: 99;
}

.behind-items .item:hover .icone-wrap i {
  color: #65892f;
}

.behind-items .item:hover .title {
  color: #65892f;
}

.bg__one {
  background: #0b0b15;
}

.bg__two {
  background: #1b1b27;
}

@media (max-width: 1024px) {
  .behind-items .item {
    padding: 30px 20px !important;
  }
}

@media (max-width: 768px) {
  .behind-items .item {
    max-width: 90%;
    margin: 0 auto 20px;
  }
  .skew__left, .skew__right {
    -ms-transform: skewX(0deg) !important;
    transform: skewX(0deg) !important;
  }
  .skew__left .items-content, .skew__right .items-content {
    -ms-transform: skewX(0deg) !important;
    transform: skewX(0deg) !important;
  }
}

@media (max-width: 500px) {
  .skew__left, .skew__right {
    -ms-transform: skewX(0deg) !important;
    transform: skewX(0deg) !important;
  }
  .skew__left .items-content, .skew__right .items-content {
    -ms-transform: skewX(0deg) !important;
    transform: skewX(0deg) !important;
  }
}

/*--------------------------------------------------------------
  ##  Live Stream
  --------------------------------------------------------------*/
.tim-tab-nav li {
  display: inline-block;
  line-height: 20px;
}

.tim-tab-nav li a {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  margin-right: 35px;
}

.tim-tab-nav li a:hover, .tim-tab-nav li a.active {
  color: #f68b1f;
}

#live-stream {
  background-size: cover;
  background-position: center center;
}

#live-stream .tim-tab-nav {
  margin-bottom: 20px;
}

#live-stream .tim-tab-nav li a {
  padding-bottom: 16px;
}

#live-stream .tim-tab-nav li a:after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background: #f68b1f;
  transition: all 0.3s ease-in-out;
}

#live-stream .tim-tab-nav li a:hover, #live-stream .tim-tab-nav li a.active {
  color: #FFF;
}

#live-stream .tim-tab-nav li a:hover:after, #live-stream .tim-tab-nav li a.active:after {
  width: 40px;
}

.game-video-item {
  overflow: hidden;
  position: relative;
}

.game-video-item .blog-content {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.game-video-item .video-play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #FFF;
  font-size: 50px;
  outline: none;
}

.game-height .feature-image img {
  width: auto;
  height: 193.5px;
}

.youtube-banner {
  height: 100%;
  overflow: hidden;
}

.youtube-wrapper {
  position: relative;
  padding-bottom: 59.7%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background-color: #191a1c;
  cursor: pointer;
}

.youtube-wrapper:before {
  content: "\f144";
  font-family: 'FontAwesome';
  font-size: 50px;
  margin-top: 7px;
  margin-left: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  z-index: 2;
  cursor: pointer;
  visibility: visible;
  transform: translate3d(-50%, -50%, 0) scale(1);
  -moz-transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  transition: transform .2s, opacity .2s;
  -moz-transition: transform .2s, opacity .2s;
  -webkit-transition: transform .2s, opacity .2s;
}

.youtube-wrapper.reveal:after, .youtube-wrapper.reveal:before, .youtube-wrapper.reveal .blog-content {
  opacity: 0;
  visibility: hidden;
  transition: visibility .4s, opacity .4s;
}

.youtube-wrapper.reveal iframe {
  visibility: visible;
}

.youtube-wrapper iframe, .youtube-wrapper object, .youtube-wrapper embed {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  visibility: hidden;
}

.youtube-wrapper .youtube-poster {
  height: 387px;
  background-position: center;
  background-size: cover;
}

@media (max-width: 1024px) {
  .game-height .feature-image img {
    height: auto;
  }
}

@media (max-width: 991px) {
  .game-video-item .youtube-wrapper {
    padding-bottom: 57.2%;
  }
}

@media (max-width: 768px) {
  .game-video-item {
    margin-bottom: 20px;
  }
  .game-video-item .youtube-wrapper {
    padding-bottom: 57.2%;
  }
  .game-height .feature-image img {
    width: 100%;
    height: auto;
  }
  .tim-tab-nav li a {
    margin-right: 10px;
  }
}

/*--------------------------------------------------------------
  ##  Game Strategy
  --------------------------------------------------------------*/
#game-strategy .section-icon-title {
  padding: 25px 20px;
}

.tim-tab-nav-two {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 20px;
}

.tim-tab-nav-two li {
  display: inline-block;
  text-align: center;
  width: 50%;
  float: left;
}

.tim-tab-nav-two li a {
  padding: 21px 0;
  color: #fff;
  display: inline-block;
  width: 100%;
  background: #272737;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}

.tim-tab-nav-two li a:hover, .tim-tab-nav-two li a.active {
  background: #f68b1f;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.tab-content .tab-pane.active {
  animation: fadeIn .75s ease forwards;
}

.latest-post.stategy-small {
  text-align: center;
}

.latest-post.stategy-small .blog-content {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: auto;
}

.latest-post.stategy-small .blog-content:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(246, 139, 31, 0);
  transition: all 0.3s ease-in-out;
}

.latest-post.stategy-small .blog-content .content {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  left: 0;
  height: auto;
  z-index: 2;
}

.latest-post.stategy-small .blog-content .content .blog-title {
  margin-top: 0;
}

.latest-post.stategy-small:hover .blog-content:after {
  background: rgba(246, 139, 31, 0.7);
}

.latest-post.stategy-mid.stategy .blog-title {
  font-size: 25px;
  line-height: 30px;
}

@media (max-width: 500px) {
  .game-tips .cart-btn-two, .game-tips .button-wrap .popup-btn-two {
    padding: 12px 15px;
    font-size: 14px;
  }
  .content-with-image img {
    width: 100%;
  }
  .game-tips p:first-child {
    margin-bottom: 10px;
  }
  .section-icon-title .float-left {
    float: none !important;
    margin-bottom: 10px;
  }
  .tim-tab-nav li a {
    margin-right: 10px;
  }
}

/*--------------------------------------------------------------
  ##  Add
  --------------------------------------------------------------*/
.add-big-wrap {
  position: relative;
}

.add-big-wrap img {
  width: 100%;
}

.add-big-wrap .adds-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 80%;
  height: 100%;
}

.add-big-wrap .adds-content .content {
  position: absolute;
  top: 50%;
  left: 70px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.add-big-wrap .adds-content .add-title a {
  color: #fff;
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  margin-top: 0;
}

.add-big-wrap .adds-content .add-title a:hover {
  color: #f68b1f;
}

.add-big-wrap .adds-content .popup-btn {
  font-size: 16px;
  font-weight: 600;
  padding: 8px 28px;
}

.add-big-wrap .adds-content .popup-btn i {
  background: transparent;
  height: auto;
  width: auto;
  color: #fff;
}

.add-detail {
  position: absolute;
  right: 0;
  top: 0;
  width: 30%;
  height: 100%;
  text-align: right;
  padding: 10px 30px 30px 0;
}

.add-detail .add-batch {
  background: #000;
  color: #fff;
  font-size: 14px;
  padding: 0 9px;
  line-height: 20px;
  display: inline-block;
}

.add-detail .add-size-title {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin: 25px 0 0;
}

.add-detail .advertize {
  color: #fff;
  position: absolute;
  left: 20px;
  right: 30px;
  font-weight: 700;
  bottom: 20px;
  margin: 0;
  font-size: 14px;
}

.add-small {
  position: relative;
}

.add-small img {
  max-width: 100%;
}

.add-small .adds-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px 10px 20px 20px;
}

.add-small .adds-content .add-batch {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 14px;
  padding: 0 9px;
  line-height: 20px;
  display: inline-block;
}

.add-small .adds-content .add-title {
  font-size: 25px;
  line-height: 30px;
  margin-top: 30px;
}

.add-small .adds-content .add-title a {
  color: #fff;
  margin: 0;
  font-weight: 700;
}

.add-small .adds-content .add-title a:hover {
  color: #f68b1f;
}

.add-small .adds-content .add-detail {
  width: 100%;
  bottom: 0;
  text-align: left;
}

.add-small .adds-content .popup-btn {
  padding: 6px 17px;
  font-size: 16px;
}

.add-small .adds-content .popup-btn i {
  background: transparent;
  color: #fff;
  height: auto;
  width: auto;
}

.add-small .adds-content .add-size-title {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  margin-top: 30px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .add-small .adds-content {
    padding: 10px;
  }
  .add-small .adds-content .add-title {
    font-size: 15px;
    line-height: 20px;
  }
  .add-small .adds-content .popup-btn {
    padding: 6px 10px;
    font-size: 14px;
    margin-top: 0;
  }
  .add-small .adds-content .add-size-title {
    font-size: 12px;
    margin-top: 7px;
  }
  .popup-btn {
    margin-top: 10px;
  }
  .add-big-wrap .adds-content .add-title {
    line-height: 25px;
  }
  .add-big-wrap .adds-content .add-title a {
    font-size: 22px;
    line-height: 25px;
  }
}

@media (max-width: 768px) {
  .add-big-wrap {
    margin-bottom: 10px;
  }
  .add-big-wrap.pr__15 {
    padding-right: 0 !important;
  }
  .add-big-wrap img {
    height: 200px;
    width: 100%;
  }
  .add-big-wrap .adds-content .content {
    left: 30px;
  }
  .add-big-wrap .adds-content .add-title {
    line-height: 25px;
  }
  .add-big-wrap .adds-content .add-title a {
    font-size: 20px;
    line-height: 25px;
  }
  .add-small {
    height: 250px;
  }
  .add-small img {
    height: 100%;
    width: auto;
  }
  .add-small .adds-content .add-title {
    font-size: 18px;
    line-height: 25px;
    margin-top: 10px;
  }
  .add-small .float-right {
    float: none !important;
  }
}

@media (max-width: 500px) {
  .add-big-wrap .adds-content .add-title br {
    display: none;
  }
  .add-big-wrap {
    overflow: hidden;
    margin-bottom: 20px;
  }
  .add-big-wrap img {
    height: 100%;
    width: auto;
  }
  .add-big-wrap .adds-content {
    width: 100%;
  }
  .add-big-wrap .adds-content .content {
    position: static;
    left: 35px;
    -ms-transform: translate(0);
    transform: translate(0);
    padding: 10px;
  }
  .add-detail {
    width: 100%;
    height: 100px;
    bottom: 0;
    top: auto;
    text-align: left;
  }
  .add-detail .add-size-title {
    margin-left: 10px;
    margin-top: 0;
  }
  .add-detail .advertize {
    left: 10px;
  }
}

/*--------------------------------------------------------------
  ##  Newsletter
  --------------------------------------------------------------*/
.news_letter {
  padding: 50px 0;
}

.news_letter.section-padding {
  padding: 30px 0 80px;
}

.newsletter-wrap {
  padding: 50px;
}

.newsletter-wrap .newsletter-content .newsletter-title {
  color: #fff;
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 0;
}

.newsletter-wrap .newsletter-content p {
  color: #fff;
  margin-bottom: 0;
}

.newsletter-form-wrap {
  padding: 25px 0;
}

.newsletter-form-wrap .news-form input {
  height: 46px;
  max-width: 280px;
  width: 100%;
  padding: 0 10px;
  border: 2px solid #fff;
  background: transparent;
  color: #fff;
  margin-right: 4px;
  outline: 0;
}

.newsletter-form-wrap .news-form input::-webkit-input-placeholder {
  color: #fff;
}

.newsletter-form-wrap .news-form input:-ms-input-placeholder {
  color: #fff;
}

.newsletter-form-wrap .news-form input::placeholder {
  color: #fff;
}

.newsletter-form-wrap .news-form button {
  width: 120px;
  text-align: center;
}

.nwl-title h2 {
  color: #fff;
  font-size: 30px;
}

.nwl-title p {
  color: #fff;
  margin-bottom: 25px;
}

#newsletter-two {
  background: #0c0c0c;
  padding: 100px 0;
}

#nsletter-two {
  max-width: 570px;
  margin: 0 auto;
}

#nsletter-two input {
  width: 100%;
  background: #000;
  color: #fff;
  height: 50px;
  border: 0;
  outline: 0;
  padding: 10px 20px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

#nsletter-two input::-webkit-input-placeholder {
  color: #bababa;
}

#nsletter-two input:-ms-input-placeholder {
  color: #bababa;
}

#nsletter-two input::placeholder {
  color: #bababa;
}

.nwl-btn {
  padding: 10px;
  width: 140px;
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
  background: #fd0e0e;
  border: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  outline: 0;
  cursor: pointer;
}

.nwl-btn:after {
  left: -60px;
}

.nwl-btn i {
  display: none;
}

#newsletter-two {
  overflow: hidden;
}

@media (max-width: 1024px) {
  .newsletter-wrap .newsletter-content .newsletter-title {
    font-size: 25px;
  }
}

@media (max-width: 991px) {
  #nsletter-two input {
    border: 1px solid #1d1d1d;
  }
}

@media (max-width: 768px) {
  .newsletter-wrap {
    padding: 30px;
  }
  .newsletter-form-wrap {
    padding: 20px 0 0;
  }
}

@media (max-width: 500px) {
  .news_letter {
    padding: 15px;
  }
  .newsletter-wrap .newsletter-content .newsletter-title {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .newsletter-wrap .newsletter-content p {
    line-height: 20px;
  }
  .news-form {
    text-align: center;
  }
  .news-form input {
    margin-bottom: 10px;
  }
  .news-form.d-flex {
    display: block !important;
  }
  .newsletter-wrap {
    padding: 20px 6px;
  }
  #nsletter-two.d-flex {
    display: block !important;
  }
  #nsletter-two input {
    margin-bottom: 10px;
    border-radius: 5px;
  }
  #nsletter-two .nwl-btn {
    border-radius: 5px;
    padding: 13px;
  }
}

/*--------------------------------------------------------------
  ##  Page Header
  --------------------------------------------------------------*/
#page-header {
  height: 485px;
  background-size: cover;
  background-position: center center;
}

.page-title-wrap {
  position: relative;
  height: 485px;
}

.page-title-wrap .page-title-inner {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  left: 0;
}

.page-title-wrap .page-title-inner h1 {
  color: #fff;
  font-size: 40px;
  margin-bottom: 0;
  font-weight: 700;
  text-transform: uppercase;
}

.page-title-wrap .page-title-inner h1 span {
  color: #f68b1f;
}

.page-title-wrap .page-title-inner h3 {
  color: #fff;
  font-size: 20px;
  margin-bottom: 0;
}

/*.page-title-wrap .page-title-inner:after {
  position: absolute;
  content: '';
  background-image: url("../");
}*/

.page-title-wrap .page-title-inner .delemitter {
  display: block;
  height: 50px;
  background-repeat: no-repeat;
  max-width: 237px;
  margin: 0 auto;
}

@media (max-width: 991px) {
  #page-header {
    height: 200px;
    padding-top: 0;
  }
  .page-title-wrap {
    height: 200px;
  }
  .page-title-wrap h1 {
    font-size: 35px;
  }
}

/*--------------------------------------------------------------
  ##  Killer Page Header
  --------------------------------------------------------------*/
#killer-page-header {
  height: 405px;
  background-size: cover;
  background-position: center center;
  border-bottom: 3px solid #65892f;
}

.killer_bd_left {
  position: relative;
  bottom: -89px;
}

.killer_bd_right {
  display: inline-block;
  padding-top: 180px;
  padding-left: 30px;
}

.killer_bd_right h6 {
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}

.killer_bd_right h1 {
  font-size: 60px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}

.killer_bd_right h1 span {
  color: #f68b1f;
}

.killer_bd_count .single_count {
  display: inline-block;
  text-align: center;
  margin-right: 50px;
  margin-top: 13px;
}

.killer_bd_count .single_count span {
  display: block;
  color: #fff;
  font-weight: 700;
}

.killer_bd_count .single_count span:last-child {
  font-weight: 400;
  color: #f68b1f;
  font-size: 16px;
}

.killer_bd_count_right {
  display: inline-block;
  float: right;
  position: relative;
}

.killer_bd_count_right span {
  display: block;
  position: absolute;
  bottom: 0;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
}

@media (max-width: 991px) {
  .killer_bd_right h1 {
    font-size: 45px;
  }
  .killer_bd_right {
    padding-top: 115px;
  }
  .killer_bd_count .single_count {
    margin-right: 40px;
  }
}

@media (max-width: 767px) {
  #killer-page-header {
    height: auto;
    padding: 30px 0px;
  }
  .killer_bd_right {
    padding-top: 0;
    padding-left: 0;
  }
  .killer_bd_right h1 {
    font-size: 30px;
  }
  .killer_bd_count_right {
    float: none;
    margin-top: 30px;
    display: block;
  }
}

@media (max-width: 575px) {
  .killer_bd_count_right {
    float: right;
    margin-top: 0px;
    display: inline-block;
  }
  .killer_bd_left {
    bottom: 0;
    margin: 0 auto;
  }
}

@media (max-width: 479px) {
  .killer_bd_count_right {
    float: none;
    margin-top: 30px;
    display: block;
  }
}

/*--------------------------------------------------------------
  ##  Killer Page Header Two
  --------------------------------------------------------------*/
#killer-page-header-two {
  height: 405px;
  background-size: cover;
  background-position: center center;
  border-bottom: 3px solid #f68b1f;
}

#killer-page-header-two .killer_bd_left {
  position: relative;
  bottom: -89px;
}

#killer-page-header-two .killer_bd_right {
  display: inline-block;
  padding-top: 142px;
  padding-left: 30px;
}

#killer-page-header-two .killer_bd_right h6 {
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}

#killer-page-header-two .killer_bd_right h1 {
  font-size: 40px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}

#killer-page-header-two .killer_bd_right h1 span {
  color: #f68b1f;
}

.killer_bd_count_down {
  margin-top: 29px;
}

.countdown.countdown-three .CountdownContent {
  color: #fff;
  background: transparent;
  width: auto;
  height: auto;
  line-height: auto;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  margin-right: 30px;
  position: relative;
}

.countdown.countdown-three .CountdownContent .CountdownLabel {
  display: block;
  font-size: 14px;
}

.countdown.countdown-three .CountdownSeparator {
  font-size: 24px;
  color: #fff;
  position: relative;
  top: -20px;
  right: 15px;
}

@media (max-width: 991px) {
  #killer-page-header-two .killer_bd_right h1 {
    font-size: 33px;
  }
}

@media (max-width: 767px) {
  #killer-page-header-two {
    height: auto;
  }
  #killer-page-header-two .killer_bd_left {
    bottom: 0;
  }
  #killer-page-header-two .killer_bd_right {
    padding-top: 30px;
    padding-left: 0;
  }
  .countdown.countdown-three .CountdownContent {
    margin-right: 15px;
  }
  .countdown.countdown-three .CountdownSeparator {
    right: 10px;
  }
  #killer-page-header-two .killer_bd_right h1 {
    font-size: 26px;
  }
}

@media (max-width: 575px) {
  #killer-page-header-two .killer_bd_right h1 {
    font-size: 30px;
  }
}

@media (max-width: 479px) {
  .countdown.countdown-three .CountdownContent {
    margin-right: 15px;
  }
  .countdown.countdown-three .CountdownSeparator {
    right: 10px;
  }
  #killer-page-header-two .killer_bd_right h1 {
    font-size: 23px;
  }
}

/*--------------------------------------------------------------
  ##  Killer History
  --------------------------------------------------------------*/
#killer-history {
  background-size: cover;
  background-repeat: no-repeat;
}

.killer-history-content h1 {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 30px;
}

.killer-history-content h1 span {
  color: #f68b1f;
}

.killer-history-content p {
  margin-bottom: 30px;
}

.killer-tournament-history {
  margin-top: 30px;
}

.killer-tournament-history .single-klr-tournament {
  display: inline-block;
  background: #232830;
  margin-right: 15px;
}

.single-klr-tournament-left {
  display: inline-block;
  padding: 13px 30px;
}

.single-klr-tournament-left h3 {
  font-size: #dfdfdf;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
}

.single-klr-tournament-left h3 span {
  color: #f68b1f;
}

.single-klr-tournament-right {
  display: inline-block;
  height: 110px;
  line-height: 110px;
  border-left: 1px solid #1f2229;
}

.single-klr-tournament-right span {
  font-size: 22px;
  font-weight: 700;
  padding: 0px 25px;
}

@media (max-width: 991px) {
  .single-klr-tournament-left {
    padding: 13px 6px;
  }
  .killer-tournament-history .single-klr-tournament {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .killer-tournament-history {
    text-align: center;
  }
  .killer-tournament-history .single-klr-tournament {
    display: block;
    max-width: 300px;
    margin: 30px auto 0px;
    text-align: left;
  }
  .killer-history-content h1 {
    font-size: 25px;
  }
  .single-klr-tournament-right span {
    padding: 11px;
    font-size: 16px;
  }
  .single-klr-tournament-left {
    padding: 5px;
  }
  .single-klr-tournament-left h3 {
    font-size: 16px;
  }
  .single-klr-tournament-right,
  .single-klr-tournament-left {
    vertical-align: middle;
  }
}

/*--------------------------------------------------------------
  ##  Killer Team
  --------------------------------------------------------------*/
.kill-single-team {
  text-align: center;
}

.kill-single-team:hover .kill-team-social {
  -ms-transform: translate(-50%) scale(1);
  transform: translate(-50%) scale(1);
}

.kill-team-img {
  position: relative;
  border: 10px solid #272c35;
  margin-bottom: 30px;
}

.kill-team-img:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(246, 139, 31, 0.89);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  transition: 0.5s;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
}

.kill-team-img:hover:after {
  -ms-transform: scaleY(1);
  transform: scaleY(1);
}

.kill-team-img img {
  width: 100%;
}

.kill-team-social {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  -ms-transform: translate(-50%) scaleY(0);
  transform: translate(-50%) scaleY(0);
  color: #fff;
  width: 100%;
  transition: 0.5s;
}

.kill-team-social a {
  display: inline-block;
  padding: 0px 8px;
}

.kill-team-social a i {
  font-size: 16px;
  color: #fff;
}

.kill-single-team-hover h3 {
  font-size: 22px;
  color: #f68b1f;
  font-weight: 700;
}

.kill-single-team-hover h3 span {
  display: block;
  color: #fff;
  font-weight: 400;
  margin-top: 10px;
}

@media (max-width: 991px) {
  .kill-team-img {
    border: 0px solid #272c35;
  }
  .kill-team-social a {
    padding: 0px 4px;
  }
}

@media (max-width: 575px) {
  .kill-single-team {
    width: 300px;
    margin: 0px auto 30px;
    margin-bottom: 30px;
  }
}

/*--------------------------------------------------------------
  ##  Killer Winner
  --------------------------------------------------------------*/
#killer-winner.section-padding {
  padding: 100px 0;
  background-attachment: fixed;
  background-size: cover;
  padding-bottom: 50px;
}

.killer-winner-left > h3,
.killer-winner-right > h3,
.killer-win-brackets > h3 {
  font-size: 30px;
  font-weight: 700;
  background: url(../media/logo/logo-pattern-two.png) no-repeat scroll 0 0/cover;
  padding: 30px 30px;
  box-shadow: 0px 6px 6px rgba(42, 40, 40, 0.36), inset 0 0px 2px rgba(167, 167, 167, 0.33);
}

.killer-winner-left {
  margin-bottom: 100px;
}

.killer-winner-left h3 {
  color: #f68b1f;
}

.killer-winner-left li {
  position: relative;
  height: 145px;
  padding: 0px 30px;
  box-shadow: 0px 6px 6px rgba(42, 40, 40, 0.36), inset 0 0px 2px rgba(167, 167, 167, 0.33);
  margin-bottom: 5px;
  font-size: 18px;
}

.killer-winner-left li span {
  color: white;
  position: relative;
  top: 0;
  left: 25%;
  right: 15%;
  bottom: 0;
  z-index: 1;
}

.killer-winner-left li small {
  color: white;
  position: relative;
  top: 0;
  left: 25%;
  right: 15%;
  bottom: 0;
  z-index: 1;
}

.killer-winner-left li::before {
  content: "";
  position: absolute;
  top: 0;
  left: 25%;
  right: 15%;
  bottom: 40%;
  backdrop-filter: blur(10px);
}

.killer-winner-right h3,
.killer-win-brackets h3 {
  color: #dfdfdf;
}

.killer-winner-right ul {
  background: url(../media/logo/logo-pattern-two.png) no-repeat scroll 0 0/cover;
  box-shadow: 0px 6px 6px rgba(42, 40, 40, 0.36), inset 0 0px 2px rgba(167, 167, 167, 0.33);
  padding: 22px 30px;
}

.killer-winner-right ul li {
  margin-bottom: 48px;
}

.killer-winner-right ul li span {
  width: 32%;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  color: #fff;
}

.killer-winner-right ul li span:first-child {
  background: #161616;
  padding: 10px;
  box-shadow: 0px 6px 6px rgba(42, 40, 40, 0.36), inset 0 0px 2px rgba(167, 167, 167, 0.33);
}

.killer-winner-right ul li span:last-child {
  background: #161616;
  padding: 10px;
  box-shadow: 0px 6px 6px rgba(42, 40, 40, 0.36), inset 0 0px 2px rgba(167, 167, 167, 0.33);
}

.killer-winner-right ul li:last-child {
  margin-bottom: 0px;
}

.killer-win-brackets ul {
  margin-bottom: 70px;
}

.killer-win-brackets ul li {
  display: inline-block;
  height: 56px;
  width: 190px;
  line-height: 56px;
  background: url(../media/logo/logo-pattern-two.png) no-repeat scroll 0 0/cover;
  text-align: center;
  color: #dfdfdf;
  font-size: 18px;
  margin-right: 5px;
  box-shadow: 0px 6px 6px rgba(42, 40, 40, 0.36), inset 0 0px 2px rgba(167, 167, 167, 0.33);
}

.killer-win-brackets .kill-game-round-all {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.killer-win-brackets .kill-game-round-group {
  width: 190;
  float: left;
  position: relative;
  margin-right: 116px;
}

.killer-win-brackets .kill-game-round-group .img {
  position: absolute;
  top: 50%;
  left: 100%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.killer-win-brackets .kill-game-round-group:nth-child(2) {
  height: 518px;
}

.killer-win-brackets .kill-game-round-group:nth-child(2) .kill-g-round:last-child {
  position: absolute;
  left: 0;
  bottom: -38px;
}

.killer-win-brackets .kill-game-round-group h3 {
  box-shadow: 0px 6px 6px rgba(42, 40, 40, 0.36), inset 0 0px 2px rgba(167, 167, 167, 0.33);
  text-align: center;
  height: 50px;
  line-height: 50px;
  font-weight: 700;
  font-size: 21px;
  margin-bottom: 36px;
}

.killer-win-brackets .kill-game-round-group:last-child {
  margin-right: 0px;
}

.killer-win-brackets .kill-game-round-group .g_team:last-child {
  margin-bottom: 0px;
}

.killer-win-brackets .kill-g-round {
  width: 190px;
  position: relative;
}

.killer-win-brackets .kill-g-round:last-child {
  margin-bottom: 0px;
}

.killer-win-brackets .kill-g-round .img {
  position: absolute;
  top: 50%;
  left: 100%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.killer-win-brackets .kill-g-round > h3 {
  font-size: 18px;
  color: #fff;
  height: 58px;
  background: url(../media/logo/logo-pattern-two.png) no-repeat scroll 0 0/cover;
  border-radius: 0px;
  box-shadow: 0px 6px 6px rgba(42, 40, 40, 0.36), inset 0 0px 2px rgba(167, 167, 167, 0.33);
  line-height: 58px;
  padding: 0px 20px;
  margin-bottom: 30px;
}

.killer-win-brackets .kill_game {
  margin-bottom: 52px;
}

.killer-win-brackets .kill_game:last-child {
  margin-bottom: 0px;
}

.killer-win-brackets .g_team {
  margin-bottom: 22px;
  position: relative;
  height: 45px;
  background: red;
  line-height: 45px;
  background: url(../media/logo/logo-pattern-two.png) no-repeat scroll 0 0/cover;
  box-shadow: 0px 6px 6px rgba(42, 40, 40, 0.36), inset 0 0px 2px rgba(167, 167, 167, 0.33);
  padding: 0px 20px;
}

.killer-win-brackets .g_team p {
  line-height: 45px;
  float: right;
  font-weight: 800;
  color: #fff;
  font-size: 18px;
}

.killer-win-brackets .g_team span {
  background: #242931;
  border-radius: 5px;
  position: absolute;
  top: -7px;
  background: url(../media/logo/logo-pattern-two.png) no-repeat scroll 0 0/cover;
  box-shadow: 0px 6px 6px rgba(42, 40, 40, 0.36), inset 0 0px 2px rgba(167, 167, 167, 0.33);
  width: 63px;
  height: 60px;
  text-align: center;
  line-height: 60px;
}

@media (max-width: 1199px) {
  .killer-win-brackets .kill-g-round {
    width: 145px;
  }
  .killer-win-brackets .g_team {
    padding: 0px 11px;
  }
}

@media (max-width: 991px) {
  .killer-win-brackets ul li {
    width: 150px;
  }
  .killer-win-brackets .kill-g-round {
    width: 140px;
  }
  .killer-win-brackets .kill-game-round-group {
    margin-right: 50px;
  }
  .killer-win-brackets .kill-game-round-group .img {
    display: none;
  }
  .killer-win-brackets .g_team {
    padding: 0px 5px 0px;
  }
}

@media (max-width: 767px) {
  .killer-winner-left li {
    position: relative;
    height: 63px;
    padding: 0px 30px;
    box-shadow: 0px 6px 6px rgba(42, 40, 40, 0.36), inset 0 0px 2px rgba(167, 167, 167, 0.33);
    margin-bottom: 5px;
    font-size: 13px;
  }
  .killer-winner-left li span {
     color: white;
     position: relative;
     top: -57%;
     left: 40%;
     right: 15%;
     bottom: 0;
     z-index: 1;
    font-size: 12px;
   }

  .killer-winner-left li small {
    color: white;
    position: relative;
    top: -12%;
    left: 25%;
    right: 15%;
    bottom: 0;
    z-index: 1;
  }

  .killer-winner-left li::before {
    content: "";
    position: absolute;
    top: 0;
    left: 25%;
    right: 15%;
    bottom: 40%;
    backdrop-filter: blur(10px);
  }
  .killer-winner-left > h3,
  .killer-winner-right > h3,
  .killer-win-brackets > h3 {
    font-size: 20px;
    padding: 15px;
  }
  .killer-winner-right ul li span {
    width: 31.5%;
  }
  .killer-win-brackets ul li {
    width: 119px;
  }
  .killer-win-brackets .kill-game-round-group {
    margin-right: 30px;
  }
  .killer-win-brackets .g_team {
    padding: 0;
  }
  .killer-win-brackets .g_team p {
    font-size: 16px;
    padding-right: 5px;
  }
  .killer-win-brackets .g_team span {
    top: 0;
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
  .killer-win-brackets .kill-g-round {
    width: 105px;
  }
}

@media (max-width: 575px) {
  .killer-winner-left > h3,
  .killer-winner-right > h3,
  .killer-win-brackets > h3 {
    font-size: 26px;
  }
  .killer-win-brackets ul {
    text-align: center;
  }
  .killer-win-brackets .g_team {
    padding: 0;
    text-align: center;
    box_shadow: none;
  }
  .killer-win-brackets .g_team p {
    display: none;
  }
  .killer-win-brackets .g_team span {
    position: static;
    width: auto;
    height: auto;
    line-height: 0;
  }
  .killer-win-brackets .kill-g-round {
    width: 50px;
    margin-bottom: 0;
  }
  .killer-win-brackets .kill-g-round > h3 {
    padding: 0;
    font-size: 14px;
  }
  .killer-win-brackets .kill-g-round .img {
    display: none;
  }
  .killer-win-brackets .kill-game-round-group {
    margin-right: 80px;
  }
  .killer-win-brackets .kill-game-round-group .img {
    display: none;
  }
  .killer-win-brackets .kill-game-round-group:nth-child(2) {
    height: 484px;
  }
  .killer-win-brackets .kill-game-round-group:nth-child(2):first-child {
    margin-bottom: 309px;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .killer-win-brackets .kill-game-round-group {
    margin-right: 34px;
  }
}

/*--------------------------------------------------------------
  ##  Game Feature
  --------------------------------------------------------------*/
#feature-game {
  position: relative;
  background: #13131f;
}

#feature-game .container {
  position: relative;
}

#feature-game-two {
  position: relative;
}

#feature-game-two .container {
  position: relative;
}

.slider-nav-prev,
.slider-nav-next {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
}

.slider-nav-prev i,
.slider-nav-next i {
  color: #fff;
  font-size: 20px;
  z-index: 2;
}

.slider-nav-prev:before,
.slider-nav-next:before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 40px;
  width: 40px;
  background: #f68b1f;
  z-index: -1;
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.slider-nav-prev:hover,
.slider-nav-next:hover {
  opacity: 0.8;
}

.slider-nav-prev {
  left: -5%;
}

.slider-nav-next {
  right: -5%;
}

.game-feature-slider {
  padding: 0 15px;
}


.game-feature-slider .game-items img {
  max-width: 100%;
  z-index: -1;
}

.game-feature-slider .game-items .item {
  position: static;
}

.game-feature-slider .game-items .item .game-content {
  width: 100%;
  -ms-transform: skew(8deg);
  transform: skew(8deg);
  z-index: 111;
}

.game-feature-slider .game-items .item .game-content h3 {
  line-height: 20px;
  margin-bottom: 0;
}

.game-feature-slider .game-items .item .game-content h3 a {
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
}

.game-feature-slider .game-items .item .game-content h3 a:hover {
  color: #f68b1f;
}

.game-feature-slider .game-items .item .game-content .review li {
  font-size: 13px;
  line-height: 13px;
  color: #ffc107;
}

.game-feature-slider .game-items .item .game-content .meta li {
  margin-right: 0;
}

.game-feature-slider .game-items .item .game-content .meta li a:hover {
  color: #f68b1f;
}

.game-feature-slider .game-items .item .game-content .details-btn {
  position: absolute;
  right: 50px;
  bottom: 30px;
}

.game-feature-slider .game-items .item:hover img {
  -ms-transform: scale(1.02);
  transform: scale(1.02);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media (max-width: 991px) {
  .game-feature-slider .game-items .item .game-content .details-btn {
    right: 28px;
  }
  .game-feature-slider .game-items .item .game-content h3 a {
    font-size: 18px;
  }
  .meta li a {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  #feature-game-nav-prev, #feature-game-nav-next {
    display: none;
  }
}

@media (max-width: 767px) {
  #feature-game-nav-prev, #feature-game-nav-next {
    height: 30px;
    width: 30px;
    line-height: 35px;
    display: none;
  }
  #feature-game-nav-prev:before, #feature-game-nav-next:before {
    height: 30px;
    width: 30px;
  }
}

@media (max-width: 500px) {
  .slider-nav-prev, .slider-nav-next {
    display: none;
  }
  .game-feature-slider .game-items {
    max-width: 270px;
    margin: 0 auto;
  }
}

/*--------------------------------------------------------------
  ##  Tranding Game
  --------------------------------------------------------------*/
#tranding-game {
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

#tranding-game .section-title .section-content p {
  margin-bottom: 30px;
}

.rating-with-social h3 {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  margin: 0;
}

.rating-with-social .review li {
  font-size: 20px;
}

.social-link li {
  display: inline-block;
  margin-right: 10px;
}

.social-link li a {
  color: #fff;
  font-size: 18px;
}

.win-dwn-btn {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  margin-top: 40px;
}

.win-dwn-btn img {
  margin-right: 10px;
}

.win-dwn-btn:hover {
  color: #ffc107;
}

@media (max-width: 500px) {
  .rating-with-social h3 {
    font-size: 20px;
  }
  .rating-with-social .review li {
    font-size: 15px;
  }
  .social-link li a {
    font-size: 13px;
  }
}

/*--------------------------------------------------------------
  ##  Logo Carouser
  --------------------------------------------------------------*/
#logo-carousel {
  background: #0c0c0c;
  padding: 100px 0;
}

#logo-carousel-two {
  background: #1a1c20;
  padding: 100px 0;
}

.tim-logo-carousel {
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.tim-logo-carousel .swiper-wrapper {
  -ms-flex-align: center;
  align-items: center;
}

/*--------------------------------------------------------------
  ##  Overview
  --------------------------------------------------------------*/
#game-download {
  background: #13131f;
}

.download-inner .title {
  font-size: 30px;
  line-height: 40px;
  color: #dedede;
  font-weight: 700;
  margin-bottom: 20px;
}

.download-inner .title span {
  color: #f68b1f;
}

.download-inner p {
  line-height: 30px;
  margin-bottom: 15px;
  font-size: 16px;
}

.download-inner p:last-child {
  margin-bottom: 0;
}

.download-inner .play-store-btn {
  margin-right: 10px;
}

.download-image-wrap {
  text-align: right;
}

.download-image-wrap img {
  max-width: 100%;
}

.download-inner.d-inner-two {
  position: relative;
  margin-bottom: 80px;
}

.download-inner.d-inner-two h2 {
  font-size: 40px;
  line-height: 45px;
}

.download-inner.d-inner-two h2 p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 40px;
}

#game-download-two {
  padding: 150px 0 40px;
  background-size: cover;
  background-position: center center;
}

@media (max-width: 768px) {
  .download-image-wrap {
    margin-top: 30px;
  }
  .download-inner.d-inner-two {
    margin-bottom: 30px;
  }
  .download-inner.d-inner-two h2 {
    font-size: 25px;
    line-height: 30px;
  }
  .download-inner p br {
    display: none;
  }
  #game-download-two {
    text-align: center;
    padding: 100px 0 30px;
  }
}

/*--------------------------------------------------------------
  ##  Purchase Game
  --------------------------------------------------------------*/
#purchase {
  background-size: cover;
  background-position: center center;
  padding: 100px 0 70px;
}

.game-info {
  margin-bottom: 20px;
}

.game-info p {
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}

.game-info p span {
  font-weight: 400;
  font-size: 16px;
}

.purhase-game-inner > p {
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 30px;
}

.purhase-game-inner .tim-btn {
  font-size: 20px;
  line-height: 20px;
  padding: 14px 42px;
  vertical-align: middle;
  margin-top: 20px;
}

.purhase-game-inner .tim-btn:after {
  background: #f68b1f;
}

.purhase-game-inner .tim-btn:hover {
  color: #fff;
  border-color: #f68b1f;
}

/*--------------------------------------------------------------
  ##  Countup
  --------------------------------------------------------------*/
#countup {
  background: #1a1c20;
}

.counter-box .count .counter {
  margin: 0 auto;
  margin-bottom: 5px;
  background-repeat: no-repeat;
}

.counter-box .count .counter span {
  display: inline-block;
  color: #fff;
  font-weight: 700;
  font-size: 36px;
}

.counter-box .count img {
  margin-bottom: 10px;
}

.counter-box .count h3 {
  font-size: 20px;
  font-weight: 500;
  color: #cccccc;
  line-height: 25px;
}

@media (max-width: 991px) {
  .counter-box {
    margin-bottom: 30px;
  }
}

/*--------------------------------------------------------------
  ##  Game Review
  --------------------------------------------------------------*/
.review-inner {
  padding: 20px;
  background-size: cover;
}

.review-slider {
  position: relative;
}

.review-slider .swiper-slide img {
  max-width: 100%;
  width: 100%;
}

.review-slider #review-nav-prev, .review-slider #review-nav-next {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 100;
  height: 30px;
  width: 30px;
  line-height: 30px;
  color: #fff;
  background: rgba(0, 0, 0, 0.58);
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}

#review-nav-prev {
  left: 15px;
}

#review-nav-next {
  right: 15px;
}

.revied-gread {
  background: #0b0c10;
  text-align: center;
  padding: 40px 10px;
}

.revied-gread .gread {
  height: 100px;
  width: 100px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  border: 7px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  margin-bottom: 20px;
}

.revied-gread .gread h3 {
  font-size: 50px;
  margin: 0;
  font-weight: 700;
  color: #f68b1f;
  line-height: 35px;
  margin-top: 5px;
}

.revied-gread .review {
  margin-bottom: 50px;
}

.revied-gread .review li {
  font-size: 20px;
  color: #f68b1f;
}

.revied-gread .review-text {
  color: #fff;
  font-size: 16px;
  line-height: 25px;
  color: #f68b1f;
}

.revied-gread .review-text i {
  color: #fff;
}

.revied-gread .review-text .fa-quote-left {
  margin-right: 5px;
}

.revied-gread .review-text .fa-quote-right {
  margin-left: 5px;
}

.revied-gread .avatar {
  height: 55px;
  width: 55px;
  border: 5px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  text-align: center;
  margin: 0 auto;
}

.revied-gread .avatar img {
  border-radius: 50%;
  width: 100%;
}

.game-details-wrap {
  display: -ms-flexbox;
  display: flex;
}

.game-tips-inner hr {
  margin: 30px 0 50px;
  border-bottom: 1px solid #252525;
}

.game-tips-inner .tips-bg {
  background: rgba(0, 0, 0, 0.5);
  padding: 50px 40px;
  border-radius: 10px;
}

.game-details {
  margin-top: 20px;
  width: 50%;
  float: left;
}

.game-details h4 {
  color: #fff;
  font-size: 20px;
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.game-details .gfeature {
  margin: 0;
  padding: 0;
  list-style: none;
  float: left;
}

.game-details .gfeature li {
  color: #fff;
  font-size: 16px;
  line-height: 25px;
  position: relative;
  margin-left: 20px;
  font-weight: 400;
}

.game-details .gfeature li:before {
  position: absolute;
  content: "\f05d";
  font: normal normal normal 14px/1 FontAwesome;
  left: -18px;
  top: 4px;
}

/* Game Tips */
.game-tips h3 {
  font-size: 30px;
  line-height: 25px;
  font-weight: 500;
  color: #dedede;
  margin-bottom: 30px;
}

.game-tips p {
  color: #bfbfbf;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
}

.game-tips p:first-child {
  margin-bottom: 30px;
}

.game-tips ul.tips-item {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 30px;
}

.game-tips ul.tips-item li {
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  margin-left: 25px;
  color: #bfbfbf;
  position: relative;
}

.game-tips ul.tips-item li:after {
  position: absolute;
  content: "\f05d";
  font: normal normal normal 14px/1 FontAwesome;
  left: -20px;
  top: 5px;
  color: #fd0e0e;
}

.game-tips .game-large-player .youtube-wrapper {
  padding-bottom: 56.3%;
}

.game-tips .game-large-player .youtube-wrapper .youtube-poster {
  height: 411px;
}

.widgets-titleb {
  font-size: 30px;
  font-weight: 500;
  color: #dedede;
  margin: 0 0 20px;
}

.widgets-title {
  font-size: 25px;
  color: #dedede;
  margin-top: 0;
  margin-bottom: 20px;
}

.feature-video-sm {
  position: relative;
  background: #252525;
  padding: 10px;
  border-radius: 10px;
}

.feature-video-sm img {
  border-radius: 10px;
  width: 100%;
}

.feature-video-sm .popup-btn-three {
  height: 50px;
  width: 50px;
  line-height: 52px;
  border-radius: 50%;
  background: #f68b1f;
  position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 20px;
  outline: 0;
}

.feature-video-sm .popup-btn-three i {
  color: #dedede;
  margin-left: 3px;
}

.button-wrap .popup-btn-two {
  padding: 14px 40px;
  text-transform: uppercase;
  font-size: 20px;
  background: #00d2ff;
  line-height: 20px;
}

.button-wrap .popup-btn-two i {
  margin-right: 8px;
}

.button-wrap .popup-btn-two:hover, .button-wrap .popup-btn-two:focus {
  background: #487b00;
}

.content-with-image {
  margin-bottom: 50px;
}

.content-with-image img {
  float: left;
  max-width: 340px;
  height: auto;
}

.content-with-image .content {
  margin-left: 360px;
}

.content-with-image .content p {
  color: #bfbfbf;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
}

@media (min-width: 769px) and (max-width: 1200px) {
  .game-details .gfeature {
    margin: 0 10px 0 0;
  }
  .game-details .gfeature li {
    font-size: 14px;
  }
  .game-blog-post {
    margin-top: 20px;
  }
  .revied-gread .review-text {
    font-size: 14px;
  }
  .revied-gread .review {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .review-inner .pr__15 {
    padding-right: 0 !important;
  }
  .game-details {
    margin-bottom: 20px;
  }
  .content-with-image img {
    float: none;
    margin-bottom: 10px;
  }
  .content-with-image .content {
    margin-left: 0;
  }
  .review-inner {
    margin-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .review-inner {
    margin-bottom: 30px;
    background-position: center;
    background-size: cover;
  }
  .game-details-wrap {
    display: block;
  }
  .game-details-wrap .gfeature {
    width: 100%;
    margin-bottom: 20px;
  }
  .game-details {
    width: 100%;
    margin-bottom: 0;
  }
  .game-details .gfeature li {
    font-size: 18px;
    line-height: 30px;
  }
}

/*--------------------------------------------------------------
  ##  Questions
  --------------------------------------------------------------*/
#faq {
  padding: 100px 0;
  background-size: cover;
  background-position: center center;
  background: #0d0f17;
}

#faq .section-title {
  margin-bottom: 0;
}

#faq .section-title h2 {
  color: #dedede;
  margin-bottom: 50px;
  text-align: left;
  font-size: 40px;
}

#faq .tim-btn {
  margin-top: 20px;
  font-size: 20px;
  padding: 15px 40px;
}

.card-body {
  padding: 15px 15px;
  background: rgba(50, 50, 50, 0.7);
  color: white;
}

.card {
  background: transparent;
  border: 0;
  border-radius: 0;
}

.card .card-header {
  padding: 0;
  border-top: 1px solid #6b7077 !important;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  background: transparent;
  border-bottom: 0;
}

.card .card-header h5 {
  position: relative;
}

.card .card-header h5 button {
  color: #fff;
  font-size: 20px;
  transition: all 150ms ease-in-out;
  display: block;
  width: 100%;
  border: 0;
  border-radius: 0;
  background: rgba(50, 50, 50, 0.7);
  text-align: left;
  padding: 10px 20px 10px 40px;
  text-decoration: none;
}

.card .card-header:hover {
  border-top-color: #65892f !important;
  background: #65892f;
}

.card.card-active .card-header {
  border-top-color: #65892f !important;
}

#accordion .card.card-active button,
#accordion .card.card-header button:hover,
#accordion .card.card-header button:focus {
  border-top: 1px solid #65892f;
  color: #fff;
  position: relative;
  text-decoration: none;
  background: #65892f;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-header > h5:before {
  content: "\f068";
  display: block;
  position: absolute;
  top: 50%;
  left: 20px;
  z-index: 9;
  font: var(--fa-font-solid);
  transition: all 0.5s;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 12px;
  color: #FFF;
}

.collapsed:before {
  content: "\f067";
  display: block;
  position: absolute;
  top: 50%;
  left: 20px;
  z-index: 9;
  transition: all 0.5s;
  font: var(--fa-font-solid);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 12px;
}

@media (max-width: 768px) {
  #faq {
    padding: 50px 0;
  }
  .faq .panel-group {
    margin-bottom: 20px;
  }
  #faq .section-title h2 {
    font-size: 35px;
    margin-bottom: 30px;
  }
  #faq .tim-btn.tim-btn-bg {
    margin-bottom: 20px;
  }
}

@media (max-width: 500px) {
  #faq .section-title h2 {
    font-size: 25px;
    margin-bottom: 10px;
  }
  .panel-group .panel-title a {
    font-size: 16px;
  }
}

/*--------------------------------------------------------------
  ##  Call To Action
  --------------------------------------------------------------*/
.col-to-action-game {
  padding: 40px 100px;
  background: rgba(0, 0, 0, 0.502);
}

.col-to-action-game .title-inner {
  width: 85%;
  float: left;
}

.col-to-action-game .title-inner h2 {
  font-size: 40px;
  line-height: 40px;
  font-weight: 700;
  color: #fff;
}

.col-to-action-game .title-inner h3 {
  font-size: 30px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0;
}

.col-to-action-game .button-inner {
  width: 15%;
  padding: 19px 0;
  float: right;
}

.col-to-action-game .button-inner .tim-btn-bg {
  padding: 14px 32px;
  text-transform: capitalize;
}

.col-to-action-game .button-inner .tim-btn-bg:hover {
  background: #487b00;
}

@media (max-width: 992px) {
  .col-to-action-game {
    padding: 40px 50px;
  }
  .col-to-action-game .title-inner {
    width: 80%;
  }
  .col-to-action-game .button-inner {
    width: 20%;
  }
}

@media (max-width: 768px) {
  .col-to-action-game {
    padding: 40px 50px;
    text-align: center;
  }
  .col-to-action-game .title-inner {
    width: 100%;
  }
  .col-to-action-game .button-inner {
    width: 100%;
    text-align: center;
    padding-bottom: 0;
  }
}

@media (max-width: 768px) {
  .col-to-action-game {
    padding: 30px;
  }
  .col-to-action-game .title-inner h2 {
    font-size: 30px;
  }
  .col-to-action-game .title-inner h3 {
    font-size: 25px;
  }
}

/*--------------------------------------------------------------
  ##  Author List
  --------------------------------------------------------------*/
#author-list {
  padding: 50px 0;
  background: #131313;
}

.top-team a img {
  max-width: 100%;
}

/*--------------------------------------------------------------
  ##  Game Table
  --------------------------------------------------------------*/
#game-tables {
  padding-bottom: 100px;
  background: #131313;
}

.tables-inner {
  background: #050404;
  padding: 50px 40px;
}

.tables-inner thead {
  background: #1b1717;
}

.tables-inner .table {
  border-collapse: separate;
  border-spacing: 0 8px;
}

.tables-inner .table thead tr th {
  border: 0;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  padding: 16px 10px;
}

.tables-inner .table thead tr th:first-child {
  padding-left: 30px;
}

.tables-inner .table td, .tables-inner .table th {
  border: 0;
}

.tables-inner tbody tr {
  background-color: #0e0d0d;
}

.tables-inner tbody tr th {
  padding: 25px 0 25px 30px;
}

.tables-inner tbody tr th .game-type {
  color: #fff;
  font-size: 20px;
  margin: 0;
}

.tables-inner tbody tr th .game-type i {
  margin-right: 10px;
}

.tables-inner tbody tr th span {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  margin-left: 30px;
}

.tables-inner tbody tr td {
  font-size: 18px;
  color: #fff;
  display: table-cell;
  padding: 20px;
}

.game-search {
  display: -ms-flexbox;
  display: flex;
}

.game-search input[type="text"] {
  background: #1b1717;
  border: 0;
  color: #fff;
  height: 40px;
  outline: 0;
  padding: 10px;
  max-width: 230px;
  margin-right: 10px;
  width: 100%;
}

.game-search input[type="text"]::-webkit-input-placeholder {
  color: #fff;
}

.game-search input[type="text"]:-ms-input-placeholder {
  color: #fff;
}

.game-search input[type="text"]::placeholder {
  color: #fff;
}

.game-search .tim-btn-bg {
  background: #f68b1f;
  border: 0;
  color: #fff;
  height: 40px;
  padding: 10px 30px;
  outline: 0;
  color: #fff;
  border-radius: 0;
  text-transform: capitalize;
  cursor: pointer;
}

.login-form input {
  width: 100%;
  background: #1b1717;
  height: 40px;
  padding: 10px 15px;
  border: 0;
  margin-bottom: 10px;
  outline: 0;
  color: #fff;
}

.login-form input.check {
  width: auto;
  height: auto;
  background-color: transparent;
}

.login-form input.check span {
  margin-left: 10px;
}

.login-form > p {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 5px;
}

.login-form > p a {
  color: #f68b1f;
}

.login-form .submit-btn {
  padding: 12px 33px;
  color: #fff;
  text-transform: uppercase;
  background: #f68b1f;
  display: inline-block;
  border: 0;
  font-size: 20px;
  line-height: 17px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 10px;
}

.login-form .submit-btn:hover {
  background: #487b00;
}

.squaredThree {
  position: relative;
  display: inline-block;
}

.squaredThree input[type=checkbox] {
  height: auto;
  width: auto;
  visibility: hidden;
}

.squaredThree label {
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  top: 6px;
  left: 0;
  background: #1b1717;
}

.squaredThree span {
  margin-left: 10px;
  color: #fff;
}

.squaredThree input[type=checkbox]:checked + label:after {
  opacity: 1;
}

.squaredThree label:hover::after {
  opacity: 0.3;
}

.squaredThree label:after {
  content: '';
  width: 9px;
  height: 5px;
  position: absolute;
  top: 4px;
  left: 4px;
  border: 2px solid #fcfff4;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.user-widget {
  margin-top: 20px;
}

.user-widget .topics-title {
  color: #fff;
  font-size: 25px;
  margin-top: 0;
  font-weight: 500;
}

.user-widget .item {
  margin-bottom: 10px;
}

.user-widget .item p {
  margin-bottom: 0;
  color: #b5b5b5;
  line-height: 16px;
  position: relative;
  overflow: hidden;
  text-decoration: underline;
}

.user-widget .item p a {
  color: #f68b1f;
}

.user-widget .item span {
  color: #888;
}

.user-widget .item a {
  color: #f68b1f;
}

@media (max-width: 991px) {
  .tables-inner {
    padding: 35px 25px;
  }
  .tables-inner tbody tr th {
    padding: 20px 0 20px 20px;
  }
  .tables-inner .table thead tr th:first-child {
    padding-left: 20px;
  }
}

@media (max-width: 500px) {
  .game-search input[type="text"] {
    margin-right: -3px;
  }
  .tables-inner tbody tr th .game-type {
    font-size: 16px;
  }
  .tables-inner tbody tr th span {
    margin-left: 0;
    display: block;
    line-height: 20px;
  }
  .tables-inner .table thead tr th {
    font-size: 15px;
  }
  .tables-inner {
    padding: 30px 20px;
  }
  .tables-inner tbody tr th {
    padding: 15px 0 25px 15px;
  }
  .tables-inner tbody tr td {
    text-align: center;
  }
}

/*--------------------------------------------------------------
  ##  Tips And Tricks
  --------------------------------------------------------------*/
#tips {
  padding: 100px 0;
}

.mini-title h3 {
  font-size: 25px;
  font-weight: 600;
  line-height: 35px;
  color: #dedede;
  margin-bottom: 15px;
}

.mini-title h3 span {
  color: #f68b1f;
}

.tips-content .popup-btn {
  visibility: visible !important;
  opacity: 1;
  padding: 10px 11px;
  font-weight: 600;
  border-radius: 4px;
  font-size: 16px;
}

.tips-content .popup-btn i {
  background: transparent;
  color: #fff;
  font-size: 20px;
  margin-right: 2px;
}

.tips-content p {
  font-size: 18px;
  line-height: 28px;
}

.game-video-item.game-box-video {
  background-repeat: no-repeat;
}

.game-video-item.game-box-video .feature-image {
  padding: 50px 80px 50px 50px;
}

.game-video-item.game-box-video .youtube-wrapper {
  padding-bottom: 59.7%;
  border-radius: 10px;
}

.game-video-item.game-box-video .youtube-wrapper:before {
  content: '';
  background-image: url("../media/video/play.png");
  height: 80px;
  width: 80px;
}

.game-video-item.game-box-video .youtube-wrapper .youtube-poster {
  height: 245px;
}

@media (max-width: 1199px) {
  .mini-title h3 {
    font-size: 22px;
  }
  .tips-content p {
    font-size: 16px;
  }
  .game-video-item.game-box-video {
    background-size: cover;
  }
  .game-video-item.game-box-video .feature-image {
    padding: 28px 42px 93px 56px;
  }
}

@media (max-width: 768px) {
  #tips {
    padding: 50px 0;
  }
  .game-video-item.game-box-video {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .game-video-item.game-box-video .feature-image {
    padding: 28px 5% 93px 13%;
  }
}

/*--------------------------------------------------------------
  ##  Moderator
  --------------------------------------------------------------*/
#moderator {
  background-size: cover;
  background: #13131f;
}

#moderator, #moderator-two {
  padding: 100px 0;
  position: relative;
}

.moderator-member {
  text-align: center;
}

.member-details {
  text-align: center;
}

.member-details .name {
  color: #fff;
  font-size: 20px;
  line-height: 25px;
  color: #fff;
  margin-bottom: 0;
}

.member-thumb {
  width: 138px;
  height: 176px;
  margin: 0 auto 20px;
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
}

.member-thumb img {
  margin-top: 39px;
  margin-right: 8px;
  border-radius: 50%;
}

.member-social-link {
  margin-top: -5px;
  padding: 0;
  list-style: none;
}

.member-social-link li {
  display: inline-block;
  border-radius: 3px;
  margin-right: 5px;
  height: 35px;
  width: 35px;
  line-height: 34px;
  transition: all 0.3s ease-in-out;
  border: 1px solid #fff;
  border-radius: 50%;
}

.member-social-link li a {
  color: #fff;
  font-size: 16px;
  color: #fff;
  display: block;
}

.member-social-link li:hover {
  border-color: #f68b1f;
}

.member-social-link li:hover a {
  color: #f68b1f;
}

.bg-thumb {
  position: absolute;
  left: -55px;
  bottom: 0;
}

@media (max-width: 991px) {
  .moderator-member {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  #moderator {
    padding: 50px 0;
  }
}

/*--------------------------------------------------------------
  ##  Tournaments
  --------------------------------------------------------------*/
#tournaments {
  padding: 100px 0;
  background: #13131f;
}

.trurnament-inner {
  background: #1a1c20;
  border-top: 5px solid #f68b1f;
  padding-top: 20px;
}

.trurnament-inner .torurmant {
  margin: 0 auto;
  padding: 20px 15px;
  transition: all 0.3s ease-in-out;
  border-bottom: 1px solid #303030;
}

.trurnament-inner .torurmant .game-item {
  display: inline-block;
  width: 33.33%;
  float: left;
}

.trurnament-inner .torurmant .game-item img {
  height: 70px;
  width: 70px;
  border-radius: 5px;
}

.trurnament-inner .torurmant .game-item h4 {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 0;
}

.trurnament-inner .torurmant .game-item h4 a {
  color: #fff;
}

.trurnament-inner .torurmant .game-item h4 a:hover {
  color: #f68b1f;
}

.trurnament-inner .torurmant .game-item.vs {
  text-align: center;
}

.trurnament-inner .torurmant .game-item.vs h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  height: 45px;
  width: 45px;
  background: #f68b1f;
  border-radius: 50%;
  line-height: 47px;
  margin: 20px auto;
  position: relative;
  z-index: 2;
}

.trurnament-inner .torurmant .game-item.vs h4:before, .trurnament-inner .torurmant .game-item.vs h4:after {
  position: absolute;
  content: '';
  border-radius: 50%;
  z-index: -1;
}

.trurnament-inner .torurmant .game-item.vs h4:before {
  left: -5px;
  top: -5px;
  bottom: -5px;
  right: -5px;
  border: 5px solid rgba(255, 255, 255, 0.2);
}

.trurnament-inner .torurmant .game-item.vs h4:after {
  left: -10px;
  top: -10px;
  bottom: -10px;
  right: -10px;
  border: 5px solid rgba(255, 255, 255, 0.1);
}

.trurnament-inner .tournaments-details {
  padding: 20px 25px;
}

.trurnament-inner .tournaments-details .date {
  width: 33.33%;
  float: left;
  display: inline-block;
  text-align: center;
}

.trurnament-inner .tournaments-details .date h4 {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.trurnament-inner .tournaments-details .details {
  display: inline-block;
  float: left;
}

.trurnament-inner .tournaments-details .details .review {
  margin-bottom: 10px;
}

.trurnament-inner .tournaments-details .details .review li {
  color: #f68b1f;
  font-size: 14px;
}

.trurnament-inner .tournaments-details .details a {
  color: #fff;
  font-size: 16px;
}

.trurnament-inner .tournaments-details .details a.tim-btn {
  padding: 10px 12px;
  line-height: 15px;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 4px;
}

.trurnament-inner .tournaments-details .details .meta {
  color: #fff;
}

.trurnament-inner .tournaments-details .details .meta:hover {
  color: #f68b1f;
}

.trurnament-inner:hover .torurmant {
  background-image: none;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .trurnament-inner .torurmant .game-item img {
    height: 55px;
    width: 55px;
  }
  .trurnament-inner .torurmant .game-item h4 {
    font-size: 14px;
    font-weight: 600;
  }
  .trurnament-inner .torurmant .game-item.vs h4 {
    margin: 15px auto;
  }
}

@media (max-width: 991px) {
  .trurnament-inner {
    margin-bottom: 20px;
    max-width: 350px;
    margin: 0 auto 20px;
  }
}

/*--------------------------------------------------------------
  ##  Tournaments Count
  --------------------------------------------------------------*/
#torurmant-count, #torurmant-count-two, #torurmant-count-three {
  background: #13131f;
  margin-top: -1px;
}

.torurmant {
  padding: 60px 50px;
  text-align: center;
  position: relative;
  background: #21212d;
  bckground: #1a1c20;
}

.torurmant .link-btn {
  position: absolute;
  top: 25px;
  right: 30px;
  color: #f68b1f;
  font-weight: 700;
  font-size: 16px;
}

.torurmant-title {
  background-image: radial-gradient(ellipse farthest-corner at center 0px, #11383a -300%, #262635 45%, #27273a 85%);
  margin-bottom: 20px;
}

.torurmant-title h3 {
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  margin: 0;
  background: url(../media/background/stripe.png) top left;
  padding: 20px;
}

.torurmant-game {
  width: 33.33%;
  float: left;
}

.torurmant-game img {
  height: 120px;
  width: 120px;
  border-radius: 50%;
}

.torurmant-game h3 {
  font-size: 25px;
  color: #f68b1f;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 0;
}

.torurmant-game .tim-btn {
  border: 0;
  border-radius: 0;
  font-size: 20px;
  font-weight: 700;
  padding: 17px 55px;
  border: 1px solid #fff;
}

.torurmant-game .tim-btn:hover {
  border-color: #f68b1f;
}

.tags {
  position: absolute;
  right: 30px;
  bottom: 20px;
}

.tags a {
  background: #131313;
  font-size: 14px;
  color: #fff;
  padding: 0 15px;
  display: inline-block;
  margin-right: 3px;
  line-height: 25px;
}

.tags a:hover {
  color: #f68b1f;
}

.countdown {
  margin-bottom: 50px;
}

.countdown .CountdownContent {
  height: 50px;
  width: 46px;
  background: #262636;
  color: #f68b1f;
  font-size: 25px;
  display: inline-block;
  text-align: center;
  margin-right: 2px;
  font-weight: 600;
  line-height: 55px;
}

.countdown .CountdownContent .CountdownSeparator,
.countdown .CountdownContent .CountdownLabel {
  display: none;
}

.vs-fire {
  height: 50px;
  width: 50px;
  font-size: 25px;
  line-height: 55px;
  font-weight: 600;
  color: #fff;
  background: #f68b1f;
  margin: 0 auto 50px;
  border-radius: 50%;
  position: relative;
}

.vs-fire canvas {
  position: absolute;
  left: 0;
  border-radius: 50%;
}

.vs-fire:before, .vs-fire:after {
  position: absolute;
  content: '';
  border: 1px solid #f68b1f;
  border-radius: 50%;
}

.vs-fire:before {
  top: -10px;
  bottom: -10px;
  right: -10px;
  left: -10px;
  animation: opacity 1000ms infinite ease-in-out;
  animation-delay: 800ms;
}

.vs-fire:after {
  top: -20px;
  bottom: -20px;
  right: -20px;
  left: -20px;
  animation: opacity 1000ms infinite ease-in-out;
}

@keyframes opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .2;
  }
  100% {
    opacity: 1;
  }
}

.latest-game {
  padding: 40px;
  background: #21212d;
}

.latest-game h3 {
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 20px;
  color: #e9e9e9;
}

.latest-game h3 a {
  color: #f68b1f;
}

.latest-game h3 a:hover {
  color: #D82F2B;
}

.latest-game .tim-btm-bg {
  font-size: 20px;
  font-weight: 700;
  padding: 16px 67px;
  border: 0;
  border-radius: 0;
  color: #fff;
  margin: 40px  auto 10px;
  border: 1px solid #fff;
}

.latest-game .tim-btm-bg:hover {
  background: #f68b1f;
  border-color: #f68b1f;
}

.latest-game .game-item {
  padding: 0 5px;
}

.latest-game img {
  width: 100%;
  -ms-transform: skew(-5deg);
  transform: skew(-5deg);
}

.latest-game .game-name {
  display: inline-block;
}

.latest-game h4 {
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 15px;
}

.latest-game h4 a {
  color: #fff;
}

.latest-game h4 a:hover {
  color: #f68b1f;
}

.latest-game .review li {
  font-size: 14px;
  color: #f68b1f;
}

.latest-game .ratting-point {
  float: right;
  height: 30px;
  width: 30px;
  background: #f68b1f;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  margin-top: 15px;
  margin-right: 20px;
}

.latest-game .ratting-point p {
  font-weight: 600;
  color: #fff;
  margin: 0;
  font-size: 14px;
}

.game-tabs {
  background: #21212d;
}

.tim-tab-nav-game {
  border-bottom: 1px solid #313131;
}

.tim-tab-nav-game li {
  text-align: center;
  border-right: 1px solid #313131;
}

.tim-tab-nav-game li:last-child {
  border-right: 0;
}

.tim-tab-nav-game li a {
  text-transform: uppercase;
  color: #e9e9e9;
  font-size: 15px;
  line-height: 25px;
  font-weight: 600;
  padding: 15px 13px;
  display: block;
}

.tim-tab-nav-game li a.active {
  color: #f68b1f;
}

.letest-games {
  padding: 24px 20px;
  border-bottom: 1px solid #2d2d2d;
}

.letest-games:last-child {
  border-bottom: 0;
}

.letest-games a {
  display: inline-block;
  height: 60px;
  width: 60px;
}

.letest-games a img {
  width: 100%;
  border-radius: 50%;
}

.letest-games .game-time {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.letest-games .game-time .time {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
}

.letest-games .game-time .date {
  color: #fff;
  font-size: 16px;
  margin: 0;
}

.letest-games span {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin: 0 15px;
  display: inline-block;
}

@media (max-width: 992px) {
  .game-wraper-tab {
    margin-top: 20px;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .tim-tab-nav-game li a {
    font-size: 13px;
    padding: 9px;
  }
  .letest-games {
    padding: 15px;
    text-align: center;
  }
  .letest-games span {
    margin: 0 10px;
  }
  .letest-games span.eql {
    display: none;
  }
  .letest-games .game-time {
    margin-top: 20px;
  }
  .letest-games .game-time p {
    display: inline-block;
  }
  .countdown .CountdownContent {
    width: 40px;
    height: 40px;
    line-height: 43px;
    font-size: 20px;
  }
  .torurmant-game .tim-btn-bg {
    padding: 17px 35px;
  }
  .torurmant {
    padding: 60px 30px;
  }
}

@media (max-width: 991px) {
  .torurmant {
    padding: 50px 25px;
  }
  .latest-game {
    background-size: cover;
    background-position: center center;
  }
  .latest-game .game-item {
    max-width: 300px;
    margin: 0 auto 30px;
  }
  .game-tabs {
    max-width: 450px;
    margin: 0 auto;
  }
  .tim-tab-nav-game li a {
    padding: 20px 23px;
  }
}

@media (max-width: 768px) {
  .torurmant-game {
    width: 25%;
  }
  .torurmant-game:nth-child(3) {
    width: 50%;
  }
  .torurmant-game img {
    width: 90px;
    height: 90px;
  }
  .countdown {
    margin-bottom: 30px;
  }
  .countdown .CountdownContent {
    height: 40px;
    width: 40px;
    line-height: 44px;
    font-size: 20px;
  }
  .torurmant-game h3 {
    font-size: 20px;
  }
  .torurmant-game .tim-btn-bg {
    font-size: 16px;
    padding: 13px 37px;
  }
  .letest-games span {
    margin: 0 30px;
  }
}

@media (max-width: 500px) {
  .torurmant-game {
    width: 100%;
  }
  .torurmant-game:nth-child(3) {
    width: 100%;
    margin: 30px 0 20px;
  }
  .letest-games {
    text-align: center;
  }
  .tim-tab-nav-game {
    text-align: center;
  }
  .tim-tab-nav-game li {
    width: 28%;
  }
  .tim-tab-nav-game li:first-child {
    width: 15%;
  }
  .tim-tab-nav-game li a {
    padding: 10px 5px;
    font-size: 16px;
  }
  .letest-games span {
    margin: 0 20px;
  }
  .letest-games {
    padding: 22px 10px;
  }
}

/*--------------------------------------------------------------
  ##  Top Games
  --------------------------------------------------------------*/
.top-game-tabs .torurmant-title {
  padding: 0;
}

.tim-tab-nav-game-top {
  background: url(../media/background/stripe.png) top left;
  padding-left: 20px;
}

.tim-tab-nav-game-top li {
  padding: 0 15px;
}

.tim-tab-nav-game-top li a {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 16px 0;
  position: relative;
}

.tim-tab-nav-game-top li a:after {
  content: '';
  position: absolute;
  width: 0;
  height: 3px;
  background: #f68b1f;
  transition: all 0.3s ease-in-out;
  bottom: 0;
  left: 0;
}

.tim-tab-nav-game-top li a:hover, .tim-tab-nav-game-top li a.active {
  color: #f68b1f;
}

.tim-tab-nav-game-top li a:hover:after, .tim-tab-nav-game-top li a.active:after {
  width: 100%;
}

.games-bg {
  background: #0d0707;
}

.games-bg .games-title {
  background: #21212d;
  padding: 15px 20px;
}

.games-bg .games-title h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
  margin-bottom: 0;
}

.games-bg .top-games-item {
  background: #1a1a25;
  padding: 15px 20px;
  border-radius: 5px;
  margin-bottom: 21px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.8);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
}

.games-bg .top-games-item:last-child {
  margin-bottom: 0;
}

.games-bg .top-games-item .game-user {
  float: left;
  width: 33%;
}

.games-bg .top-games-item .game-user .uthumb {
  float: left;
  border-radius: 10px;
  overflow: hidden;
}

.games-bg .top-games-item .game-user .content {
  margin-left: 80px;
}

.games-bg .top-games-item .game-user .content h3 {
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  margin-top: 2px;
}

.games-bg .top-games-item .game-user .content h3 a {
  color: #fff;
}

.games-bg .top-games-item .game-user .content h3 a:hover {
  color: #fd0e0e;
}

.games-bg .top-games-item .game-user .content p {
  margin-bottom: 0;
  line-height: 16px;
}

.games-bg .top-games-item .win {
  width: 60px;
  float: left;
  text-align: center;
  border-left: 1px solid #332b2b;
  border-right: 1px solid #332b2b;
  width: 33%;
}

.games-bg .top-games-item .win h3 {
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
}

.games-bg .top-games-item .win h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  line-height: 16px;
  margin-bottom: 0;
}

.games-bg .top-games-item .match {
  text-align: center;
  width: 33%;
}

.games-bg .top-games-item .match h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}

.games-bg .top-games-item .match h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  line-height: 16px;
  margin-bottom: 0;
}

.games-bg .top-games-item:hover {
  background-image: radial-gradient(ellipse farthest-corner at center 0px, #11383a -300%, #262635 45%, #27273a 85%);
}

.games-bg .games-inner {
  padding: 20px 15px;
  background: #0f0f19;
}

.feature-game {
  position: relative;
}

.feature-game .blog-content {
  position: absolute;
  bottom: 0;
  padding: 20px;
  width: 100%;
}

.feature-game .blog-content .blog-title {
  margin-bottom: 0;
}

.feature-game .blog-content .blog-title a:hover {
  color: #f68b1f;
}

.feature-game .blog-content p {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  line-height: 25px;
  margin-bottom: 0;
}

.game-clan-video {
  position: relative;
}

.game-clan-video img {
  max-width: 100%;
}

.game-clan-video .youtube-wrapper {
  padding-bottom: 35%;
}

.game-clan-video .youtube-wrapper:before {
  content: "\f04b";
  font-size: 30px;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  line-height: 48px;
  text-align: center;
  padding-left: 7px;
  margin: 0;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .games-bg .top-games-item {
    padding: 15px;
  }
  .games-bg .top-games-item .game-user {
    width: 130px;
  }
  .games-bg .top-games-item .game-user .content h3 a {
    font-size: 14px;
  }
  .games-bg .top-games-item .game-user .content p {
    font-size: 12px;
  }
  .games-bg .top-games-item .win {
    width: 45px;
  }
  .games-bg .top-games-item .win h3 {
    font-size: 14px;
  }
  .games-bg .top-games-item .match h3 {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .games-bg {
    margin-bottom: 20px;
  }
  .feature-game {
    max-width: 370px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .games-bg .top-games-item .game-user {
    width: 40%;
  }
  .games-bg .top-games-item .win {
    width: 28%;
  }
  .games-bg .top-games-item .match {
    width: 20%;
  }
}

@media (max-width: 500px) {
  .tim-tab-nav-game-top li {
    padding: 0 8px;
  }
  .tim-tab-nav-game-top li a {
    font-size: 15px;
  }
  .games-bg .top-games-item .game-user {
    width: 60%;
  }
  .games-bg .top-games-item .game-user .content h3 {
    font-size: 15px;
  }
  .games-bg .top-games-item .win {
    width: 20%;
  }
  .games-bg .top-games-item .match {
    width: 20%;
  }
  .games-bg .top-games-item .match h3 {
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  .games-bg .games-inner {
    padding: 15px 10px;
  }
  .games-bg .top-games-item {
    padding: 10px;
  }
  .games-bg .top-games-item .game-user {
    width: 140px;
  }
  .games-bg .top-games-item .win {
    width: 50px;
  }
}

/*--------------------------------------------------------------
  ##  Screenshort
  --------------------------------------------------------------*/
#screenshot {
  padding-top: 100px;
  background: #181827;
}

.sc-title {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 20px;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.game-video-item .video-play-btn.bdr {
  display: inline-block;
  height: 60px;
  width: 60px;
  border: 4px solid;
  border-radius: 50%;
  text-align: center;
}

@media (max-width: 768px) {
  .torurmant {
    background-size: cover;
  }
  .countdown .CountdownContent {
    margin-bottom: 2px;
  }
  #screenshot .feature-image img {
    width: 100%;
  }
}

/*--------------------------------------------------------------
  ##  404 Page
  --------------------------------------------------------------*/
#error-page {
  height: 100vh;
  text-align: center;
  position: relative;
  padding-top: 150px;
}

#error-page .error-content {
  position: absolute;
  top: 56%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 100px 50px;
  width: 60%;
  z-index: 1;
}

#error-page .error-content:before {
  content: '';
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  border: 5px solid rgba(0, 0, 0, 0.5);
  z-index: -1;
}

#error-page .error-content img {
  max-width: 300px;
  margin-bottom: 40px;
}

#error-page .error-content h1 {
  font-size: 80px;
  color: #fff;
  line-height: 80px;
  margin-bottom: 30px;
}

#error-page .error-content h3 {
  font-size: 50px;
  color: #fff;
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  #error-page .error-content {
    top: 50%;
  }
  #error-page .error-content h1 {
    font-size: 50px;
    margin-bottom: 20px;
  }
  #error-page .error-content img {
    max-width: 200px;
    margin-bottom: 20px;
  }
  #error-page .error-content h3 {
    font-size: 30px;
  }
}

@media (max-width: 500px) {
  #error-page .error-content {
    width: 70%;
    top: 50%;
  }
  #error-page .error-content h1 {
    font-size: 40px;
    margin-bottom: 0;
  }
  #error-page .error-content img {
    max-width: 150px;
    margin-bottom: 20px;
  }
  #error-page .error-content h3 {
    font-size: 30px;
    font-size: 20px;
  }
}

/*--------------------------------------------------------------
  ##  Woocommerce
  --------------------------------------------------------------*/
.shop-three-column-zoombie {
  background-attachment: fixed;
  background-size: cover;
}

.site-main .columns-4 ul.products li.product {
  margin-bottom: 20px;
}

.site-main .columns-4 ul.products li.product .price {
  display: inline-block;
  color: #ffffff;
  font-weight: 400;
  float: left;
  font-size: 20px;
  font-weight: 500;
  margin-left: 30px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.site-main .columns-4 ul.products li.product .add_to_cart_button {
  float: right;
  color: #adadad;
  font-weight: 600;
  font-size: 20px;
  margin-right: 30px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  border-color: #adadad;
}

.site-main .columns-4 ul.products li.product .add_to_cart_button:hover {
  color: #f68b1f;
}

.site-main .columns-4 ul.products li.product:hover .price, .site-main .columns-4 ul.products li.product:hover .add_to_cart_button {
  visibility: visible;
  opacity: 1;
}

.site-main {
  background: #131313;
}

.site-main .columns-3 ul.products li.product {
  margin-bottom: 10px;
}

.site-main .columns-3 ul.products li.product .product-thumb {
  height: auto;
}

.site-main .columns-3 ul.products li.product .product-thumb:after {
  top: 20px;
  left: 16px;
  right: 22px;
  bottom: 16px;
  background-size: cover;
  background-position: center center;
  height: auto;
  width: auto;
}

.site-main .columns-3 ul.products li.product .product-thumb img {
  margin: 0;
  width: 100%;
}

.site-main .columns-3 ul.products li.product .price {
  display: inline-block;
  color: #ffffff;
  font-weight: 400;
  float: left;
  font-size: 20px;
  font-weight: 500;
  margin-left: 30px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;
}

.site-main .columns-3 ul.products li.product .add_to_cart_button {
  float: right;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  margin-right: 30px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.site-main .columns-3 ul.products li.product .add_to_cart_button:hover {
  color: #f68b1f;
}

.site-main .columns-3 ul.products li.product:hover .price, .site-main .columns-3 ul.products li.product:hover .add_to_cart_button {
  visibility: visible;
  opacity: 1;
}

.product-thumb {
  position: relative;
  z-index: 1;
  height: 283px;
  padding: 20px;
  margin-bottom: 20px;
  background: #191919;
}

.product-thumb img {
  margin-bottom: 20px;
}

.woocommerce-loop-product__title {
  font-size: 20px !important;
  line-height: 25px;
  color: #adadad;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 600 !important;
}

.woocommerce-loop-product__title:hover {
  color: #f68b1f;
}

ul.products li.product .star-rating {
  margin: 0 auto 10px;
}

.star-rating span:before {
  color: #ffc107;
}

.woocommerce-ordering {
  width: 100%;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.woocommerce-result-count {
  font-size: 18px;
  color: #fff;
  margin-right: 20px;
}

select.orderby {
  padding: 4px 10px;
  width: 110px;
  color: #fff;
  background: transparent;
  margin: 0 auto;
  background-color: #2b2b2b;
  border: 1px solid transparent;
  font-size: 16px;
  outline: 0;
  width: 70px;
}

.short select.orderby {
  width: 180px;
}

.short p, .show-items p {
  color: #fff;
  font-size: 16px;
  color: #fff;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 0;
}

/* Product Two */
.products.product-two li.product .product-thumb {
  background: #1a1c20;
  height: auto;
  padding: 0;
  border-radius: 10px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.products.product-two li.product .product-thumb img {
  margin-bottom: 20px;
  width: 100%;
}

.products.product-two li.product .product-thumb:before, .products.product-two li.product .product-thumb:before {
  display: none;
}

.products.product-two li.product .product-thumb:after {
  display: none;
}

.products.product-two li.product .product-thumb:hover {
  background: #383838;
}

.products.product-two li.product .woocommerce-loop-product__title {
  margin-bottom: 0;
}

.products.product-two li.product .price, .products.product-two li.product .add_to_cart_button {
  visibility: visible !important;
  opacity: 1 !important;
}

.products.product-two li.product .add_to_cart_button:after {
  background: #fd0e0e;
}

.products.product-two li.product .add_to_cart_button:after:hover {
  color: #fff;
  border-color: #fd0e0e;
}

.products.product-two li.product .price {
  margin-left: 0 !important;
  margin-top: 10px;
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #f68b1f;
}

.products.product-two li.product .add_to_cart_button {
  border: 2px solid #fff;
  padding: 10px 37px;
  line-height: 16px;
  margin-right: 0 !important;
}

.products.product-two li.product .add_to_cart_button:before {
  background: #f68b1f;
}

#shop-post-two {
  padding: 100px 0;
  background: #0c0c0c;
}

#shop-post-two ul.products li.product .star-rating {
  font-size: 14px;
  color: #dedede;
  margin: 5px auto 20px;
}

#shop-post-two ul.products li.product .star-rating span:before {
  color: #dedede;
  font-size: 14px;
}

#shop-post-two ul.products .price {
  color: #f68b1f;
}

#shop-post-two ul.products .add_to_cart_button {
  font-size: 18px;
  border-radius: 4px;
}

#shop-post-two ul.products .add_to_cart_button:after {
  background: #f68b1f;
}

#shop-post-two ul.products .add_to_cart_button:hover {
  color: #fff;
  border-color: #f68b1f;
}

/* Product Single */
.product-slider .slick-slide {
  cursor: pointer;
}

.product-slider .slick-slide img {
  max-width: 100%;
}

.product-slider .slider-nav {
  margin-top: 10px;
}

.shop {
  padding: 100px 0;
}

.single-product {
  padding: 100px 0 60px;
  background: #13131f;
}

.single-product .site-main {
  background-color: transparent;
}

.single-product-details .product-title {
  font-size: 32px;
  line-height: 30px;
  margin-bottom: 20px;
}

.single-product-details .price {
  margin-bottom: 50px;
}

.single-product-details .price ins {
  margin-right: 10px;
  text-decoration: none;
}

.single-product-details .price ins, .single-product-details .price del {
  font-size: 26px;
  font-weight: 600;
}

.single-product-details .star-rating span:before {
  color: #f68b1f;
}

.single-product-details .review-link {
  color: #bdbdbd;
}

.single-product-details .woocommerce-product-details__short-description {
  margin-bottom: 30px;
}

.product-share-link {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}

.product-share-link li {
  display: inline-block;
  margin-right: 5px;
}

.product-share-link li a {
  display: block;
  height: 35px;
  width: 35px;
  border-radius: 4px;
  text-align: center;
  line-height: 35px;
  color: #fff;
  transition: all .3s ease-in-out;
}

.product-share-link li a.facebook-bg {
  background: #305fb3;
}

.product-share-link li a.twitter-bg {
  background: #5eaade;
}

.product-share-link li a.google-plus-bg {
  background: #d7482a;
}

.product-share-link li a.pinterest-bg {
  background: #bd081c;
}

.product-share-link li a:hover {
  background: #f68b1f;
}

.rating {
  margin-bottom: 20px;
}

.rating li {
  cursor: default;
  display: inline-block;
  font-size: 20px;
  list-style-type: none;
}

.star,
.rating:not(.vote-cast):hover .star:hover ~ .star,
.rating.vote-cast .star.selected ~ .star {
  color: #a5a5a5;
}

.rating:hover .star,
.rating.vote-cast .star {
  color: #f68b1f;
}

.share-wrap {
  margin-top: 20px;
}

.share-wrap h3 {
  font-size: 18px;
  display: inline-block;
  margin-right: 10px;
}

/* Quick View */
.quickview-wrapper {
  position: fixed;
  width: 800px;
  height: 560px;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  opacity: 0;
  -ms-transform: translate(-50%, -60%);
  transform: translate(-50%, -60%);
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.quickview-wrapper .quickview-content {
  background: #fff;
  padding: 15px;
  transition: all 0.5s ease-in-out;
}

.quickview-wrapper.open {
  visibility: visible;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 1;
}

.quickview-wrapper.open .quickview-content {
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.quickview-slider .slider-for .slick-slide, .quickview-slider .slider-nav .slick-slide, .quickview-slider .slider-for1 .slick-slide, .quickview-slider .slider-nav1 .slick-slide {
  outline: 0;
}

.quickview-slider .slider-for .slick-slide img, .quickview-slider .slider-nav .slick-slide img, .quickview-slider .slider-for1 .slick-slide img, .quickview-slider .slider-nav1 .slick-slide img {
  max-width: 100%;
}

.quickview-slider .slider-for, .quickview-slider .slider-for1 {
  margin-bottom: 15px;
}

.quickview-slider .slider-nav .slick-slide, .quickview-slider .slider-nav1 .slick-slide {
  cursor: pointer;
}

/* Product Single */
.product-tab-wrapper .product-tabs {
  margin: 50px 0 30px;
  list-style: none;
  text-align: center;
  border-top: 1px solid #2b2b2b;
  text-align: center;
  display: inherit;
}

.product-tab-wrapper .product-tabs li {
  display: inline-block;
}

.product-tab-wrapper .product-tabs li a {
  padding: 10px 20px;
  text-decoration: none;
  color: #444;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  border-top: 1px solid transparent;
  font-weight: 600;
}

.product-tab-wrapper .product-tabs li a:hover, .product-tab-wrapper .product-tabs li a.active {
  color: #f68b1f;
  border-color: #f68b1f;
}

.product-tab-wrapper .tab-content .tab-pane {
  padding: 0;
}

.comments-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.review-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.review-list .review-comment {
  margin-bottom: 20px;
}

.review-list .review-comment .user-avatar {
  height: 70px;
  width: 70px;
  float: left;
  border-radius: 50%;
  overflow: hidden;
}

.review-list .review-comment .user-avatar img {
  width: 100%;
  height: auto;
}

.review-list .review-comment .comment-content {
  margin-left: 90px;
}

.review-list .review-comment .comment-content .name {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.review-list .review-comment .comment-content .star-rating span:before {
  color: #f68b1f;
}

.review-list .review-comment .comment-content .comment-meta {
  margin-bottom: 10px;
}

.review-list .review-comment .comment-content .comment-meta .star-rating li {
  font-size: 12px;
  margin-right: 0;
}

.review-list .review-comment .comment-content .comment-meta .post-date {
  font-size: 12px;
  color: #a7a7a7;
}

.product-cart {
  margin: 40px 0 60px;
}

.product-cart .quantity {
  position: relative;
  background: #FFF;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.09);
  width: 90px;
  height: 50px;
  border: none;
  border-radius: 5px;
  float: left;
  padding: 11px 12px;
  margin-right: 10px;
}

.product-cart .quantity .minus, .product-cart .quantity .plus {
  cursor: pointer;
  color: #363636;
  font-size: 12px;
}

.product-cart .quantity .minus:focus, .product-cart .quantity .plus:focus {
  background: transparent;
  color: #363636;
}

.product-cart .quantity input {
  width: 51%;
  margin: 0 auto;
  border: none;
  padding: 0;
  text-align: center;
  font-size: 16px;
}

.product-cart .tim-cart-btn {
  background: #f68b1f;
  padding: 10px 17px;
  color: #FFF;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  font-weight: 500;
  border: 0;
  cursor: pointer;
}

.product-cart .tim-cart-btn i {
  margin-right: 5px;
}

.product-cart .tim-cart-btn:hover {
  background: #333;
  color: #FFF;
}

.rating-title {
  font-size: 22px;
  font-weight: 600;
}

.review-form textarea, .review-form input {
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
  width: 100%;
  padding: 10px;
}

.review-form textarea {
  height: 150px;
}

.review-form input {
  height: 50px;
  margin-top: 20px;
}

.review-form .gp-btn {
  max-width: 250px;
  margin: 30px 0  0 15px;
  display: inline-block;
  width: 100%;
  text-align: center;
  background: #333;
  color: #FFF;
  border-radius: 30px;
  padding: 12px 0px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  border: none;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
}

.review-form .gp-btn:hover {
  background: #f68b1f;
}

.shop_attributes {
  width: 100%;
}

.shop_attributes tbody {
  border: 1px solid #dcdcdc;
}

.shop_attributes tbody tr {
  height: 40px;
  border-bottom: 1px solid #dcdcdc;
}

.shop_attributes tbody tr th {
  width: 30%;
  border-right: 1px solid #dcdcdc;
  padding-left: 20px;
}

.shop_attributes tbody tr td {
  padding-left: 20px;
}

.shop_attributes tbody tr td p {
  margin-bottom: 0;
}

.related-product {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #272727;
}

.related-product .product-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #b3b3b3;
}

.related-product.site-main .columns-4 ul.products li.product {
  margin-right: 0;
  width: 25%;
  padding: 0 15px;
}

.related-product-slider .product {
  text-align: center;
}

.related-product-slider .product .product-thumb {
  padding: 0;
  height: auto;
}

.related-product-slider .product .product-thumb img {
  max-width: 100%;
  margin-bottom: 0;
}

.related-product-slider .product .star-rating {
  margin: 0 auto 15px;
}

.related-product-slider .product .price {
  display: inline-block;
  color: #ffffff;
  font-weight: 400;
  float: left;
  font-size: 20px;
  font-weight: 500;
  margin-left: 30px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.related-product-slider .product .add_to_cart_button {
  float: right;
  color: #adadad;
  font-weight: 600;
  font-size: 20px;
  margin-right: 30px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  border-color: #adadad;
}

.related-product-slider .product .add_to_cart_button:hover {
  color: #f68b1f;
}

.related-product-slider .product:hover .price, .related-product-slider .product:hover .add_to_cart_button {
  opacity: 1;
  visibility: visible;
}

.slick-initialized .slick-slide {
  outline: 0;
}

/* Color Checkbox */
.color-checkboxes {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.color-checkboxes h4 {
  margin-right: 10px;
  font-size: 18px;
  font-weight: 600;
}

.color-checkboxes #col-Blue-label {
  background: #2196f3;
}

.color-checkboxes #col-Green-label {
  background: #8bc34a;
}

.color-checkboxes #col-Yellow-label {
  background: #fdd835;
}

.color-checkboxes #col-Orange-label {
  background: #ff9800;
}

.color-checkboxes #col-Red-label {
  background: #f44336;
}

.color-checkboxes #col-Black-label {
  background: #222222;
}

.color-checkbox {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  margin-right: 7px;
  position: relative;
  border-radius: 16px;
  transition: transform 0.1s linear;
  cursor: pointer;
}

.color-checkbox.bordered {
  box-shadow: 0 0 0 2px #f0f0f0 inset;
}

.color-checkbox.bordered:after {
  border-color: black;
}

.color-checkbox:after {
  content: '';
  display: block;
  width: 6px;
  height: 10px;
  position: absolute;
  top: 3px;
  left: 7px;
  border: 2px solid white;
  border-width: 0 2px 2px 0;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.1s;
  text-align: center;
}

.color-checkbox:hover,
.color-checkbox__input:focus + .color-checkbox {
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.color-checkbox__input:checked + .color-checkbox:after {
  opacity: 1;
}

.color-checkbox__input {
  visibility: hidden;
  width: 0;
  pointer-events: none;
  position: absolute;
}

@media (max-width: 1200px) {
  .site-main .columns-4 ul.products li.product .product-thumb {
    height: auto;
  }
  .site-main .columns-4 ul.products li.product .product-thumb:after {
    top: 15px;
    left: 13px;
    right: 18px;
    bottom: 12px;
    background-size: cover;
    background-position: center center;
    height: auto;
    width: auto;
  }
  .site-main .columns-4 ul.products li.product .product-thumb img {
    margin: 0;
    width: 100%;
  }
  .site-main .columns-3 ul.products li.product .product-thumb:after {
    top: 15px;
    left: 14px;
    right: 17px;
    bottom: 13px;
  }
  ul.products li.product {
    margin-bottom: 1.5em;
  }
}

@media (max-width: 992px) {
  .site-main .columns-4 ul.products li.product .product-thumb:after {
    top: 10px;
    left: 11px;
    right: 11px;
    bottom: 8px;
  }
  .products.product-two li.product {
    width: 46% !important;
    margin-right: 3%;
    display: inline-block;
    margin-bottom: 20px;
  }
  .site-main .columns-3 ul.products li.product .price {
    margin-left: 8px;
    font-size: 16px;
  }
  .site-main .columns-3 ul.products li.product .add_to_cart_button {
    font-size: 16px;
    margin-right: 7px;
  }
  ul.products li.product .star-rating {
    margin-bottom: 0;
  }
  .woocommerce-loop-product__title {
    font-size: 14px !important;
    line-height: 15px;
    font-family: "Roboto Condensed", sans-serif;
  }
  .site-main .columns-3 ul.products li.product .product-thumb:after, .site-main .columns-3 ul.products li.product .product-thumb:after {
    top: 11px;
    left: 11px;
    right: 13px;
    bottom: 9px;
  }
}

@media (max-width: 768px) {
  .site-main .columns-3 ul.products li.product, .site-main .columns-4 ul.products li.product {
    width: 46%;
    margin-right: 3%;
    display: inline-block;
  }
  .products.product-two li.product {
    width: 46%;
  }
  .site-main .columns-3 ul.products li.product .product-thumb:before, .site-main .columns-4 ul.products li.product .product-thumb:before {
    background-size: contain;
  }
  .site-main .columns-3 ul.products li.product .product-thumb:after, .site-main .columns-4 ul.products li.product .product-thumb:after {
    top: 26px;
    left: 24px;
    right: 28px;
    bottom: 23px;
  }
  .site-main .columns-3 ul.products li.product .add_to_cart_button, .site-main .columns-4 ul.products li.product .add_to_cart_button {
    padding: 10px 20px;
    font-size: 14px;
    margin-right: 10px;
  }
  .site-main .columns-3 ul.products li.product .price, .site-main .columns-4 ul.products li.product .price {
    margin-left: 10px !important;
  }
  .single-product-details .product-title {
    font-size: 26px;
    margin-top: 30px;
    font-weight: 600;
  }
}

@media (max-width: 500px) {
  .site-main .columns-4 ul.products li.product, .site-main .columns-3 ul.products li.product {
    width: 100%;
  }
  .site-main .columns-4 ul.products li.product .product-thumb:after, .site-main .columns-3 ul.products li.product .product-thumb:after {
    top: 53px;
    left: 43px;
    right: 50px;
    bottom: 46px;
  }
  #shop-post-two ul.products li.product .star-rating {
    margin-bottom: 20px;
  }
  select.orderby {
    width: 120px;
  }
  .woocommerce-ordering {
    display: block;
    margin-bottom: 30px;
  }
  .short {
    margin-bottom: 15px;
  }
  .short.text-right, .show-items.text-right {
    text-align: left !important;
  }
  .products.product-two li.product {
    width: 100% !important;
  }
}

/*--------------------------------------------------------------
  ##  Widgets
  --------------------------------------------------------------*/
.widget {
  margin-bottom: 30px;
}

.widget-title {
  color: #dedede;
  font-size: 20px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #685858;
}

.search-form, .search-form-product {
  position: relative;
}

.search-form label, .search-form-product label {
  color: #333;
  display: block;
  font-weight: 800;
  margin-bottom: 0.5em;
}

.search-form input[type="search"], .search-form-product input[type="search"] {
  border: 2px solid #a1aeaf;
  display: block;
  padding: 10px 30px 10px 15px;
  width: 100%;
  outline: 0;
  border-radius: 10px;
  height: 45px;
  background: rgba(255, 255, 255, 0.102);
  color: #dedede;
}

.search-form input[type="search"]::-webkit-input-placeholder, .search-form-product input[type="search"]::-webkit-input-placeholder {
  color: #dedede;
}

.search-form input[type="search"]:-ms-input-placeholder, .search-form-product input[type="search"]:-ms-input-placeholder {
  color: #dedede;
}

.search-form input[type="search"]::placeholder, .search-form-product input[type="search"]::placeholder {
  color: #dedede;
}

.search-form .search-submit, .search-form-product .search-submit {
  position: absolute;
  right: 10px;
  top: 50%;
  border: 0;
  background: transparent;
  color: #dedede;
  outline: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 14px;
}

.search-form .screen-reader-text, .search-form-product .screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

.widget-social-link {
  margin: 0;
  list-style: none;
  padding: 25px;
  background: #252525;
  border-radius: 10px;
}

.widget-social-link li {
  display: inline-block;
  width: 18%;
  text-align: center;
  margin-bottom: 20px;
}

.widget-social-link li .fa.fa-facebook {
  color: #3a5897;
}

.widget-social-link li .fa-twitter {
  color: #49c9f2;
}

.widget-social-link li .fa-instagram {
  color: #3b749f;
}

.widget-social-link li .fa-pinterest-p {
  color: #cd2129;
}

.widget-social-link li .fa-linkedin {
  color: #07669f;
}

.widget-social-link li .fa-behance {
  color: #0683f0;
}

.widget-social-link li .fa-google-plus {
  color: #d13e2c;
}

.widget-social-link li .fa-rss {
  color: #f88a02;
}

.widget-social-link li .fa-tumblr {
  color: #274152;
}

.widget-social-link li .fa-youtube {
  color: #ce332c;
}

.widget-social-link li .fa-vimeo {
  color: #85c8ee;
}

.widget-social-link li .fa-cloud {
  color: #c1c1c1;
}

.widget-social-link li .fa-stumbleupon {
  color: #399734;
}

.widget-social-link li .fa-snapchat-ghost {
  color: #ff6600;
}

.widget-social-link li .fa-dribbble {
  color: #ea4b88;
}

.widget-social-link li a {
  display: block;
  font-size: 20px;
  color: #fff;
}

.widget-social-link li a:hover {
  opacity: 0.8;
}

.widget-social-link li:nth-last-child(-n+5) {
  margin-bottom: 0;
}

.widgets-recent-post {
  margin: 0;
  padding: 0;
  list-style: none;
}

.widgets-recent-post li {
  display: block;
  margin-bottom: 20px;
}

.widgets-recent-post li .feature-img {
  float: left;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.widgets-recent-post li .feature-img:hover {
  opacity: 0.8;
}

.widgets-recent-post li .content {
  margin-left: 80px;
  padding: 5px 0;
}

.widgets-recent-post li .content h3 {
  font-size: 16px;
  margin: 0 0 5px;
}

.widgets-recent-post li .content h3 a {
  font-weight: 500;
  color: #dedede;
}

.widgets-recent-post li .content h3 a:hover {
  color: #ffc107;
}

.widgets-recent-post li .content .post-date {
  color: #808080;
}

.widgets-recent-post li .content .post-date i {
  margin-right: 5px;
}

.tags-links a {
  display: inline-block;
  color: #bcbcbc;
  font-size: 16px;
  line-height: 20px;
  margin-right: 20px;
  font-weight: 500;
}

.tags-links a:hover {
  color: #ffc107;
}

.widget_upcoming_game {
  margin: 0;
  padding: 0;
}

.widget_upcoming_game > li {
  display: block;
  list-style: none;
  padding: 15px 10px;
  border-radius: 10px;
  background: #252525;
  margin-bottom: 15px;
}

.widget_upcoming_game > li .game-thumb {
  float: left;
}

.widget_upcoming_game > li .content {
  margin-left: 70px;
  position: relative;
}

.widget_upcoming_game > li .content h3 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.widget_upcoming_game > li .content h3 a {
  color: #dedede;
}

.widget_upcoming_game > li .content h3 a:hover {
  color: #f68b1f;
}

.widget_upcoming_game > li .content .review {
  line-height: 13px;
}

.widget_upcoming_game > li .content .review li {
  font-size: 13px;
  line-height: 13px;
  color: #f68b1f;
}

.widget_upcoming_game > li .content .follow {
  height: 28px;
  width: 28px;
  color: #dedede;
  text-align: center;
  background: #f68b1f;
  border-radius: 50%;
  display: block;
  font-size: 14px;
  line-height: 31px;
  position: absolute;
  bottom: -3px;
  right: 10px;
}

.widget_upcoming_game > li .content .follow:hover {
  opacity: 0.9;
}

/* Shop Widget */
.widget-title-shop {
  color: #dedede;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 3px solid #65892f;
  margin-bottom: 10px;
  position: relative;
  padding: 14px 20px;
  background: #212121;
  line-height: 16px;
}

.widget-title-shop:after {
  position: absolute;
  content: '';
  left: 50%;
  bottom: -8px;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #65892f;
}

.search-form-product {
  border: 0;
}

.search-form-product input[type="search"] {
  border: 0;
  background: #252525;
  border-radius: 0;
}

/* Releted Product */
.widgets-releted-product img {
  float: left;
  border-radius: 6px;
}

.widgets-releted-product > li {
  margin-bottom: 20px;
  border-radius: 4px;
  background: #252525;
  padding: 10px;
}

.widgets-releted-product > li .content {
  margin-left: 90px;
}

.widgets-releted-product > li .content h3 {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 5px;
}

.widgets-releted-product > li .content h3 a {
  color: #dedede;
}

.widgets-releted-product > li .content h3 a:hover {
  color: #f68b1f;
}

.widgets-releted-product > li .content .add_to_cart_button, .widgets-releted-product > li .content .price {
  color: #dedede;
}

.widgets-releted-product > li .content .add_to_cart_button:hover {
  color: #f68b1f;
}

.widgets-releted-product > li .content .review {
  line-height: 13px;
}

.widgets-releted-product > li .content .review li {
  font-size: 13px;
  color: #f68b1f;
  margin-bottom: 0;
  line-height: 13px;
}

/* Product Price */
#product-price .price-filter-inner {
  background: #252525;
  padding: 25px 20px 15px;
  border-radius: 5px;
}

#slider-range {
  margin-bottom: 15px;
}

.ui-widget-header {
  background: #65892f;
}

.ui-slider {
  position: relative;
}

.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  display: block;
  border: 0;
}

.ui-slider-horizontal {
  height: 7px;
}

.ui-slider-horizontal .ui-slider-handle {
  top: -8px;
}

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.ui-state-default, .ui-widget-content .ui-state-default {
  background: #FFF;
  outline: none;
  position: relative;
  display: inline-block;
  height: 15px;
  width: 15px;
  z-index: 2;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ui-widget-content {
  background: #fff;
}

.ui-widget.ui-widget-content {
  border: none;
}

.price-filter span {
  font-size: 13px;
  color: #fff;
}

.price-filter span #amount {
  width: auto;
  padding: 0;
  margin: 0;
  outline: none;
  font-size: 13px;
  border: none;
  cursor: default;
  background: transparent;
  color: #fff;
}

.shop-catgories-links {
  margin: 0;
  padding: 20px;
  background: #252525;
  list-style: none;
  border-radius: 5px;
}

.shop-catgories-links li a {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  line-height: 30px;
}

.shop-catgories-links li a:hover {
  background: #65892f;
  border-radius: 4px;
  text-decoration: underline;
  padding: 0 10px;
}

.active-category {
  background: #65892f;
  border-radius: 4px;
  text-decoration: underline;
  padding: 0 10px;
}

.active-category-slide {
  border: 2px solid #65892f;
  padding: 5px;
}

@media (min-width: 768px) and (max-width: 991.99px) {
  .widgets-releted-product > li .content {
    margin-left: 75px;
  }
  .widgets-releted-product > li .content h3 {
    font-size: 14px;
  }
  .widgets-releted-product > li .content .review {
    line-height: 11px;
  }
  .widgets-releted-product > li .content .review li {
    font-size: 11px;
    line-height: 11px;
  }
  .widgets-releted-product > li .content .add_to_cart_button, .widgets-releted-product > li .content price {
    font-size: 12px;
  }
  .feature-video-sm {
    padding: 5px;
    max-width: 370px;
  }
  .feature-video-sm img {
    border-radius: 5px;
  }
  .feature-video-sm .popup-btn-three {
    height: 35px;
    width: 35px;
    line-height: 37px;
    font-size: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .widget-social-link {
    padding: 15px 8px;
  }
  .widgets-recent-post li .content h3 {
    font-size: 15px;
  }
  .widget_upcoming_game > li .content {
    margin-left: 66px;
  }
  .widget_upcoming_game > li .content h3 {
    font-size: 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .widget-social-link {
    padding: 10px;
  }
  .widgets-recent-post li .content {
    margin-left: 75px;
  }
  .widgets-recent-post li .content h3 {
    font-size: 14px;
  }
  .widgets-recent-post li .content .post-date {
    font-size: 12px;
  }
  .widget_upcoming_game > li {
    padding: 10px 6px;
  }
  .widget_upcoming_game > li .content {
    margin-left: 65px;
  }
  .widget_upcoming_game > li .content h3 {
    font-size: 13px;
    line-height: 16px;
  }
}

/*--------------------------------------------------------------
  ##  Footer
  --------------------------------------------------------------*/
#footer {
  background: #141416;
}

.footer-inner {
  position: relative;
  z-index: 1;
}

.fire-bg-wrap {
  position: absolute;
  width: 100%;
  z-index: 0;
  bottom: 0;
}

.footer-title {
  color: #dedede;
  font-size: 25px;
  font-weight: 600;
  margin: 0 0 25px;
  text-transform: uppercase;
}

.content p {
  color: #FFF;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 20px;
}

.content .fread-btn {
  color: #FFF;
  font-size: 18px;
  display: inline-block;
}

.content .fread-btn:hover {
  color: #ffc107;
}

.contact-items {
  margin-bottom: 20px;
}

.contact-items .title {
  font-size: 16px;
  color: #d4d4d4;
  font-weight: 600;
}

.contact-items .title i {
  margin-right: 10px;
  width: 22px;
  text-align: center;
  color: #65892f;
}

.contact-items .email {
  color: #e0e0e0;
  font-size: 16px;
  margin-left: 30px;
  margin-bottom: 0;
  line-height: 22px;
}

.footer-latest-post {
  transition: all 0.3s ease-in-out;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #1a1a1a;
}

.footer-latest-post .feature-image {
  float: left;
  margin-right: 10px;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.footer-latest-post .feature-image .play {
  position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  visibility: hidden;
  opacity: 0;
  width: auto;
}

.footer-latest-post .post-content .post-title {
  margin-bottom: 0;
  font-size: 15px;
}

.footer-latest-post .post-content .post-title a {
  color: #FFF;
  font-weight: 600;
  display: inherit;
  line-height: 22px;
}

.footer-latest-post .post-meta {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-latest-post .post-meta li {
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
}

.footer-latest-post .post-meta li:first-child a {
  color: #FFF;
  text-transform: uppercase;
  font-weight: 500;
}

.footer-latest-post .post-meta li:first-child a i {
  margin-right: 5px;
}

.footer-latest-post .post-meta li:first-child a:hover {
  color: #65892f;
}

.footer-latest-post .post-meta li:first-child a:hover i {
  color: #fff;
}

.footer-latest-post .post-meta li:last-child {
  margin-right: 0;
}

.footer-latest-post .post-meta li:last-child i {
  color: #65892f;
}

.footer-latest-post:hover .feature-image .play {
  visibility: visible;
  opacity: 1;
}

.footer-latest-post:hover .post-content .post-title {
  text-decoration: underline;
}

.top-game a {
  display: inline-block;
  padding: 3px;
  height: 80px;
  width: 80px;
  margin-bottom: 3px;
}

.top-game a img {
  width: 100%;
}

.copy-right {
  padding: 20px 0;
  border-top: 1px solid #212121;
  border-bottom: 1px solid #212121;
  text-align: center;
}

.copy-right p {
  margin-bottom: 0;
  color: #d4d4d4;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 1px;
}

.copy-right p a {
  color: #65892f;
}

.footer-menu-wrap {
  padding: 50px 0;
  background: #141416;
  text-align: center;
}

.footer-logo {
  margin-bottom: 20px;
  display: block;
}

.footer-social-link {
  margin: 20px 0 30px;
  padding: 0;
  list-style: none;
}

.footer-social-link li {
  display: inline-block;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  text-align: center;
  line-height: 44px;
  transition: all 0.3s ease-in-out;
}

.footer-social-link li i {
  color: #FFF;
}

.footer-social-link li.fb-bg {
  background-image: url("../media/footer/2.png");
}

.footer-social-link li.in-bg {
  background-image: url("../media/footer/3.png");
}

.footer-social-link li.tw-bg {
  background-image: url("../media/footer/4.png");
}

.footer-social-link li.gp-bg {
  background-image: url("../media/footer/5.png");
}

.footer-social-link li:hover {
  opacity: 0.8;
}

.footer-menu {
  margin: 0;
  padding: 0;
}

.footer-menu li {
  display: inline-block;
  margin: 0 20px;
}

.footer-menu li a {
  color: #fff;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
}

.footer-menu li a:hover {
  color: #65892f;
}

/* Footer Two */
.footer-two {
  border-top: 1px solid #160000;
  padding: 80px 0 20px;
  background-size: cover;
  background-position: center center;
}

.footer-two .footer-latest-post .post-meta li i {
  color: #FFF;
}

.footer-about p {
  color: #cecece;
}

.footer-social-link-two li {
  display: inline-block;
  margin-right: 10px;
}

.footer-social-link-two li a {
  color: #fff;
  font-size: 25px;
}

.footer-social-link-two li a:hover {
  color: #f68b1f;
}

.tags-items a {
  display: inline-block;
  color: #fff;
  padding: 4px 8px;
  background: #333;
  margin-bottom: 4px;
  margin-right: 3px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.tags-items a:hover {
  background: #f68b1f;
}

.copyright-two {
  text-align: center;
  margin-top: 40px;
}

.copyright-two p {
  color: #fff;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 0;
}

.copyright-two p a {
  color: #f68b1f;
}

/* Footer */
#footer-three {
  padding: 100px 0 30px;
  background-size: cover;
  position: relative;
  background: #0b0d14;
}

#footer-three .copy-right {
  border: 0;
  padding: 0;
  margin-top: 20px;
  padding-top: 30px;
  border-top: 1px solid #1d1d1d;
}

#footer-three .footer-fire canvas {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer-about .footer-social-link {
  margin-top: 50px;
}

.footer-menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-menu-list li {
  border-bottom: 1px solid #262626;
}

.footer-menu-list li a {
  color: #cecece;
  font-size: 16px;
  line-height: 40px;
  font-weight: 500;
  display: block;
}

.footer-menu-list li a:hover {
  color: #f68b1f;
}

.footer-menu-list li:last-child {
  border-bottom: 0;
}

.footer-newsletter-inner p {
  font-size: 16px;
  font-weight: 500;
  color: #cecece;
}

.footer-newsletter input[type="text"] {
  display: block;
  padding: 10px 15px;
  background: transparent;
  border: 1px solid #262626;
  border-radius: 4px;
  margin-bottom: 20px;
  width: 100%;
  font-size: 16px;
  outline: 0;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.footer-newsletter input[type="text"]::-webkit-input-placeholder {
  color: #262626;
}

.footer-newsletter input[type="text"]:-ms-input-placeholder {
  color: #262626;
}

.footer-newsletter input[type="text"]::placeholder {
  color: #262626;
}

.footer-newsletter input[type="text"]:hover, .footer-newsletter input[type="text"]:focus {
  border-color: #f68b1f;
}

.footer-newsletter .tim-btn-bg {
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.footer-newsletter .tim-btn-bg:hover {
  background: #ffc107;
}

/* Footer Clan */
#footer-clan {
  padding-top: 100px;
  position: relative;
  background: #161623;
}

.footer-menu-clan {
  padding: 30px 0;
}

.footer-menu-clan li {
  display: inline-block;
  margin: 0 18px;
  position: relative;
}

.footer-menu-clan li:first-child {
  margin-left: 0;
}

.footer-menu-clan li:after {
  position: absolute;
  content: '';
  right: -20px;
  height: 15px;
  width: 1px;
  background: #a5a5a5;
  bottom: 7px;
}

.footer-menu-clan li:last-child:after {
  display: none;
}

.footer-menu-clan li a {
  color: #a5a5a5;
  font-weight: 500;
}

.footer-menu-clan li a:hover {
  color: #f68b1f;
}

.sub-footer-inner {
  margin-top: 30px;
  border-top: 1px solid #2f2f2f;
}

.sub-footer-inner .copy-right {
  border: 0;
  text-align: right;
  padding: 30px 0;
}

.footer-title-clan {
  font-size: 18px;
  line-height: 25px;
  color: #fff;
  font-weight: 500;
}

.footer-menu-clan-two li a {
  color: #a5a5a5;
  font-size: 14px;
  font-weight: 500;
}

.footer-menu-clan-two li a:hover {
  color: #f68b1f;
}

#footer-logo-clan a {
  display: block;
  margin-bottom: 20px;
}

#footer-logo-clan .copyright p {
  color: #a5a5a5;
  line-height: 25px;
  font-weight: 500;
}

.footer-address p {
  color: #fff;
  font-size: 16px;
  color: #a5a5a5;
  line-height: 26px;
  margin-bottom: 0;
}

.footer-social-three {
  margin-bottom: 30px;
}

.footer-social-three li {
  display: inline-block;
}

.footer-social-three li a.fbgc, .footer-social-three li a.tbgc, .footer-social-three li a.gbgc, .footer-social-three li a.ibgc {
  display: block;
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 28px;
  font-size: 14px;
  background: #fd0e0e;
  border-radius: 50%;
  color: #fff;
  margin-right: 5px;
  transition: all 0.3s ease-in-out;
}

.footer-social-three li a.fbgc {
  background: #3b5998;
}

.footer-social-three li a.tbgc {
  background: #1da1f2;
}

.footer-social-three li a.gbgc {
  background: #ec1c23;
}

.footer-social-three li a.ibgc {
  background: #e1306c;
}

.footer-social-three li a:hover {
  background: #fd0e0e;
}

.payment-logo a {
  display: inline-block;
  margin-right: 10px;
}

.fire-mosal-left, .fire-mosal-right {
  position: absolute;
  bottom: 0;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.fire-mosal-left {
  left: 11%;
}

.fire-mosal-right {
  right: 8%;
}

@media (max-width: 1600px) {
  .fire-mosal-left {
    left: 5%;
  }
  .fire-mosal-right {
    right: 3%;
  }
}

@media (max-width: 1440px) and (min-width: 1281px) {
  .fire-mosal-left {
    left: 4%;
  }
  .fire-mosal-right {
    right: 0%;
  }
}

@media (max-width: 1280px) {
  .fire-mosal-left, .fire-mosal-right {
    display: none;
  }
}

@media (max-width: 993px) {
  #footer-clan .footer-menu-clan, #footer-clan .copy-right {
    text-align: center;
  }
  #footer-clan .footer-menu-clan {
    padding: 20px 0 10px;
  }
  #footer-clan .copy-right {
    padding-top: 0;
  }
}

@media (max-width: 768px) {
  .footer-menu li {
    margin: 0 10px;
  }
  .footer-about {
    margin-bottom: 20px;
  }
  .footer-menu-clan li {
    margin: 0 10px;
  }
  .footer-menu-clan li:after {
    right: -12px;
  }
  .footer-title, .footer-title-clan {
    margin-top: 20px;
  }
  #footer-three {
    padding-top: 50px;
  }
}

/*--------------------------------------------------------------
  ##  modal
  --------------------------------------------------------------*/
.modal {
  z-index: 99999;
}

.modal-dialog {
  background: #252c36;
  pointer-events: visible;
  border: 2px solid #606f7b;
  padding: 30px;
  margin: 15% auto;
  max-width: 600px;
  z-index: 99999;
}

.modal-body {
  padding: 0;
}

.modal-body .nav {
  margin-bottom: 35px;
}

.modal-body .nav li {
  padding: 0px 25px;
  border-right: 1px solid #dfdfdf;
}

.modal-body .nav li a {
  font-size: 16px;
  font-weight: 700;
  color: #dfdfdf;
}

.modal-body .nav li i {
  color: #fff;
  margin-right: 10px;
}

.modal-body .nav li:last-child {
  border-right: 0px;
}

.modal-body .nav li:first-child {
  padding-left: 0px;
}

.modal-body .nav li.active i {
  color: #f68b1f;
}

.login-title h3 {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 30px;
}

.login-right,
.login-center,
.login-left {
  display: inline-block;
  vertical-align: top;
  margin: 0px -3px;
}

.login-left {
  width: 30%;
}

.login-right {
  width: 71%;
  padding-left: 70px;
}

.login-center {
  width: 71%;
  padding: 0;
}

.login-social li {
  height: 52px;
  border-radius: 5px;
  margin-bottom: 20px;
  line-height: 52px;
  text-align: center;
  font-size: 20px;
}

.login-social li.facebook {
  background: #3648cc;
}

.login-social li.twitter {
  background: #1da1f2;
}

.login-social li.pinterest {
  background: #f82b43;
}

.login-social li a {
  display: block;
  color: #fefefe;
}

.login-social li a i {
  float: left;
  color: #fff;
  height: 52px;
  width: 52px;
  line-height: 52px;
  text-align: center;
  position: relative;
}

.login-social li a i:after {
  position: absolute;
  right: 0;
  content: "";
  width: 2px;
  height: 100%;
  box-shadow: 0px 0px 6px rgba(40, 31, 3, 0.5);
}

.login_main_form input {
  width: 100%;
  height: 52px;
  background: transparent;
  border: 1px solid #65892f;
  margin-bottom: 20px;
  padding-left: 15px;
  color: #65892f;
}

.login_main_form input[type="checkbox"] {
  width: auto;
  height: auto;
  margin-right: 10px;
}

.login_main_form input[type="submit"] {
  padding: 2px 10px;
  background: transparent;
  color: #65892f;
  border: 2px solid #65892f;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
}

.select_wrapper {
  position: relative;
}

.select_wrapper select {
  width: 100%;
  height: 51px;
  padding: 0px 15px;
  border: 1px solid #9e9e9e;
  background: transparent;
  color: #fff;
  opacity: 0.5;
  -moz-appearance: none;
  -webkit-appearance: none;
  margin-bottom: 20px;
  position: relative;
}

.select_wrapper span {
  float: right;
  color: #f68b1f;
}

.select_wrapper:before {
  position: absolute;
  right: 15px;
  top: 15px;
  content: "\f0d7";
  font-family: 'FontAwesome';
  color: #f68b1f;
  pointer-events: none;
}

@media (max-width: 767px) {
  .modal-dialog {
    max-width: 550px;
    margin: 58px auto;
  }
  .login_main_form span {
    display: block;
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .modal-dialog {
    max-width: 450px;
  }
  .login-left {
    width: 35%;
  }
  .login-right {
    width: 65%;
    padding-left: 30px;
  }
  .login-center {
    width: 65%;
  }
  .login_main_form span {
    display: block;
    margin-bottom: 20px;
  }
}

@media (max-width: 479px) {
  .modal-dialog {
    max-width: 300px;
  }
  .login-left {
    width: 100%;
  }
  .login-right {
    width: 100%;
    padding-left: 0px;
  }
  .login-center {
    width: 100%;
  }
}

.text-right {
  text-align: right !important;
}

.roadmap {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.modal-close a {
  position: relative;
  left: 100%;
  cursor: pointer;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.iconLarge {
  left: 55%;
  bottom: 25px;
  width: 68px;
  height: 68px;
  position: relative;
  z-index: 0;
}

.iconLarge ins {
  width: 56px;
  height: 56px;
  left: 6px;
  top: 6px;
  display: block;
  position: absolute;
  z-index: 5;
  background-repeat: no-repeat;
}

.iconLarge del {
  width: 68px;
  height: 68px;
  background-image: url("../media/icons/default.png");
  display: block;
  position: absolute;
  z-index: 10;
  background-repeat: no-repeat;
}

.iconLarge a {
  width: 62px;
  height: 62px;
  background: url("../media/icons/default_hover.png");
  left: 3px;
  top: 3px;
  display: block;
  position: absolute;
  z-index: 20;
  opacity: 0;
}

.kill-team-social .armory-a {
  position: absolute;
  left: 25%;
  top: -100%;
}

.iconLarge a:hover {
  opacity: 1;
}

#tickets .modal-dialog{
  padding: 0 !important;
  background: black !important;
}

.red-btn {
  padding: 2px 10px;
  text-transform: uppercase;
  background: red;
  color: white;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  vertical-align: middle;
  border-radius: 6px;
  display: inline-block;
}

.green-btn {
  padding: 2px 10px;
  text-transform: uppercase;
  background: green;
  color: white;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  vertical-align: middle;
  border-radius: 6px;
  display: inline-block;
}

.red-btn:hover {
  background: red;
  color: white;
}

.green-btn:hover {
  background: green;
  color: white;
}

#ticket-form textarea {
  display: block;
  width: 100%;
  height: 200px;
}

.ticket-response {
  background: white;
  color: black;
  overflow: auto;
  height: 200px;
  width: 100%;
}

.kill-team-social .ticket-a {
  cursor: pointer;
  position: absolute;
  left: 25%;
  bottom: -15%;
}

.verification-message {
  background: var(--ltn__paragraph-color);
  width: 100%;
  left: 0;
  right: 0;
  margin: 0;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.verification-message div
{
  margin-top: 10px;
  background-color: red;
}

.verification-message div:hover {
  background-color: green;
}

.header-btn-reg {
  padding: 2px 10px;
  background-color: transparent;
  color: #65892f;
  border: 2px solid #65892f;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
}

.coming-soon__content {
  position: absolute;
  right: 10%;
  top: 70%;
  display: grid;
  place-content: center;
  padding-inline: 30px;
}

.coming-soon__content h2 {
  margin-bottom: 20px;
}

.countdown {
  margin-inline: auto;
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.coming-soon__content .countdown__item {
  background: black;
  padding: 1rem 1.5rem;
  border-radius: 5px;
}

.countdown__text {
  margin-bottom: 0;
}

.color--theme-color {
  color: #00ffa3 !important;
}

.score-faction-container {
  max-width: 270px;
  margin: auto;
  margin-bottom: 5px;
  height: 37px;
}

.score-container {
  overflow: hidden;

  max-width: 270px;
  max-height: 417px;

  margin: auto;

  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.score-container,
#bg-table-container {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

@media (max-width: 992px) {
  .coming-soon__content {
    right: 5% !important;
    top: 80% !important;
  }

  .coming-soon__content .countdown__item {
    padding: 1rem 0.5rem !important;
  }

  .countdown {
    gap: 10px;
  }
}

.show-password-toggle {
  margin-left: 10px;
}

.alertify-notifier {
  z-index: 100000 !important;
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100000;
}

.popup {
  background: #252c36;
  max-width: 400px;
  margin: 100px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.gp_link {
  display: block;
  width: 10rem;
  height: 3rem;
  background: url(../media/shop/gamepoints_url.png);
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;
  transition: all .1s ease-in-out;
  border-radius: 10px;
}

.pixel_link {
  display: block;
  height: 3rem;
  background: url(../media/shop/pixel_url.png);
  opacity: 1;
  transition: all .1s ease-in-out;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 43%;
  margin-top: .5rem;
}


.btcpay_link {
  display: block;
  width: 10rem;
  height: 3rem;
  background: url(../media/shop/btcpay.webp);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  transition: all .1s ease-in-out;
  background-color: #b1a9a9;
  border-radius: 10px;
}

.kinguin_link{
  display: block;
  width: 10rem;
  height: 3rem;
  background: url(../media/shop/kinguin-logo.png);
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;
  transition: all .1s ease-in-out;
  background-color: #b1a9a9;
  border-radius: 10px;
}

.brand-logo {
  position: relative;
  text-align: center;
}

.logo-title {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px 10px;
}

.cart-plus-minus {
  border: 2px solid;
  height: 63px;
  line-height: 56px;
  width: 140px;
  text-align: center;
}

.qtybutton {
  height: 100%;
  width: 30%;
}

.dec.qtybutton {
  float: left;
  border-right: 2px solid;
}

.inc.qtybutton {
  float: right;
  border-left: 2px solid;
}

.cart-plus-minus,
.dec.qtybutton,
.inc.qtybutton {
  background-color: var(--white);
  border-color: var(--border-color-11);
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
}

input.cart-plus-minus-box {
  background: transparent none repeat scroll 0 0;
  box-shadow: none;
  border: none;
  height: 100%;
  margin-bottom: 0;
  padding: 0;
  text-align: center;
  width: 40%;
  font-weight: 700;
}

.modal-product-img {
  float: left;
}

ea .modal-product-info {
  overflow: hidden;
}

.added-cart i {
  color: var(--green);
}

.new-badge {
  background-color: red;
  color: white;
  padding: 2px 5px;
  border-radius: 5px;
  margin-left: 5px;
}

.div-logo{
    display: flex;
    align-items: center;
}

.donate-flex{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gray-div{
  background: rgba(50, 50, 50, 0.7);
  color: white;
  padding: 15px 15px;
}


#instanceTable {
 /* background-color: #0d1f39;*/
  padding: 100px 0;
  background-attachment: fixed;
  background-size: cover;
}

#instanceTable .card-body {
  background-color: #0d1f39;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#instanceTable .table-responsive {
  width: 90%;
}

#speedrunTable {
  border-radius: 10px;
  background-color: #1F2739;
}

#speedrunTable th,
#speedrunTable td {
  padding: 7px;
  border-style: none;
  text-align: center;
}

#speedrunTable tr {
  border-style: none;
}

#speedrunTable th h1 {
  font-weight: bold;
  font-size: 1em;
  text-align: left;
  color: #185875;
}

#speedrunTable thead {
  background-color: #1F2739;
  color: #FFFFFF;
}

#speedrunTable tbody tr:nth-child(odd) {
  background-color: #323C50;
}

#speedrunTable tbody tr:nth-child(even) {
  background-color: #2C3446;
}

#speedrunTable tbody tr:hover {
  background-color: #464A52;
  box-shadow: 0 6px 6px -6px #0E1119;
  transform: translate3d(6px, -6px, 0);
  transition-delay: 0s;
  transition-duration: 0.4s;
  transition-property: all;
  transition-timing-function: linear;
}

#speedrunTable tbody td:hover {
  background-color: #532f89;
  color: #FFFFFF;
  font-weight: bold;
  box-shadow: #7F7C21 -1px 1px, #7F7C21 -2px 2px, #7F7C21 -3px 3px, #7F7C21 -4px 4px, #7F7C21 -5px 5px, #7F7C21 -6px 6px;
  transform: translate3d(6px, -6px, 0);

  transition-delay: 0s;
  transition-duration: 0.4s;
  transition-property: all;
}

.modal-content {
  /*
  margin-top: 10%;
  */
  border-radius: 0;
  border: 1px solid rgba(158, 151, 131, .1);
  opacity: 0.98;
  background-color: var(--background-color);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

#instanceTable .modal-body {
  padding: 4rem;
}

.hidden-element {
  visibility: hidden;
  height: 25vh;
}


#instanceTable .table-responsive {
  color: white;
}

.speedrunTable {
  border-radius: 10px;
  background-color: #1F2739;
}

.speedrunTable th,
.speedrunTable td {
  padding: 15px;
  border-style: none;
}

.speedrunTable tr {
  border-style: none;
}

.speedrunTable th h1 {
  font-weight: bold;
  font-size: 1em;
  text-align: left;
  color: #185875;
}

.speedrunTable thead {
  background-color: #1F2739;
  color: #FFFFFF;
}

.speedrunTable tbody tr:nth-child(odd) {
  background-color: #323C50;
}

.speedrunTable tbody tr:nth-child(even) {
  background-color: #2C3446;
}

.speedrunTable tbody tr:hover {
  background-color: #464A52;
  box-shadow: 0 6px 6px -6px #0E1119;
  transform: translate3d(6px, -6px, 0);
  transition-delay: 0s;
  transition-duration: 0.4s;
  transition-property: all;
  transition-timing-function: linear;
}

.speedrunTable tbody td:hover {
  background-color: #532f89;
  color: #FFFFFF;
  font-weight: bold;
  box-shadow: #7F7C21 -1px 1px, #7F7C21 -2px 2px, #7F7C21 -3px 3px, #7F7C21 -4px 4px, #7F7C21 -5px 5px, #7F7C21 -6px 6px;
  transform: translate3d(6px, -6px, 0);

  transition-delay: 0s;
  transition-duration: 0.4s;
  transition-property: all;
}

.kook-logo {
  width: 7rem;
}

.verification-message-black {
  background-image: radial-gradient(ellipse farthest-corner at center 0px, #11383a -300%, #262635 45%, #27273a 85%);
}


@media (min-width: 1955px) {
  #instanceTable .modal-dialog {
    max-width: 75%;
  }
}


.profile-menu {
  border: 1px solid green;
  border-radius: 20px;
  padding: 1rem;
}

.btn-parent {
  position: relative;
}

.btn-parent .badge {
  position: absolute;
  top: -17px;
  border-radius: 50%;
  opacity: 1;
  font-size: 12px;
  padding: 7px;
  left: 135px;
}

.badge {
  font-weight: normal !important;
  border-radius: 10px;
  padding: 0.25em 1em;
  text-transform: uppercase;
  letter-spacing: 2px;
  opacity: 0.7;
  background-color: var(--bs-danger);
}

.btn-primary-purple {
  background-color: green;
  border: green;
  color: white;
}

.appeal-status {
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  text-align: center;
}

.appeal-status.closed {
  background-color: #d9534f;
}

.appeal-status.approved {
  background-color: #5cb85c;
}

.player-search-container {
  background-color: #35294b;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
}

.player-search-container h5 {
  color: #333;
}

.player-search-container .btn-primary {
  background-color: #4CAF50;
  border-color: #4CAF50;
}

.ucp {
  background-color: var(--background-color);
}

.ucp table td {
  padding: 0.5rem;
  vertical-align: top;
  border-top: none;
  border-bottom: 1px solid rgba(50, 50, 50, 0.53);
  max-width: 33rem;
  word-break: break-word;
  font-family: sans-serif;
}

.ucp table tr td:last-child, .ucp table tr td:first-child, .ucp table tr td:nth-child(7), .ucp table tr td:nth-child(4) {
  word-break: normal;
}

.ucp .game-class a {
  color: inherit !important;
}

.ucp .table.light-borders td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: none;
  border-bottom: 1px solid rgba(50, 50, 50, 0.17);
}

.ucp .w-10 {
  width: 10%;
}

.ucp table thead th {
  border-top: 0;
  border-bottom: 2px solid rgba(50, 50, 50, 0.53);
  color: var(--gray);
  padding: 0.5rem;
}

/*

.ucp input {
    padding: 10px;
}
*/

.ucp .btn {
  /*
  border-radius: 0;
  */
  font-size: 14px;
}

.ucp .btn-primary {
  background-color: #322927;
  border-color: #201b19;
}

.ucp .btn-primary:active, .ucp .btn-primary:hover {
  background-color: #564440 !important;
  border-color: #312925 !important;
}

.ucp .passwords_invalid {
  cursor: not-allowed;
}

.ucp .btn-danger {
  background-color: #A7333F;
  border-color: #c24b4b;
  color: #fff;
}

.ucp .btn-danger:hover {
  background-color: #c24b4b;
  border-color: #A7333F;
}

.ucp .btn-warning {
  background-color: #F6F5AE;
  border-color: #F6F5AE;
  color: #000;
}

.ucp .btn-warning:hover {
  background-color: #D9D86E;
  border-color: #D9D86E;
}

.ucp .btn-success {
  background-color: #4E6E58;
  border-color: #4E6E58;
  color: #fff;
}

.ucp .btn-success:hover {
  background-color: #3C5644;
  border-color: #3C5644;
}

.ucp .btn-danger:active, .ucp .btn-danger:hover {
  background-color: #d95050 !important;
  border-color: #c24b4b !important;
}


.ucp .text-white a {
  margin-right: 50px;
}

.ucp a.active {
  color: var(--orange) !important;
}

.ucp a {
  color: var(--blue-gray) !important;
  background: none !important;
  cursor: pointer;
}

.ucp .message-counter {
  width: 50px;
  position: relative;
  top: 36px;
  right: -93%;
  font-size: small;
  z-index: 1024;
}

.ucp .dropdown-menu.show {
  background-color: var(--darker-blue);
  cursor: pointer;
}

.ucp .dropdown-menu.show a {
  color: white !important;
  font-size: 1.2rem;
}

a:hover {
  color: var(--purple) !important;
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

.ucp label {
  margin-bottom: 0 !important;
}

.ucp select.form-control {
  padding: 6px 12px;
  cursor: pointer;
}

.ucp .form-control, #banAppealModal .form-control {
  border-radius: 20px;
  background-color: var(--darker-blue);
  color: #FFFFFF;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
  font-size: 1.2rem;
}

.ucp .form-control::placeholder, #banAppealModal .form-control::placeholder {
  color: var(--blue-gray);
}


.ucp .list-group-item {
  background-color: var(--gray-bg) !important;
}


.response-ban-appeal {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  justify-content: space-between;
  align-items: center;
}

.message-container {
  background-color: #198751;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
}

.msg-right {
  margin-left: auto;
  width: fit-content;
}

.msg-left {
  margin-right: auto;
  width: fit-content;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.form-back {
  background: #8080801c;
  border-radius: 10px;
  padding: 2rem;
  margin-right: 2rem;
}

#speedrunModal .modal-dialog {
  padding: 0;
  max-width: 100rem;
}

.font-weight-bold {
  font-weight: bold;
}

.table-hover tbody tr:hover {
  background-color: rgb(255 255 255 / 57%);
}

.thead-light th {
  background-color: #272737;
  color: #fff;
  border-bottom: none;
}

.table-hover td {
  border-bottom: none;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.starfall-frame {
  border-radius: 20px;
  background: #63636524;
  padding: 1.5rem;
  margin-right: 1rem;
  width: 100%;
}

.starfall-columns {
  display: flex;
  flex-direction: row;
}

#reserveButton:disabled {
  background-color: green;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.error-message {
  margin-top: 10px;
  padding: 10px;
  background-color: #e84c5b;
  border-color: #f5c2c7;
  color: white;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  animation: fadeIn 0.3s ease-out;
}

@media (max-width: 1500px) {
  #speedrunTable td {
    font-size: 14px;
  }

  #instanceTable .modal-body {
    padding: 1rem;
  }
}


@media all and (max-width: 1600px) {
  .home-clan {
    background-size: cover;
    background-position: center;
  }
}

@media all and (max-width: 1200px) {
  #fire-title {
    font-size: 40px;
    overflow: hidden;
    display: inline-block;
  }
  #fire-bg canvas {
    width: 385px;
    margin-top: 2px;
  }
  .section-title-two .title:before, .section-title-two .title:after {
    width: 200px;
  }
}

@media (max-width: 1024px) {
  .section-title h2.title {
    font-size: 35px;
  }
  .section-title h3.sub-title {
    font-size: 18px;
  }
  .section-title .delemitter {
    max-width: 170px;
    background-size: contain;
    margin-bottom: 15px;
  }
  .section-title .section-content p {
    font-size: 16px;
  }
  .section-title .section-content p br {
    display: none;
  }
  #fire-title {
    font-size: 30px;
  }
  #fire-bg canvas {
    width: 290px;
    margin-top: 1px;
  }
  .starfall-columns {
    flex-direction: column;
  }
}


@media all and (max-width: 991px) {
  .rmt__30 {
    margin-top: 30px;
  }
}

@media all and (min-width: 767px) {
  .padd__none {
    padding: 0 !important;
  }
  .section-title-three {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .section-title {
    margin-bottom: 50px;
  }
  .section-title h2.title {
    font-size: 30px;
  }
  .section-title .section-content p br {
    display: none;
  }
  .section-padding {
    padding: 50px 0;
  }
  .section-title-two .title {
    font-size: 25px;
  }
  .section-title-two .title:before, .section-title-two .title:after {
    width: 100px;
  }
  #fire-bg {
    width: 60%;
    top: 3px;
  }
  .rsmt__30 {
    margin-top: 30px;
  }
  .pixel_link {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .section-title-two .title:before, .section-title-two .title:after {
    width: 70px;
  }
  #fire-title {
    font-size: 28px;
  }
  #fire-bg canvas {
    width: 270px;
    margin-top: 3px;
  }
  .col-full-width {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 370px;
    margin: 0 auto;
  }
}


.features .dropdown-menu {
  background: #272738;
}


.custom-table {
  width: 100%;
  margin: auto;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  background-color: transparent;
  color: #fff;
  text-align: center;
}

.custom-table td {
  padding: 15px;
  border-bottom: 1px solid #444;
}

.custom-table .currency {
  color: white;
  font-weight: bold;
}

.custom-table .bonus {
  color: #ffeb3b;
  font-weight: bold;
}

.custom-table .arrow {
  color: #fff;
  font-size: 18px;
}

.custom-table .tokens {
  color: #8bc34a;
  font-weight: bold;
}

.custom-table .tokens i {
  margin-right: 5px;
  color: #8bc34a;
}

.custom-table tr:hover {
  background-color: #333;
}

.donation-calculator {
  text-align: center;
}

.donation-calculator .form-control {
  width: 50%;
  margin: auto;
}

.donation-calculator .btn {
  margin-top: 15px;
}


.bonus-text {
  color: var(--green);
  font-weight: bold;
  margin-left: 15px;
}

.arrow-blink {
  color: #d4a000;
  font-weight: bold;
  margin: 0 10px;
  animation: blink 1s infinite;
}

.final-points {
  color: #d4a000;
  font-weight: bold;
}

.pulse-effect {
  animation: pulse 1.5s infinite;
}


.section {
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 10px;
  background-color: rgb(71 70 71 / 18%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 18px;
}

.section-title {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #eedbfa;
  display: flex;
  align-items: center;
}

.section-title i {
  margin-right: 10px;
}

.landing-content {
  font-size: 18px;
  line-height: 1.6;
}

.gift-list {
  list-style: none;
  padding: 0;
}

.gift-list li {
  margin-bottom: 10px;
  display: flex;
  font-family: sans-serif;
  align-items: center;
}

.gift-list i {
  margin-right: 10px;
  color: #eedbfa;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.server-container h3 {
  color: #eedbfa;
  margin-top: 20px;
  margin-bottom: 10px;
}

.server-container ul {
  list-style: none;
  padding: 0;
}

.server-container ul li {
  margin-bottom: 5px;
}

.text-center {
  text-align: center;
}

.server-container p, .server-container ul, .landing-content p, .landing-content ul {
  font-family: sans-serif;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    color: #28a745;
  }
  50% {
    transform: scale(1.1);
    color: #34d058;
  }
  100% {
    transform: scale(1);
    color: #28a745;
  }
}


@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@media (max-height: 750px) {
  #initialModal, #initialModal .modal-dialog {
    margin-top: 0 !important;
  }
}